import React from 'react';
import { Box, Button } from 'theme-ui';
import Text from '../../01_atoms/Text/Text';

interface StartProps {
  onContinue: () => void;
}

const Start: React.FC<StartProps> = ({ onContinue }) => (
  <>
    <Text variant="h2" highlighted sx={{ m: 0, textAlign: 'center' }}>
      Aktuell nur
    </Text>
    <Text variant="h2" sx={{ m: 0, textAlign: 'center' }}>
      für iOS verfügbar.
    </Text>

    <Text sx={{ mt: 3, textAlign: 'center', letterSpacing: 0 }}>
      Momentan bieten wir Yuu Yoga nur als App für iOS an. Eine Version für
      Android-Smartphones ist in Planung.
    </Text>

    <Text sx={{ mt: 3, textAlign: 'center', letterSpacing: 0 }}>
      Lass Dich gerne vormerken und wir geben dir Bescheid sobald, diese
      verfügbar ist.
    </Text>

    <Box sx={{ mt: 9 }}>
      <Button variant="primary" onClick={onContinue}>
        Jetzt vormerken
      </Button>
    </Box>
  </>
);

export default Start;
