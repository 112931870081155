import { Box, Image } from 'theme-ui';
import React, { useEffect } from 'react';
import Helmet from 'react-helmet';

import Header from '../../02_molecules/Header/Header';
import Text from '../../01_atoms/Text/Text';
import Container from '../../01_atoms/Container/Container';
import IosGateIllustration from '../../../assets/iosGateIllustration.svg';
import LoadingIllustration from '../../../assets/illustrationLoading.svg';

const IosGate: React.FC<unknown> = () => {
  useEffect(() => {
    // 1vh is by default the whole viewport, however sometimes the viewport is overlapped by browser-bars.
    // for this case, we need to know the visible height when loading the page, to keep everything really full-screen
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="apple-itunes-app"
          content={`app-id=1540962781, app-argument=${
            window.location.href || ''
          }`}
        />
      </Helmet>

      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 99,
          width: '100%',
          height: '140px',
          backgroundColor: 'white',
        }}
      />

      <Header showButton={null} theme="light" />

      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 101,
        }}
      >
        <Image
          src={IosGateIllustration}
          sx={{
            height: '140px',
            width: '244px',
          }}
        />
      </Box>

      <Box
        sx={{
          position: 'relative',
          pt: '68px',
          pb: `${(173 / 376) * 100}%`,
          minHeight: 'calc((var(--vh, 1vh) * 100) - 3rem)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '@media (orientation: portrait)': {
            minHeight: 'calc((var(--vh, 1vh) * 100) - 3.75rem)',
          },
          '@media (orientation: portrait) and (min-width: 48em)': {
            minHeight: 'calc((var(--vh, 1vh) * 100) - 5rem)',
          },
          '@media (orientation: landscape) and (min-height: 48em)': {
            minHeight: 'calc((var(--vh, 1vh) * 100) - 3.75rem)',
          },
          '@media (orientation: landscape) and (min-height: 62em)': {
            minHeight: 'calc((var(--vh, 1vh) * 100) - 5rem)',
          },
        }}
      >
        <Container sx={{ pt: 5, textAlign: 'center' }}>
          <Text
            variant="h2"
            highlighted
            sx={{ mt: 0, mb: 3, textAlign: 'center' }}
          >
            Hol’ dir die App.
          </Text>

          <Text sx={{ textAlign: 'center', letterSpacing: 0 }}>
            Um Dir Yoga in bester Qualität bieten zu können, kannst Du Yuu Yoga
            auf Deinem Smartphone momentan nur mit unserer App verwenden. Lade
            Dir diese einfach im App Store herunter.
          </Text>
        </Container>

        <Image
          src={LoadingIllustration}
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
          }}
        />
      </Box>
    </>
  );
};

export default IosGate;
