import React from 'react';
import { Box, Grid } from 'theme-ui';

import { useVideoSessions } from '@youga/youga-client-api';

import type { TrainingSession } from '../../../types/interfaces';
import Container from '../../01_atoms/Container/Container';
import PoseComparisonSessionItem from '../../01_atoms/PoseComparisonSessionItem/PoseComparisonSessionItem';

interface PoseComparisonOverviewListProps {
  trainingSessions: TrainingSession[];
}

const PoseComparisonOverviewList: React.FC<PoseComparisonOverviewListProps> = ({
  trainingSessions,
}) => {
  const { data: videoSessionsData } = useVideoSessions();

  return (
    <Box
      sx={{
        mt: 9,
        mb: [9, 16],
        position: 'relative',
      }}
    >
      <Container>
        <Grid
          columns={[1, 3, null, 4]}
          sx={{ columnGap: [0, 5, null, 11], rowGap: '3.75rem' }}
        >
          {trainingSessions
            .filter((s) => Boolean(s.images[0]))
            .map((session) => {
              const matchingVideoSession =
                videoSessionsData?.videoSessions[session.videoSessionId || ''];

              let poseIndex = 0;

              if (matchingVideoSession?.highlightPoseName) {
                poseIndex = Math.max(
                  0,
                  session.images.findIndex(
                    (image) =>
                      image.poseName === matchingVideoSession.highlightPoseName,
                  ),
                );
              }

              return (
                <PoseComparisonSessionItem
                  key={session.id}
                  poseImage={session.images[poseIndex].referenceUrl}
                  userImage={session.images[poseIndex].url}
                  sessionName={session.videoSessionName || 'Session'}
                  sessionId={session.videoSessionId || ''}
                  date={session.timestamp}
                  target={`/app/pose-comparison/${session.id}`}
                />
              );
            })}
        </Grid>
      </Container>
    </Box>
  );
};

export default PoseComparisonOverviewList;
