import React, { useState, useEffect, SyntheticEvent } from 'react';

import { Auth } from 'aws-amplify';
import * as Sentry from '@sentry/browser';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

import { AuthVariant } from './AuthBox';
import ConfirmResetPassword from './ConfirmResetPassword';
import Container from '../../01_atoms/Container/Container';
import getUsernameByEmailAddress from '../../../utils/getUserEmailByAddress';
import gtagReportConversion from '../../../utils/gtagReportConversion';
import IconCheck from '../../../assets/icons/iconCheck.inline.svg';
import IconExclamation from '../../../assets/icons/iconExclamation.inline.svg';
import InformationModal, { IInformationScreen } from './InformationModal';
import ResetPassword from './ResetPassword';
import SignInForm from './SignInForm';
import SignUpForm from './SignUpForm';
import Text from '../../01_atoms/Text/Text';
import useAuth from '../../../hooks/useAuth';

interface AuthFormProps {
  confirmCode?: string;
  referral: string;
  variant?: AuthVariant;
  setCurrentVariant: (variant: AuthVariant) => void;
}

export default function AuthForm({
  confirmCode,
  referral = '',
  variant = 'signin',
  setCurrentVariant,
}: AuthFormProps) {
  const { handleSignInWithEmail } = useAuth();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newsletter, setNewsletter] = useState('false');
  const [conditionsAccepted, setConditionsAccepted] = useState(false);
  const [errorInformation, setErrorInformation] = useState<null | {
    position: string;
    element: React.ReactNode;
  }>(null);
  const [code, setCode] = useState(confirmCode || '');
  const [informationScreen, setInformationScreen] =
    useState<IInformationScreen | null>(null);

  let title = '';

  switch (variant) {
    case 'signin':
      title = 'logge dich jetzt ein.';
      break;
    default:
      title = 'we make yoga about you.';
      break;
  }

  let subtitle = '';

  switch (variant) {
    case 'resetPassword':
      subtitle = 'Passwort zurücksetzen';
      break;
    case 'confirmResetPassword':
      subtitle = 'Neues Passwort speichern';
      break;
    default:
      subtitle = '';
      break;
  }

  useEffect(() => {
    setInformationScreen(null);
    setErrorInformation(null);
  }, [variant]);

  const federatedSignIn = async (
    provider: CognitoHostedUIIdentityProvider,
  ): Promise<void> => {
    try {
      await Auth.federatedSignIn({ provider });
    } catch (e) {
      console.log('Federated login failed: provider: ', { provider });
      console.log(e);
    }
  };

  const onClickFacebookLogin = async (
    e: SyntheticEvent<HTMLButtonElement>,
  ): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    await federatedSignIn(CognitoHostedUIIdentityProvider.Facebook);
  };

  const onClickGoogleLogin = async (
    e: SyntheticEvent<HTMLButtonElement>,
  ): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    await federatedSignIn(CognitoHostedUIIdentityProvider.Google);
  };

  const onClickAppleLogin = async (
    e: SyntheticEvent<HTMLButtonElement>,
  ): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    await federatedSignIn(CognitoHostedUIIdentityProvider.Apple);
  };

  const onSubmit = async (
    e: SyntheticEvent<HTMLFormElement>,
  ): Promise<void> => {
    e.preventDefault();
    setInformationScreen(null);
    setErrorInformation(null);

    setLoading(true);

    try {
      switch (variant) {
        case 'signin':
          /**
           * @see https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_InitiateAuth.html
           * @see https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_RespondToAuthChallenge.html
           * @see https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_GetUser.html
           */
          try {
            await handleSignInWithEmail(email, password);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            let caughtError = false;

            // We only want to catch API specific errors here
            // in all other cases, we throw the error one level higher
            switch (error.code) {
              // This exception is thrown when a user is not confirmed successfully.
              case 'UserNotConfirmedException':
                caughtError = true;
                setErrorInformation({
                  position: 'email',
                  element: (
                    <span>Bitte bestätige zunächst deinen Benutzer.</span>
                  ),
                });
                break;

              // This exception is thrown when a user is not found.
              case 'UserNotFoundException':
                caughtError = true;
                setInformationScreen({
                  title: 'Dieser Benutzer existiert noch nicht.',
                  icon: IconExclamation, // @todo find a better icon
                  iconColor: 'warning',
                  description: '',
                  buttonLabel: 'Zur Registrierung',
                  buttonTargetVariant: 'signup',
                });
                break;

              // This exception is thrown when the Amazon Cognito service encounters an invalid password.
              case 'InvalidPasswordException':
                caughtError = true;
                setErrorInformation({
                  position: 'password',
                  element: <span>Ungültiges Passwort.</span>,
                });
                break;

              // This exception is thrown when a user is not authorized.
              // Actually, this error is also thrown when a user does not exist or is not activated
              case 'NotAuthorizedException':
                caughtError = true;
                setErrorInformation({
                  position: 'email',
                  element: <span>Ungültige E-Mail Adresse oder Passwort.</span>,
                });
                break;

              default:
                caughtError = false;
                break;
            }

            if (!caughtError) {
              throw error;
            }
          }

          break;

        case 'signup':
          /**
           * @see https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_SignUp.html
           */
          try {
            await Auth.signUp({
              username: getUsernameByEmailAddress(email),
              password,
              attributes: {
                email: email.trim(),
                'custom:referral': referral,
                'custom:newsletter': newsletter,
              },
            });

            setInformationScreen({
              title: 'Wir haben dir eine E-Mail gesendet.',
              description:
                "Bitte gib' als nächstes den Code aus der E-Mail ein, um deine E-Mail Adresse zu bestätigen.",
              iconColor: 'primary',
              icon: IconCheck,
              buttonLabel: 'Code eingeben',
              buttonTargetVariant: 'confirmSignup',
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            let caughtError = false;

            // We only want to catch API specific errors here
            // in all other cases, we throw the error one level higher
            switch (error.code) {
              // This exception is thrown when the Amazon Cognito service encounters an invalid parameter.
              case 'InvalidParameterException':
              case 'InvalidPasswordException':
                if (
                  error.message.includes(
                    "Value at 'password' failed to satisfy constraint",
                  ) ||
                  error.message.includes(
                    'Password did not conform with policy',
                  ) ||
                  error.message.includes('Password does not conform to policy')
                ) {
                  caughtError = true;
                  setErrorInformation({
                    position: 'password',
                    element: (
                      <span>
                        Das Passwort entspricht nicht den Anforderungen. Es
                        dürfen nur Zahlen, Buchstaben und diese Zeichen
                        verwendet werden:{' '}
                        <span
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          sx={{ backgroundColor: 'grey1', px: 1 }}
                        >
                          {
                            ' + = ^ $ * . [ ] { } ( ) ? " ! @ # % & /  , > < \' : ; | _ ~ ` '
                          }
                        </span>
                      </span>
                    ),
                  });
                  break;
                }

                if (error.message.includes('Invalid email address format.')) {
                  caughtError = true;
                  setErrorInformation({
                    position: 'email',
                    element: <span>Ungültiges Format</span>,
                  });
                  break;
                }

                break;

              // This exception is thrown when Amazon Cognito encounters a user name that already exists in the user pool.
              case 'UsernameExistsException':
                caughtError = true;
                setInformationScreen({
                  title: 'Dein Benutzer existiert bereits.',
                  icon: IconExclamation, // @todo find a better icon
                  iconColor: 'warning',
                  description: '',
                  buttonLabel: 'Zum Login',
                  buttonTargetVariant: 'signin',
                });
                break;

              default:
                caughtError = false;
                break;
            }

            if (!caughtError) {
              throw error;
            }
          }
          break;

        case 'confirmSignup':
          /**
           * @see https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ConfirmSignUp.html
           */
          try {
            await Auth.confirmSignUp(
              getUsernameByEmailAddress(email),
              code.trim(),
            );
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            let caughtError = false;

            // We only want to catch API specific errors here
            // in all other cases, we throw the error one level higher
            switch (error.code) {
              // This exception is thrown if the provided code does not match what the server was expecting.
              case 'CodeMismatchException':
                caughtError = true;
                setErrorInformation({
                  position: 'code',
                  element: <span>Der Code ist ungültig.</span>,
                });
                break;

              // This exception is thrown if a code has expired.
              case 'ExpiredCodeException':
                // @todo add instructions on how to retrieve new code
                caughtError = true;
                setInformationScreen({
                  allowClose: true,
                  title: 'Dein Code ist abgelaufen.',
                  icon: IconExclamation, // @todo find a better icon
                  iconColor: 'error',
                  description: (
                    <span>
                      Melde dich gerne bei uns und wir helfen dir weiter:
                      <br />
                      <a href="mailto:info@yuuxyoga.de">info@yuuxyoga.de</a>
                    </span>
                  ),
                });
                break;

              case 'UserNotFoundException':
                caughtError = true;
                setErrorInformation({
                  position: 'email',
                  element: <span>Diese E-Mail Adresse existiert nicht.</span>,
                });
                break;

              default:
                caughtError = false;
                break;
            }

            if (!caughtError) {
              throw error;
            }
            break;
          }

          // send this as a conversion to google
          await gtagReportConversion();

          // We know that the user came here from the previous steps -> direct login because we know the password
          if (password) {
            try {
              /**
               * @see https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_InitiateAuth.html
               * @see https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_RespondToAuthChallenge.html
               * @see https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_GetUser.html
               */
              await Auth.signIn(
                email.trim().toLowerCase().replace('@', '_at_'),
                password,
              );
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
              // This is a special case here, because the "signin" is somehow optional
              // if it fails, we display the user an information screen and redirect him to the login
              setInformationScreen({
                title: 'Aktivierung erfolgreich.',
                description:
                  'Deine E-Mail Adresse wurde erfolgreich aktiviert, du kannst dich nun anmelden.',
                iconColor: 'primary',
                icon: IconCheck,
                buttonLabel: 'Zum Login',
                buttonTargetVariant: 'signin',
              });
            }
          } else {
            setInformationScreen({
              title: 'Aktivierung erfolgreich.',
              description:
                'Deine E-Mail Adresse wurde erfolgreich aktiviert, du kannst dich nun anmelden.',
              iconColor: 'primary',
              icon: IconCheck,
              buttonLabel: 'Zum Login',
              buttonTargetVariant: 'signin',
            });
          }
          break;

        case 'resetPassword':
          try {
            /**
             * @see https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ForgotPassword.html
             */
            await Auth.forgotPassword(email.trim());

            // Unfortunately we do not get any error information on whether the user exists or not
            // so we need to display a generic help message in case the user does not get any email
            setInformationScreen({
              title: 'Wir haben dir eine E-Mail gesendet.',
              description: (
                <React.Fragment>
                  <p>
                    Bitte gib&apos; als nächstes den Code aus der E-Mail ein, um
                    ein neues Passwort festzulegen.
                  </p>
                  <p>
                    Falls du keine E-Mail erhalten hast, melde dich gerne bei
                    uns: <a href="mailto:info@yuuxyoga.de">info@yuuxyoga.de</a>
                  </p>
                </React.Fragment>
              ),
              iconColor: 'primary',
              icon: IconCheck,
              buttonLabel: 'Code eingeben',
              buttonTargetVariant: 'confirmResetPassword',
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            let caughtError = false;

            // We only want to catch API specific errors here
            // in all other cases, we throw the error one level higher
            switch (error.code) {
              // This exception is thrown when a user is not confirmed successfully.
              case 'UserNotConfirmedException':
                caughtError = true;
                setErrorInformation({
                  position: 'email',
                  element: (
                    <span>Bitte bestätige zunächst deinen Benutzer.</span>
                  ),
                });
                break;

              // This exception is thrown when a user is not found.
              case 'UserNotFoundException':
                caughtError = true;
                setErrorInformation({
                  position: 'email',
                  element: <span>Diese E-Mail Adresse existiert nicht.</span>,
                });
                break;

              default:
                caughtError = false;
                break;
            }

            if (!caughtError) {
              throw error;
            }
          }
          break;

        case 'confirmResetPassword':
          try {
            /**
             * @see https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ConfirmForgotPassword.html
             */
            await Auth.forgotPasswordSubmit(
              email.trim(),
              code.trim(),
              password,
            );
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            let caughtError = false;

            // We only want to catch API specific errors here
            // in all other cases, we throw the error one level higher
            switch (error.code) {
              // This exception is thrown when the Amazon Cognito service encounters an invalid parameter.
              case 'InvalidParameterException':
              case 'InvalidPasswordException':
                if (
                  error.message.includes(
                    "Value at 'password' failed to satisfy constraint",
                  ) ||
                  error.message.includes(
                    'Password did not conform with policy',
                  ) ||
                  error.message.includes('Password does not conform to policy')
                ) {
                  caughtError = true;
                  setErrorInformation({
                    position: 'password',
                    element: (
                      <span>
                        Das Passwort entspricht nicht den Anforderungen. Es
                        dürfen nur Zahlen, Buchstaben und diese Zeichen
                        verwendet werden:{' '}
                        <span
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          sx={{ backgroundColor: 'grey1', px: 1 }}
                        >
                          {
                            ' + = ^ $ * . [ ] { } ( ) ? " ! @ # % & /  , > < \' : ; | _ ~ ` '
                          }
                        </span>
                      </span>
                    ),
                  });
                  break;
                }
                break;

              // This exception is thrown if the provided code does not match what the server was expecting.
              case 'CodeMismatchException':
                caughtError = true;
                setErrorInformation({
                  position: 'code',
                  element: <span>Der Code ist ungültig.</span>,
                });
                break;

              // This exception is thrown if a code has expired.
              case 'ExpiredCodeException':
                // @todo add instructions on how to retrieve new code
                caughtError = true;
                setInformationScreen({
                  allowClose: true,
                  title: 'Dein Code ist abgelaufen.',
                  icon: IconExclamation, // @todo find a better icon
                  iconColor: 'error',
                  description: (
                    <span>
                      Melde dich gerne bei uns und wir helfen dir weiter:
                      <br />
                      <a href="mailto:info@yuuxyoga.de">info@yuuxyoga.de</a>
                    </span>
                  ),
                });
                break;

              // This exception is thrown when a user is not confirmed successfully.
              case 'UserNotConfirmedException':
                caughtError = true;
                setErrorInformation({
                  position: 'email',
                  element: (
                    <span>Bitte bestätige zunächst deinen Benutzer.</span>
                  ),
                });
                break;

              // This exception is thrown when a user is not found.
              case 'UserNotFoundException':
                caughtError = true;
                setErrorInformation({
                  position: 'email',
                  element: <span>Diese E-Mail Adresse existiert nicht.</span>,
                });
                break;

              default:
                caughtError = false;
                break;
            }

            if (!caughtError) {
              throw error;
            }
            break;
          }

          // We know that the user came here from the previous steps -> direct login because we know the password
          if (password) {
            try {
              /**
               * @see https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_InitiateAuth.html
               * @see https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_RespondToAuthChallenge.html
               * @see https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_GetUser.html
               */
              await Auth.signIn(
                email.trim().toLowerCase().replace('@', '_at_'),
                password,
              );
            } catch (error) {
              // This is a special case here, because the "signin" is somehow optional
              // if it fails, we display the user an information screen and redirect him to the login
              setInformationScreen({
                title: 'Passwort erfolgreich geändert.',
                description:
                  'Dein Passwort wurde erfolgreich geändert, du kannst dich nun anmelden.',
                iconColor: 'primary',
                icon: IconCheck,
                buttonLabel: 'Zum Login',
                buttonTargetVariant: 'signin',
              });
            }
          } else {
            setInformationScreen({
              title: 'Passwort erfolgreich geändert.',
              description:
                'Dein Passwort wurde erfolgreich geändert, du kannst dich nun anmelden.',
              iconColor: 'primary',
              icon: IconCheck,
              buttonLabel: 'Zum Login',
              buttonTargetVariant: 'signin',
            });
          }
          break;

        default:
          break;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      Sentry.configureScope((scope) => {
        scope.setExtra('errorCode', err?.code);
        scope.setExtra('errorCodeB64', btoa(err?.code));
        scope.setExtra('errorMessage', err?.message);
        scope.setExtra('errorMessageB64', btoa(err?.message));
        scope.setExtra('errorName', err?.name);
        scope.setExtra('errorNameB64', btoa(err?.name));
        Sentry.captureException(
          new Error('Signup Flow Uncaught Cognito Error Response'),
        );
      });

      setInformationScreen({
        allowClose: true,
        title: 'Sorry!',
        icon: IconExclamation, // @todo find a better icon
        iconColor: 'warning',
        description: (
          <span>
            Da hat wohl etwas nicht funktioniert! <br />
            Melde dich gerne bei uns und wir helfen dir weiter:
            <br />
            <a href="mailto:info@yuuxyoga.de">info@yuuxyoga.de</a>
          </span>
        ),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <InformationModal
        informationScreen={informationScreen}
        setInformationScreen={setInformationScreen}
        setCurrentVariant={setCurrentVariant}
      />

      <form
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        sx={{ m: 0, p: 0 }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onSubmit={onSubmit}
      >
        <Container sx={{ width: '24rem' }}>
          <Text variant="h1" style={{ marginBottom: '8rem' }}>
            {title}
          </Text>

          <Text
            style={{
              color: 'rgb(17, 17, 17)',
              fontSize: '24px',
              fontWeight: 'bold',
            }}
          >
            {subtitle}
          </Text>

          {variant === 'signin' && (
            <SignInForm
              errorInformation={errorInformation}
              onClickFacebookLogin={onClickFacebookLogin}
              onClickGoogleLogin={onClickGoogleLogin}
              onClickAppleLogin={onClickAppleLogin}
              setEmail={setEmail}
              setPassword={setPassword}
              setCurrentVariant={setCurrentVariant}
              loading={loading}
            />
          )}

          {variant === 'resetPassword' && (
            <ResetPassword
              errorInformation={errorInformation}
              setCurrentVariant={setCurrentVariant}
              setEmail={setEmail}
            />
          )}

          {variant === 'confirmResetPassword' && (
            <ConfirmResetPassword
              confirmCode={confirmCode}
              errorInformation={errorInformation}
              setCurrentVariant={setCurrentVariant}
              setCode={setCode}
              setPassword={setPassword}
            />
          )}

          {variant === 'signup' && (
            <SignUpForm
              errorInformation={errorInformation}
              onClickFacebookLogin={onClickFacebookLogin}
              onClickGoogleLogin={onClickGoogleLogin}
              onClickAppleLogin={onClickAppleLogin}
              setEmail={setEmail}
              setPassword={setPassword}
              setCurrentVariant={setCurrentVariant}
              conditionsAccepted={conditionsAccepted}
              setConditionsAccepted={setConditionsAccepted}
              newsletter={newsletter}
              setNewsletter={setNewsletter}
              setCode={setCode}
              confirmCode={confirmCode}
            />
          )}
        </Container>
      </form>
    </>
  );
}
