import React, { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import toggleFullScreen, {
  isFullScreen,
  supportsFullScreen,
} from '../../../utils/toggleFullScreen';

export interface FullscreenToggleProps {
  order: number;
}

const FullscreenToggle: React.FC<FullscreenToggleProps> = () => {
  const [isSupported, setSupported] = useState(false);
  const [isFullState, setFullState] = useState(false);

  useEffect(() => {
    setSupported(supportsFullScreen());
  }, []);

  useEffect(() => {
    const updateFullScreenState = debounce((): void => {
      setFullState(isFullScreen());
    }, 300);

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateFullScreenState);
    }

    return (): void => {
      window.removeEventListener('resize', updateFullScreenState);
    };
  }, []);

  const handleClick = (): void => {
    const fullScreenState = toggleFullScreen();
    setFullState(fullScreenState);

    window.dispatchEvent(new Event('resize'));
  };

  if (!isSupported) {
    return null;
  }

  return (
    <button
      className={`video-react-fullscreen-control video-react-control video-react-button video-react-icon ${
        isFullState
          ? 'video-react-icon-fullscreen-exit'
          : 'video-react-icon-fullscreen'
      }`}
      type="button"
      tabIndex={0}
      onClick={handleClick}
    >
      <span className="video-react-control-text">Non-Fullscreen</span>
    </button>
  );
};

export default FullscreenToggle;
