import { z } from 'zod';

const EnvSchema = z.object({
  NODE_ENV: z.string(),
  PUBLIC_DIR: z.string(),
  GATSBY_BASE_URL: z.string().nonempty(),
  GATSBY_API_URL: z.string().nonempty(),
  GATSBY_GRACE_PERIOD: z.preprocess(
    (v) => (typeof v === 'string' ? parseInt(v, 10) : v),
    z.number(),
  ),
  GATSBY_REVENUE_CAT_API_KEY: z.string(),
  GATSBY_AWS_USER_POOL_ID: z.string().nonempty(),
  GATSBY_AWS_USER_POOL_CLIENT_ID: z.string().nonempty(),
  GATSBY_AWS_OAUTH2_DOMAIN: z.string().nonempty(),
});

const envResult = EnvSchema.safeParse({
  NODE_ENV: process.env.NODE_ENV,
  PUBLIC_DIR: process.env.PUBLIC_DIR,
  GATSBY_BASE_URL: process.env.GATSBY_BASE_URL,
  GATSBY_API_URL: process.env.GATSBY_API_URL,
  GATSBY_GRACE_PERIOD: process.env.GATSBY_GRACE_PERIOD,
  GATSBY_REVENUE_CAT_API_KEY: process.env.GATSBY_REVENUE_CAT_API_KEY,
  GATSBY_AWS_USER_POOL_ID: process.env.GATSBY_AWS_USER_POOL_ID,
  GATSBY_AWS_USER_POOL_CLIENT_ID: process.env.GATSBY_AWS_USER_POOL_CLIENT_ID,
  GATSBY_AWS_OAUTH2_DOMAIN: process.env.GATSBY_AWS_OAUTH2_DOMAIN,
});

if (!envResult.success) {
  throw new Error(
    `Invalid environment variables: ${JSON.stringify(envResult.error)}`,
  );
}

export default envResult.data;
