import type { LabelName, UserPreferences } from '@youga/youga-interfaces';

type NonNullable<T> = Exclude<T, null | undefined>;

export type GoalType = UserPreferences['goal'];

export interface GoalMeta {
  id: NonNullable<GoalType>;
  name: string;
  title: string;
  optionTitle: string;
}

export const GoalsMeta: Record<
  NonNullable<UserPreferences['goal']>,
  GoalMeta
> = {
  '30_days_challenge': {
    id: '30_days_challenge',
    name: '',
    title: 'Meine 30 Day Challenge',
    optionTitle: '30-Day Challenge',
  },
  headstand: {
    id: 'headstand',
    name: 'Kopfstand',
    title: 'Mein Weg zum Kopfstand.',
    optionTitle: 'Kopfstand lernen',
  },
  basics: {
    id: 'basics',
    name: 'Basics',
    title: 'Starte jetzt mit den Basics.',
    optionTitle: 'Die Basics lernen',
  },
  other: { id: 'other', name: '', title: '', optionTitle: 'Anderes Ziel' },
};

export function getGoalMeta(id: string): GoalMeta | null {
  switch (id) {
    case GoalsMeta['30_days_challenge'].id:
      return GoalsMeta['30_days_challenge'];
    case GoalsMeta.headstand.id:
      return GoalsMeta.headstand;
    case GoalsMeta.basics.id:
      return GoalsMeta.basics;
    case GoalsMeta.other.id:
      return GoalsMeta.other;
    default:
      return null;
  }
}

export type FlagType = 'POPULAR' | 'NEW';

export const flags: Record<FlagType, LabelName> = {
  POPULAR: 'POPULAR',
  NEW: 'NEW',
};
