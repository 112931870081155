import React, { useState } from 'react';
import { Button, Box, Image, Grid, ThemeUIStyleObject } from 'theme-ui';
import { PosesDataType } from '@youga/youga-interfaces';
import Text from '../Text/Text';
import { BurstPhoto, TrainingSessionImage } from '../../../types/interfaces';
import BtnInfoCircle from '../../../assets/btnInfoCircle.svg';
import BtnCheckCircle from '../../../assets/btnCheckCircle.svg';
import BtnCollapseCircle from '../../../assets/btnCollapseCircle.svg';
import BurstImageSwitcher from '../../02_molecules/BurstImageSwitcher/BurstImageSwitcher';
import * as Sentry from '@sentry/browser';

interface PoseComparisonItemProps {
  deletable: boolean;
  onDeleteClick: (
    imageName: string,
    beforeCloseCallback?: () => Promise<void>,
  ) => void;
  example?: boolean;
  tipsActive: boolean;
  setTipsActive: (imageName: string | null) => void;
  image: TrainingSessionImage;
  pose: PosesDataType;
  onUpdateBurstImage: (
    imageName: string,
    burstPhoto: BurstPhoto,
  ) => Promise<void>;
}

const PoseComparisonItem: React.FC<PoseComparisonItemProps> = ({
  deletable,
  onDeleteClick,
  example = false,
  tipsActive,
  setTipsActive,
  image,
  pose,
  onUpdateBurstImage,
}: PoseComparisonItemProps) => {
  const burstPhotoUrls = image.burstPhotoUrls || [];

  const [saveDisabled, setSaveDisabled] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [burstPhotoIndex, setBurstPhotoIndex] = useState<number>(
    burstPhotoUrls.findIndex((bp) => bp.url === image.url),
  );

  const tipBoxStyle: ThemeUIStyleObject = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const imageBoxStyle: ThemeUIStyleObject = {
    position: 'relative',
    width: '100%',
    paddingTop: '75%',
  };

  const imageBoxStyleCover: ThemeUIStyleObject = {
    width: '100%',
    paddingTop: '75%',
    backgroundSize: 'cover',
    backgroundColor: '#0000000f',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <Box key={image.imageName} sx={{ position: 'relative', mt: 7 }}>
      <Box sx={{ width: '100%' }}>
        <Image
          src={tipsActive ? BtnCollapseCircle : BtnInfoCircle}
          sx={{
            position: 'absolute',
            top: 0,
            width: '30px',
            height: '30px',
            cursor: 'pointer',
            zIndex: 1,
            ...(example ? { right: 0 } : { left: 0 }),
          }}
          onClick={(e): void => {
            e.preventDefault();
            setTipsActive(!tipsActive ? image.imageName : null);
          }}
        />
        <Text
          variant="default"
          sx={{
            fontSize: 3,
            display: 'inline-block',
            fontFamily: 'heading',
            px: '30px',
            width: '100%',
            lineHeight: '30px',
            textAlign: 'center',
            position: 'relative',
          }}
        >
          {pose.de}
        </Text>
        {!example && (
          <Button
            disabled={saveDisabled}
            variant="primary"
            sx={{
              position: 'absolute',
              textAlign: 'center',
              display: 'inline-block',
              top: 0,
              right: 0,
              maxWidth: 'none',
              width: '140px !important',
              height: '30px',
              fontSize: '12px',
              py: 2,
              lineHeight: 1,
              transition:
                'background-color 0.3s ease-in, border 0.3s ease-in, opacity 0.3s ease-in',
              backgroundColor: !isEditMode ? 'transparent' : undefined,
              border: !isEditMode ? '1px solid black' : '1px solid transparent',
            }}
            onClick={async (event): Promise<void> => {
              event.preventDefault();
              if (isEditMode && burstPhotoUrls.length > 1) {
                setSaveDisabled(true);
                try {
                  await onUpdateBurstImage(
                    image.imageName,
                    burstPhotoUrls[burstPhotoIndex],
                  );
                } catch {
                  Sentry.captureException(
                    new Error(
                      'Someting went wrong, saving the Picture in Pose Comparison.',
                    ),
                  );
                }
                setEditMode(false);
                setSaveDisabled(false);
                return;
              }

              setEditMode((state) => !state);
            }}
          >
            {isEditMode ? 'Speichern' : 'Bearbeiten'}
          </Button>
        )}
      </Box>

      <Grid
        columns={[1, null, 2]}
        sx={{
          columnGap: 5,
          alignItems: 'center',
          mt: 4,
          mb: 4,
        }}
      >
        {tipsActive ? (
          <Box sx={tipBoxStyle}>
            {pose.tips.de.map((t) => (
              <Box
                key={t}
                sx={{
                  my: 2,
                  textAlign: 'left',
                  paddingLeft: '30px',
                  width: '100%',
                  maxWidth: '350px',
                  position: 'relative',
                }}
              >
                <Image
                  src={BtnCheckCircle}
                  sx={{
                    position: 'absolute',
                    top: '2px',
                    left: 0,
                  }}
                />
                <Text sx={{ m: 0 }}>{t}</Text>
              </Box>
            ))}
          </Box>
        ) : (
          <Box
            sx={{
              ...imageBoxStyleCover,
              backgroundImage: `url(${image.referenceUrl})`,
            }}
          />
        )}
        <Box sx={imageBoxStyle}>
          <Image
            className="fs-exclude"
            data-hj-suppress=""
            src={
              burstPhotoUrls.length > 1
                ? burstPhotoUrls[burstPhotoIndex].url
                : image.url
            }
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: 'center center',
            }}
          />

          {!example && burstPhotoUrls.length > 1 && (
            <BurstImageSwitcher
              deletable={deletable}
              onDeleteClick={onDeleteClick}
              image={image}
              imageCount={burstPhotoUrls.length}
              editMode={isEditMode}
              burstPhotoIndex={burstPhotoIndex}
              onPrevClick={(): void => {
                if (burstPhotoIndex === 0) {
                  setBurstPhotoIndex(burstPhotoUrls.length - 1);
                } else {
                  setBurstPhotoIndex(burstPhotoIndex - 1);
                }
              }}
              onNextClick={(): void => {
                if (burstPhotoIndex === burstPhotoUrls.length - 1) {
                  setBurstPhotoIndex(0);
                } else {
                  setBurstPhotoIndex(burstPhotoIndex + 1);
                }
              }}
            />
          )}
        </Box>
      </Grid>
    </Box>
  );
};

export default PoseComparisonItem;
