import React from 'react';
import { Link } from 'gatsby';
import { Box, Grid, Image } from 'theme-ui';
import Container from '../../01_atoms/Container/Container';
import Text from '../../01_atoms/Text/Text';
import Button from '../../01_atoms/Button/Button';
import PoseComparisonSessionItem from '../../01_atoms/PoseComparisonSessionItem/PoseComparisonSessionItem';
import SeheDeinenFortschrittselbst from '../../../assets/SeheDeinenFortschrittselbst.svg';

const PoseComparisonOverviewEmpty: React.FC<unknown> = () => (
  <Box
    sx={{
      mt: 9,
      pt: 6,
      pb: 6,
      backgroundColor: 'grey1',
      position: 'relative',
    }}
  >
    <Container>
      <Grid columns={[1, 3, 4]} sx={{ columnGap: [0, 5, null, 11], rowGap: 6 }}>
        <Box sx={{ width: '232px', display: 'flex', alignItems: 'center' }}>
          <PoseComparisonSessionItem
            key="example"
            poseImage="https://yougacdkstackprod-imagebucket6194e37c-op33z8eeb47w.s3.eu-central-1.amazonaws.com/pose-comparison-example/reference-pics/189.jpg"
            userImage="https://yougacdkstackprod-imagebucket6194e37c-op33z8eeb47w.s3.eu-central-1.amazonaws.com/pose-comparison-example/user-pics/189.png"
            sessionName="Pose Comparison Beispiel"
            sessionId="example"
            target="/app/pose-comparison/example"
          />
        </Box>
        <Box
          sx={{
            gridColumn: [1, '2 / 5'],
          }}
        >
          <Image src={SeheDeinenFortschrittselbst} />
          <Text
            variant="default"
            sx={{
              mt: 3,
              maxWidth: '640px',
              fontSize: 0,
              lineHeight: 1.5,
              letterSpacing: 0,
            }}
          >
            Mit Hilfe der Pose Comparison bieten wir Dir Dein privates
            Yoga-Tagebuch, auf dem nur Du Zugriff hast. Im Beispiel kannst Du
            sehen was hinter der Pose Comparison steckt. Starte jetzt Deine
            erste Session, um Deine persönliche Gallerie zu füllen. So kannst Du
            die einzelnen Posen mit der Lehrerin vergleichen und Deine
            Fortschritte beobachten.
          </Text>
          <Link to="/app/pose-comparison/example">
            <Button variant="primary" sx={{ mt: 6 }}>
              Beispiel ansehen
            </Button>
          </Link>
        </Box>
      </Grid>
    </Container>
  </Box>
);

export default PoseComparisonOverviewEmpty;
