import React from 'react';
import { Link } from 'gatsby';

import Button from '../Button/Button';

export default function SmartMirrorLinkButton() {
  return (
    <div style={{ position: 'absolute', right: 0 }}>
      <Link to="/connect">
        <Button
          variant="whiteBorder"
          sx={{
            textAlign: 'center',
            justifyContent: 'center',
            maxWidth: '224px',
            height: '40px',
            fontSize: '0.75rem',
            whiteSpace: 'nowrap',
          }}
        >
          SmartMirror verbinden
        </Button>
      </Link>
    </div>
  );
}
