import DefaultPageLayout from '../components/04_templates/DefaultPageLayout';

function ImprintPage() {
  return (
    <DefaultPageLayout title="Impressum">
      <h4>Anbieter</h4>

      <p>
        Initium GmbH
        <br />
        Stralauer Allee 12
        <br />
        10245 Berlin
        <br />
        Deutschland
        <br />
      </p>

      <h4>Kontakt</h4>

      <p>Telefonnummer: +49 (0) 711 911 37000</p>

      <p>
        E-Mail: <a href="mailto:info@yuuxyoga.de">info@yuuxyoga.de</a>
      </p>

      <h4>Vertreten durch</h4>

      <p>Mattias Ulbrich, Stefan Zerweck</p>

      <h4>Registriereintrag</h4>

      <p>
        Registergericht: Amtsgericht Charlottenburg (Berlin)
        <br />
        HRB-Nr.: 216902 B<br />
        USt-Ident.-Nr.: DE332621103
        <br />
      </p>

      <h4>Verbraucherinformation gemäß Verbraucherstreitbeilegungsgesetz</h4>

      <p>
        Die Initium GmbH ist nicht bereit und verpflichtet, an
        Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
        teilzunehmen.
      </p>
    </DefaultPageLayout>
  );
}

export default ImprintPage;
