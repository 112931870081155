/** @jsxImportSource theme-ui */

import React from 'react';
import { Link } from 'gatsby';
import { Box, Image, ThemeUIStyleObject } from 'theme-ui';
import Text from '../Text/Text';
import IllustrationSmartMirror from '../../../assets/images/IllustrationSmartMirror.svg';

export interface SmartMirrorInfoBoxProps {
  className?: string;
  sx?: ThemeUIStyleObject;
}

const SmartMirrorInfoBox: React.FC<SmartMirrorInfoBoxProps> = ({
  className = '',
  sx,
}: SmartMirrorInfoBoxProps) => (
  <Box
    className={className}
    sx={{
      position: 'relative',
      boxShadow: '0px 10px 16px 0px rgba(0,0,0,0.10)',
      borderRadius: '10px',
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      py: [4, '13px', 4, '13px'], // 13px is exactly the padding that we need where three boxes in a column (with 1rem gap) result in a square
      px: 4,
      flexDirection: ['column', 'row', 'column', 'row'],
      ...sx,
    }}
  >
    <Box sx={{ width: '45%', textAlign: 'center', flexShrink: 0 }}>
      <Box
        sx={{
          width: '160px',
          height: '150px',
          maxWidth: '100%',
          mx: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Image src={IllustrationSmartMirror} />
      </Box>
    </Box>
    <Box
      sx={{
        textAlign: ['center', 'left', 'center', 'left'],
        mt: [3, 0, 3, 0],
      }}
    >
      <Text sx={{ mt: 0, mb: 2, zIndex: 1 }} variant="h4">
        Smart Mirror
      </Text>
      <Text sx={{ fontSize: 0, fontFamily: 'heading' }}>
        Verbessere dich direkt in der Session durch visuelles Feedback.
      </Text>
    </Box>
    <Link
      to="/app/how-it-works"
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 5,
      }}
    />
  </Box>
);

export default SmartMirrorInfoBox;
