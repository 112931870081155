import { useCoursesFavourites, useInstructors } from '@youga/youga-client-api';
import { Box } from 'theme-ui';

import Text from '../../01_atoms/Text/Text';
import Carousel from '../../02_molecules/Carousel/Carousel';

import AnimatedFavoriteCourses from './AnimatedFavoriteCourses';

function YourFavouriteCourses() {
  const { data: instructorsData } = useInstructors();
  const favoriteCourses = useCoursesFavourites();

  if (favoriteCourses.length === 0) {
    return null;
  }

  return (
    <>
      <Text variant="h2light" sx={{ fontSize: [3, 4], mt: [3, 7], mb: [3, 4] }}>
        Deine Favoriten
      </Text>
      <Box
        style={{
          position: 'relative',
          // We need to extend the width a bit, to allow the shadow to be visible
          // This space will be added afterwards as a padding-left at the items
          marginLeft: '-20px',
          marginRight: '-20px',
          width: 'calc(100% + 40px)',
        }}
      >
        <Carousel
          imageHeight={180}
          hideControlsOnTouchDevices
          hideControlShadow
          swipeLeftStyle={{
            top: 7,
            left: 2,
          }}
          swipeRightStyle={{
            top: 7,
            right: 2,
          }}
        >
          <AnimatedFavoriteCourses
            favoriteCourses={favoriteCourses}
            instructors={instructorsData?.instructors}
          />
        </Carousel>
      </Box>
    </>
  );
}

export default YourFavouriteCourses;
