import React, { useState } from 'react';
import { Box } from 'theme-ui';
import API from '../../../services/API';
import Text from '../../01_atoms/Text/Text';
import Button from '../../01_atoms/Button/Button';
import TextInput from '../../01_atoms/TextInput/TextInput';
import Checkbox from '../../01_atoms/Checkbox/Checkbox';

interface EnterProps {
  onContinue: () => void;
}

const Enter: React.FC<EnterProps> = ({ onContinue }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState<null | React.ReactNode>(null);
  const [checked, setChecked] = useState(false);

  const onSubmit = async (): Promise<void> => {
    setError(null);
    setLoading(true);

    const emailPattern = /\S+@\S+\.\S+/;

    if (!emailPattern.test(email)) {
      setError(<span>Ungültige E-Mail Adresse.</span>);
      setLoading(false);
      return;
    }

    try {
      await API.joinMailingList(email, 'android');
      onContinue();
    } catch (e) {
      setError(<span>Ungültige E-Mail Adresse.</span>);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Text variant="h3" sx={{ m: 0, textAlign: 'center' }}>
        Jetzt vormerken
      </Text>
      <Text variant="h3" sx={{ m: 0, textAlign: 'center' }}>
        für die Android Version.
      </Text>

      <Box sx={{ mt: 7, textAlign: 'left' }}>
        <TextInput
          id="email"
          type="text"
          name="email"
          placeholder="E-Mail Adresse"
          required
          onChange={(e): void => {
            setEmail(e.target.value);
          }}
          error={error}
        />
      </Box>

      <Box sx={{ mt: 7, textAlign: 'left' }}>
        <Checkbox
          id="conditions"
          name="conditions"
          checked={checked}
          onChange={(e): void => {
            setChecked(e.target.checked);
          }}
        >
          Ich möchte informiert werden, sobald die Yuu Yoga App auch als Android
          App verfügbar ist.
        </Checkbox>
      </Box>

      <Box sx={{ mt: 9 }}>
        <Button
          variant="primary"
          onClick={onSubmit}
          disabled={loading || !checked}
        >
          Abschicken
        </Button>
      </Box>
    </>
  );
};

export default Enter;
