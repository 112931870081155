import { Text } from 'theme-ui';

import AdaptiveTable from '../components/02_molecules/AdaptiveTable/AdaptiveTable';
import DefaultPageLayout from '../components/04_templates/DefaultPageLayout';

function CookiePolicyPage() {
  return (
    <DefaultPageLayout title="Cookie Policy">
      <h4>Anwendungsbereich</h4>
      <p>
        Diese Cookie Policy gilt ergänzend zur allgemeinen Datenschutzerklärung
        für unsere Website und beschreibt Art, Umfang, Zwecke und
        Rechtsgrundlagen der Datenverarbeitung bei Cookies. Im Übrigen gilt
        hinsichtlich aller weiteren Informationen die allgemeine
        Datenschutzerklärung.
      </p>
      <h4>Was sind Cookies?</h4>

      <p>
        Um Ihnen einen umfangreichen Funktionsumfang zu bieten, die Nutzung
        komfortabler zu gestalten und um unsere Angebote optimieren zu können,
        verwenden wir sogenannte „Cookies“. Cookies sind kleine Dateien, die mit
        Hilfe Ihres Internetbrowsers auf Ihrem Endgerät gespeichert werden.
      </p>

      <h4>Kategorien von Cookies</h4>

      <p>
        Wir setzen Cookies zu unterschiedlichen Zwecken und mit
        unterschiedlichen Funktionen ein. Hierbei unterscheiden wir danach, ob
        der Cookie technisch zwingend erforderlich ist (Technische
        Erforderlichkeit), wie lange er gespeichert und verwendet wird
        (Speicherdauer) und ob er durch unsere Webseite selbst oder durch Dritte
        sowie durch welchen Anbieter dieser Cookie gesetzt wurde
        (Cookie-Anbieter).
      </p>

      <h4>Technische Erforderlichkeit</h4>

      <p>
        Technisch erforderliche Cookies: Wir verwenden bestimmte Cookies, weil
        sie zwingend erforderlich sind, damit unsere Website ordnungsgemäß
        arbeiten können. Diese Cookies werden automatisch bei Aufruf unserer
        Website oder einer bestimmten Funktion gesetzt, es sei denn, Sie haben
        das Setzen von Cookies durch entsprechende Einstellungen in Ihrem
        Endgerät bzw. Browser verhindert.
      </p>

      <p>
        Technisch nicht erforderliche Cookies: Demgegenüber werden nicht
        zwingend erforderliche Cookies gesetzt, um beispielsweise Komfort und
        Leistung der Website zu verbessen oder bestimmte von Ihnen vorgenommene
        Einstellungen zu speichern. Wir setzen technisch nicht erforderliche
        Cookies außerdem ein, um Informationen zur Nutzungshäufigkeit bestimmter
        Bereiche unserer Website zu ermitteln, damit wir diese zukünftig
        gezielter auf Ihre Bedürfnisse abstimmen können.
      </p>

      <h4>Speicherdauer</h4>

      <p>
        Session-Cookies: Die meisten Cookies werden lediglich für die Dauer
        Ihres aktuellen Dienste-Aufrufs oder Ihrer Sitzung (Session) benötigt
        und wieder gelöscht bzw. verlieren ihre Gültigkeit, sobald Sie unsere
        Website verlassen oder Ihre aktuelle Sitzung abläuft (sogenannte
        „Session Cookies“). Session Cookies werden beispielsweise verwendet, um
        während Ihrer Sitzung bestimmte Informationen wie etwa Ihre Anmeldung
        für unsere Website beizubehalten.
      </p>

      <p>
        Permanent Cookies: Nur vereinzelt werden Cookies über einen längeren
        Zeitraum gespeichert, etwa um Sie beim erneuten Aufruf unserer Website
        zu einem späteren Zeitpunkt wiedererkennen und gespeicherte
        Einstellungen aufrufen zu können. Hierdurch können Sie beispielsweise
        schneller oder bequemer auf unsere Website zugreifen oder müssen
        bestimmte Einstellungen wie Ihre gewählte Sprache nicht erneut
        vornehmen. Permanent Cookies werden nach Ablauf eines vordefinierten
        Zeitraums automatisch gelöscht.
      </p>

      <h4>Cookie-Anbieter</h4>

      <p>
        Drittanbieter-Cookies: Sogenannte „Drittanbieter-Cookies“ werden durch
        andere Stellen oder Webseiten, beispielsweise durch Anbieter von Tools
        zur Webanalyse gesetzt und verwendet. Nähere Informationen zu Tools zur
        Webanalyse erhalten Sie im weiteren Verlauf dieser Cookie Policy.
        Drittanbieter können Cookies außerdem verwenden, um Werbung anzuzeigen
        oder um Inhalte sozialer Netzwerke wie z.B. Social Plugins zu
        integrieren.
      </p>

      <h4> Einsatz von Tools zur Webanalyse</h4>

      <p>
        Wir verwenden im Bereich der Nutzungsanalyse den Webanalysedienst Google
        Analytics, angeboten von Google Ireland Ltd., Gordon House, Barrow
        Street, Dublin 4, Irland. Google Analytics verwendet
        Drittanbieter-Cookies, um die Häufigkeit der Nutzung bestimmter Bereiche
        unserer Website und Präferenzen zu identifizieren. Die durch den Cookie
        erzeugten Informationen über Ihre Nutzung (einschließlich Ihrer
        verkürzten IP-Adresse) werden an einen Server von Google in den USA
        übertragen und dort gespeichert. Google wird diese Informationen in
        unserem Auftrag benutzen, um Ihre Nutzung unserer Website auszuwerten,
        um Reports für uns zusammenzustellen und um weitere mit der Webanalyse
        verbundene Dienstleistungen zu erbringen. Informationen zum Datenschutz
        und zur Sicherheit bei Google Analytics finden Sie unter
        https://support.google.com/analytics/answer/6004245?hl=en.
      </p>

      <p>
        Wir setzen für unsere Marketingmaßnahmen und im Bereich der
        Nutzungsanalyse den Dienst Facebook Pixel ein (Anbieter: Facebook
        Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2,
        Irland). Hiermit kann einer bestimmten definierten Zielgruppe auf ihre
        Interessen zugeschnittene Werbung ausgespielt werden. Die Werbung bei
        Facebook und dessen Partnern wird dann nur solchen Nutzern angezeigt,
        die angesichts ihres Nutzungsverhaltens Interesse an den entsprechenden
        Angeboten haben könnten (sog. Custom Audiences). Wenn Sie sich im
        Zusammenhang mit der Nutzung unserer Website bzw. App bei Facebook
        einloggen, wird eine Verbindung zum Facebook-Server hergestellt und
        diese Information mit Ihrem Facebook-Account verbunden, damit Sie ggf.
        einer Zielgruppe zugeordnet werden können. Facebook sammelt aber ggf.
        auch Daten, wenn Sie nicht parallel in Ihrem Facebook-Account eingeloggt
        sind. Über den Facebook Pixel können wir zudem statistisch
        nachvollziehen, welche Werbung zu einem Besuch unserer Website bzw. App
        geführt hat. So können wir die Effizienz unserer Anzeigen für
        statistische Zwecke und Marktforschungszwecke auswerten und auf dieser
        Grundlage zukünftige Werbemaßnahmen optimieren. Weitere Informationen
        zum Datenschutz in Bezug auf den Facebook Pixel finden Sie unter
        https://de-de.facebook.com/privacy/explanation.
      </p>

      <p>
        Wir verwenden zur Analyse der Stabilität der Anwendungen und zur
        Ermittlung von Fehlfunktionen der Programmierung den Dienst Sentry
        (Anbieter: Functional Software, 132 Hawthorne Street, San Francisco, CA
        94107, USA). Sentry verwendet Drittanbieter-Cookies, um in unserem
        Auftrag Informationen zur Analyse wie Angaben zum verwendeten Gerät oder
        zum Fehlerzeitpunkt zu ermitteln und uns zur Verfügung zu stellen.
        Weitere Informationen zum Datenschutz und zur Sicherheit bei Sentry
        finden Sie unter https://sentry.io/privacy/.
      </p>

      <p>
        Wir verwenden im Bereich der Nutzungsanalyse den Webanalysedienst
        FullStory (Anbieter: FullStory, Inc., 1745 Peachtree St NE, Suite G,
        Atlanta GA 30309, USA). FullStory verwendet Drittanbieter-Cookies, um
        das Nutzerverhalten auf der Website zu analysieren (ausgewählte Links,
        Spezifikationen des abrufenden Endgeräts, Klicks, Mausbewegungen).
        FullStory wird die gesammelten Informationen in unserem Auftrag und auf
        Basis eines Vertrages zur Auftragsverarbeitung verwenden, um Ihre
        Nutzung unserer Website auszuwerten, um Reports über die
        Nutzungs-Aktivitäten für uns zusammenzustellen und um weitere damit
        verbundene Dienstleistungen zu erbringen. Weitere Informationen zum
        Datenschutz in Bezug auf FullStory finden Sie unter
        https://www.fullstory.com/legal/privacy-policy.
      </p>

      <p>
        Wir nutzen Tracking-Technologien des Anbieters Singular (Singular Labs
        Inc., 181 South Park Street, Apt 2, San Francisco, CA 94107, USA). Um
        unsere Dienste konstant zu verbessern, erheben wir mit Singular Daten
        über die Nutzung unserer Dienste und darüber, durch welche
        Kampagnen/Drittseiten Sie auf unsere Dienste geleitet wurden. Es werden
        dazu insbesondere Informationen genutzt, die Ihr Endgerät an uns
        übermittelt wie etwa die IP-Adresse oder Werbe-Identifier, die über das
        Betriebssystem des Endgeräts vergeben werden. Weitere Informationen zum
        Datenschutz in Bezug auf Singular finden Sie unter
        https://www.singular.net/privacy-policy.
      </p>

      <h4>Rechtsgrundlage für den Einsatz der Cookies</h4>

      <p>
        Soweit Sie die Website nutzen, setzen wir technisch nicht erforderliche
        Cookies nur ein, wenn Sie Ihre Einwilligung per Opt-in erklärt haben.
        Die gesetzliche Grundlage ist dann Artikel 6 Absatz 1 Buchstabe a)
        DSGVO. Sie können Ihre Einwilligung jederzeit mit Wirkung für die
        Zukunft widerrufen, indem Sie das Opt-in durch die entsprechenden
        Einstellungsmöglichkeiten der Website bzw. Ihres Browsers deaktivieren.
        Die gesetzliche Grundlage für den Einsatz technisch erforderlicher
        Cookies ist Artikel 6 Absatz 1 Buchstabe f) DSGVO; unser berechtigtes
        Interesse besteht darin, dass unsere Website ordnungsgemäß arbeiten
        können muss.
      </p>

      <h4>Löschung und Widerspruch gegen die Verwendung von Cookies</h4>

      <p>
        Die Akzeptanz von Cookies ist nicht verpflichtend; falls Sie nicht
        möchten, dass Cookies auf Ihrem Endgerät gespeichert werden, können Sie
        die entsprechende Option in den Systemeinstellungen Ihres Endgeräts bzw.
        Ihres Browsers deaktivieren. Gespeicherte Cookies können Sie in den
        Systemeinstellungen Ihres Endgeräts bzw. Ihres Browsers jederzeit
        löschen. Wenn Sie keine Cookies akzeptieren, kann dieses allerdings zu
        Funktionseinschränkungen unserer Angebote führen.
      </p>

      <h4>Liste der Cookies</h4>

      <p>
        Im Einzelnen können beim Aufrufen unserer Webseite folgende Cookies
        gespeichert werden:
      </p>

      <AdaptiveTable
        headerItems={[
          'Name des Cookies',
          'Typ',
          'Speicherdauer',
          'Anbieter',
          'Zweck',
          'Rechtsgrundlage',
        ]}
        rows={[
          [
            'cookie_acceptance',
            'Permanent',
            '2 Wochen',
            'Porsche Digital',
            'YuuYoga Webseite – Information, ob Cookies akzeptiert werden',
            'Art. 6 Abs. 1 f) DSGVO',
          ],
          [
            '_ga',
            'Permanent',
            '2 Jahre',
            'Google Analytics',
            'Google Analytics Cookie zur Nutzeridentifikation; siehe auch https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage',
            'Art. 6 Abs. 1 a) DSGVO',
          ],
          [
            '_gid',
            'Permanent',
            '1 Tag',
            'Google Analytics',
            'Google Analytics Cookie zur Nutzeridentifikation.',
            'Art. 6 Abs. 1 a) DSGVO',
          ],
          [
            '_gat_gtag',
            'Permanent',
            '1 Min',
            'Google Analytics',
            'Google Analytics Cookie zur Drosselung der Requestanzahl.',
            'Art. 6 Abs. 1 a) DSGVO',
          ],
          [
            '_fbp',
            'Permanent',
            '90 Tage',
            'Facebook',
            'Über den Facebook Pixel können wir statistisch nachvollziehen, welche Werbung zu einem Besuch unserer Website bzw. App geführt hat. Mehr Details weiter oben unter Marketing.',
            'Art. 6 Abs. 1 a) DSGVO',
          ],
          [
            'fs_uid',
            'Permanent',
            '1 Jahr',
            'FullStory',
            'FullStory Cookie zur Nutzeridentifikation.',
            'Art. 6 Abs. 1 a) DSGVO',
          ],
        ]}
      />

      <Text
        sx={{ variant: 'links.default' }}
        onClick={() => document.dispatchEvent(new Event('cookie_consent_show'))}
      >
        Einstellungen zu Cookies anzeigen
      </Text>
    </DefaultPageLayout>
  );
}

export default CookiePolicyPage;
