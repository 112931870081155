/** @jsxImportSource theme-ui */

import { Box, Button, Image } from 'theme-ui';
import { Link } from 'gatsby';
import React from 'react';
import Container from '../Container/Container';
import PoseComparisonSessionItem from '../PoseComparisonSessionItem/PoseComparisonSessionItem';
import PoseComparisonEmpty from '../../../assets/images/poseComparisonEmpty.png';

const PoseComparisonCarouselEmpty: React.FC = () => (
  <Box
    sx={{ position: 'relative', paddingBottom: '2rem', overflowX: 'hidden' }}
  >
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: '48px',
        backgroundColor: 'white',
        zIndex: 0,
      }}
    />
    <Box
      sx={{
        position: 'absolute',
        top: '48px',
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'grey1',
        zIndex: -1,
      }}
    />

    <Container
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-start',
        minWidth: '530px',
      }}
    >
      <Box sx={{ height: '168px' }}>
        <Image src={PoseComparisonEmpty} sx={{ width: '250px' }} />

        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: '2rem',
          }}
        >
          <Link
            to="/app/pose-comparison/example"
            sx={{ textDecoration: 'none' }}
          >
            <Button sx={{ width: '230px !important' }} variant="primary">
              Beispiel ansehen
            </Button>
          </Link>
        </Box>
      </Box>

      <Box sx={{ width: '232px', marginLeft: '3rem' }}>
        <PoseComparisonSessionItem
          sessionId="example"
          key="example"
          poseImage="https://yougacdkstackprod-imagebucket6194e37c-op33z8eeb47w.s3.eu-central-1.amazonaws.com/pose-comparison-example/reference-pics/189.jpg"
          userImage="https://yougacdkstackprod-imagebucket6194e37c-op33z8eeb47w.s3.eu-central-1.amazonaws.com/pose-comparison-example/user-pics/189.png"
          sessionName="Pose Comparison Beispiel"
          target="/app/pose-comparison/example"
        />
      </Box>
    </Container>
  </Box>
);

export default PoseComparisonCarouselEmpty;
