import { Router } from '@reach/router';
import { Box } from 'theme-ui';

import ConnectSection from '../components/03_organisms/ConnectSection/ConnectSection';
import TrainingSessionPage from '../components/03_organisms/TrainingSessionSection/TrainingSessionPage';
import Page from '../components/04_templates/Page';

/**
 * Process description.
 *
 *  Desktop:
 *  Obtain QR Code with connect-session id
 *
 *  Mobile:
 *  Scan code and provide session id data
 *  - Auth Token
 *  - Video to Play
 *
 *  Server:
 *  After phone provides access data, generate an opentok
 *  streaming session
 *
 *  Desktop:
 *  Identify all data and redirect to the connect-training-session page
 *
 *  Mobile:
 *  Identify generated session and redirect to the (modified) camera page
 *
 *  Desktop:
 *  On session end, notify server and jump to namaste
 *
 *  Mobile:
 *  Identify session end and show namaste
 */

function ConnectPage() {
  return (
    <Page
      title="Yuu Yoga - Connect"
      theme="dark-transparent"
      authRequired={false}
    >
      <Box sx={{ minHeight: 'calc(100vh - 155px)' }}>
        <Router basepath="/connect">
          <ConnectSection path="/" />
          <TrainingSessionPage passive path="/:connectId" />
        </Router>
      </Box>
    </Page>
  );
}

export default ConnectPage;
