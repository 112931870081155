import React, { useEffect, useState } from 'react';
import { Box, Image } from 'theme-ui';

import Header from '../../02_molecules/Header/Header';
import Container from '../../01_atoms/Container/Container';
import LoadingIllustration from '../../../assets/illustrationLoading.svg';

import Start from './Start';
import Enter from './Enter';
import Success from './Success';

const UnsupportedOsGate: React.FC<unknown> = () => {
  const [step, setStep] = useState<'start' | 'enter' | 'success'>('start');

  useEffect(() => {
    // 1vh is by default the whole viewport, however sometimes the viewport is overlapped by browser-bars.
    // for this case, we need to know the visible height when loading the page, to keep everything really full-screen
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);

  return (
    <>
      <Header showButton={null} theme="light" />

      <Box
        sx={{
          position: 'relative',
          pb: `${(173 / 376) * 100}%`,
          minHeight: 'calc((var(--vh, 1vh) * 100) - 3rem)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '@media (orientation: portrait)': {
            minHeight: 'calc((var(--vh, 1vh) * 100) - 3.75rem)',
          },
          '@media (orientation: portrait) and (min-width: 48em)': {
            minHeight: 'calc((var(--vh, 1vh) * 100) - 5rem)',
          },
          '@media (orientation: landscape) and (min-height: 48em)': {
            minHeight: 'calc((var(--vh, 1vh) * 100) - 3.75rem)',
          },
          '@media (orientation: landscape) and (min-height: 62em)': {
            minHeight: 'calc((var(--vh, 1vh) * 100) - 5rem)',
          },
        }}
      >
        <Container sx={{ py: 5, textAlign: 'center' }}>
          {step === 'start' && (
            <Start
              onContinue={(): void => {
                setStep('enter');
              }}
            />
          )}

          {step === 'enter' && (
            <Enter
              onContinue={(): void => {
                setStep('success');
              }}
            />
          )}

          {step === 'success' && <Success />}
        </Container>

        <Image
          src={LoadingIllustration}
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
          }}
        />
      </Box>
    </>
  );
};

export default UnsupportedOsGate;
