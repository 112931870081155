import * as React from 'react';
import { navigate } from '@reach/router';
import { Image, Box } from 'theme-ui';

import type { TrainingSession } from '@youga/youga-interfaces';

import Button from '../../01_atoms/Button/Button';
import { useInterval } from 'react-use';

interface Props {
  trainingSession: TrainingSession;
}

function SessionImages({ trainingSession }: Props) {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  useInterval(() => {
    setCurrentIndex((prev) =>
      prev + 1 === Math.min(trainingSession.images.length, 10) ? 0 : prev + 1,
    );
  }, 500);

  return (
    <Box
      sx={{
        position: 'relative',
        width: '556px',
        maxWidth: '100%',
        height: '370px',
        mb: '5rem',
        display: 'flex',
        margin: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
      }}
    >
      {trainingSession.images.map((img, index) => {
        return (
          <Image
            className="fs-exclude"
            data-hj-suppress=""
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: 'center center',
              zIndex: currentIndex === index ? 1 : 0,
            }}
            src={img.url}
          />
        );
      })}
      <Button
        variant="primary"
        sx={{
          position: 'absolute',
          zIndex: 4,
          mb: 4,
          bottom: 3,
          left: '50%',
          transform: 'translate(-50%, 0)',
        }}
        onClick={(): void => {
          navigate(`/app/pose-comparison/${trainingSession.id}`, {
            replace: true,
          });
        }}
      >
        zur Pose Comparison
      </Button>
    </Box>
  );
}

export default SessionImages;
