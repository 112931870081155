/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { CookieConsentBanner } from '@porsche-digital/cookie-consent-basic-react';
import * as Sentry from '@sentry/browser';
import * as FullStory from '@fullstory/browser';
import { Auth } from 'aws-amplify';

let smoothscroll = null;

try {
  // eslint-disable-next-line
  smoothscroll = require('smoothscroll-polyfill');

  // installs polyfill for smooth-scroll on iOS
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  smoothscroll.polyfill();
} catch (e) {
  // eslint-disable-next-line no-console
  console.log('Workaround for gatsby:build (window is not defined)');
}

const initSentry = (): void => {
  Sentry.init({
    dsn:
      process.env.GATSBY_BUILD_ENV === 'prod'
        ? 'https://7bac40d4813b45bab2943072f44dc26e@o399359.ingest.sentry.io/5256526'
        : undefined,
    environment: process.env.GATSBY_BUILD_ENV,
  });
};

const initFullStory = async (): Promise<void> => {
  FullStory.init({
    orgId: '11VJS7',
    devMode: process.env.GATSBY_BUILD_ENV !== 'prod',
  });
  FullStory.consent(true);

  try {
    const user = await Auth.currentAuthenticatedUser();
    if (user?.attributes?.sub) {
      FullStory.identify(user?.attributes.sub, {});

      FullStory.setUserVars({
        userId_str: user.attributes.sub,
        paymentStatus_bool: 'unknown',
        environment_str: process.env.GATSBY_BUILD_ENV,
      });
    }
  } catch (error) {
    // TODO
  }
};

const initGA = (): void => {
  if (typeof document === `undefined`) {
    return;
  }
  const id = 'gaScript';
  if (document.getElementById(id) != null) {
    return;
  }
  const gaScript = document.createElement('script');
  gaScript.id = id;
  gaScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', 'UA-130033516-21');
      gtag('config', 'AW-644934357');
    `;
  document.head.appendChild(gaScript);
};

const initFBPixel = (): void => {
  if (typeof document === `undefined`) {
    return;
  }

  const id = 'fbqScript';
  if (document.getElementById(id) != null) {
    return;
  }

  const fbqScript = document.createElement('script');
  fbqScript.id = id;
  fbqScript.innerHTML = `
      (function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );
      if (window.fbq) {
        window.fbq("init", "329001935046783");
        window.fbq("track", "PageView");
      }
    `;
  document.head.appendChild(fbqScript);
};

export interface LayoutProps {
  children: React.ReactNode;
  disableCookieBanner?: boolean;
  theme?: 'dark-solid' | 'dark-transparent' | 'light';
}

type CookieCategory = 'technically_required' | 'analytics' | 'marketing';

const isBrowser = typeof window !== 'undefined';

const Layout = ({
  children,
  disableCookieBanner = false,
  theme = 'light',
}: LayoutProps): React.ReactElement => {
  const [acceptedCategories, setAcceptedCategories] = useState<
    CookieCategory[]
  >([]);

  const cookieConsentHandler = async (event: {
    acceptedCategories: string[];
  }): Promise<void> => {
    const categories = event.acceptedCategories.map((s) => s as CookieCategory);
    setAcceptedCategories(categories);
    if (categories.includes('analytics')) {
      initSentry();
      initGA();
      await initFullStory();
    }
    if (categories.includes('marketing')) {
      initFBPixel();
    }
  };

  const isLocalhost = isBrowser && window.location.href.includes('localhost');
  const cookieDomain = isLocalhost ? 'localhost' : '.yuuxyoga.de';

  return (
    <>
      <Helmet bodyAttributes={{ class: theme }}>
        <link
          rel="preload"
          href="/api/v1/instructors"
          as="fetch"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/api/v1/video-sessions"
          as="fetch"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/api/v1/courses"
          as="fetch"
          crossOrigin="anonymous"
        />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, viewport-fit=cover"
        />
        {acceptedCategories.includes('analytics') && (
          // <!-- Global site tag (gtag.js) - Google Analytics -->
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=UA-130033516-21"
          />
        )}
        {acceptedCategories.includes('marketing') && (
          // /* <!-- Facebook Pixel Code --> */
          <noscript>
            {`
            <img
              height="1"
              width="1"
              alt=""
              src="https://www.facebook.com/tr?id=329001935046783&ev=PageView&noscript=1"
            />
            `}
          </noscript>
          // /* <!-- End Facebook Pixel Code --> */
        )}
      </Helmet>

      {!disableCookieBanner && (
        <CookieConsentBanner
          headline="Cookies - Hilf uns besser zu werden"
          mode="offensive"
          handlePreferencesRestored={cookieConsentHandler}
          handlePreferencesUpdated={cookieConsentHandler}
          linkToCookiePolicy="/cookie-policy/"
          cookieDomain={cookieDomain}
        />
      )}

      {children}
    </>
  );
};

export default Layout;
