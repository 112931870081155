/** @jsxImportSource theme-ui */

import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'gatsby';

// eslint-disable-next-line import/no-extraneous-dependencies
import { RouteComponentProps } from '@reach/router';
import { Box, Image, Grid } from 'theme-ui';
import Text from '../../01_atoms/Text/Text';
import Container from '../../01_atoms/Container/Container';
import SmartMirrorInfoBox from '../../01_atoms/SmartMirrorInfoBox/SmartMirrorInfoBox';
import PoseComparisonInfoBox from '../../01_atoms/PoseComparisonInfoBox/PoseComparisonInfoBox';
import VideoOverlay from '../../01_atoms/VideoOverlay/VideoOverlay';
import Header from '../../02_molecules/Header/Header';
import useTracking from '../../../hooks/useTracking';
import ExplainerVideoPreview from '../../../assets/explainerVideoPreview.png';
import BtnPlay from '../../../assets/btnPlay.inline.svg';
import Accordion from '../../01_atoms/Accordion/Accordion';

const ExplainerSection: React.FC<RouteComponentProps> = () => {
  const [videoVisible, setVideoVisible] = useState(false);
  const { track } = useTracking();
  const trackExplainerVideo = useRef(false);

  // 1.- Track HomeSection
  useEffect(() => {
    track('how-it-works-section');
  }, [track]);

  // 2. Track Explainer Video Opened
  useEffect(() => {
    if (videoVisible && !trackExplainerVideo.current) {
      trackExplainerVideo.current = true;
      track('how-it-works-explainer-video-opened');
    }
  }, [track, videoVisible]);

  return (
    <React.Fragment>
      <VideoOverlay
        closeOnFinish
        videoUrl="https://yougacdkstackprod-imagebucket6194e37c-op33z8eeb47w.s3.eu-central-1.amazonaws.com/explainer/yuuxyoga-explainer-video-old.mp4"
        visible={videoVisible}
        videoMaxWidth={1920}
        onPlay={(): void => {
          track('how-it-works-explainer-video-play');
        }}
        onFinish={(): void => {
          track('how-it-works-explainer-video-finished');
        }}
        onClose={(): void => {
          setVideoVisible(false);
        }}
      />

      <Header showButton="back" />
      <Container sx={{ transition: 'filter ease-in 0.3s' }}>
        <Text variant="h2" sx={{ fontSize: [2, 4], mb: [5, 7] }} highlighted>
          Visuelles Feedback – so funktioniert&apos;s
        </Text>
      </Container>

      <Box sx={{ position: 'relative', pb: 9 }}>
        <Box
          sx={{
            position: 'absolute',
            top: ['100px', '200px', '300px', '400px'],
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: '-1',
            backgroundColor: 'grey1',
          }}
        />

        <Container>
          <Box
            sx={{
              position: 'relative',
              width: '100%',
            }}
          >
            <Box
              sx={{
                position: 'relative',
                cursor: 'pointer',
                paddingTop: '55.78%',
                overflow: 'hidden',
              }}
              onClick={(): void => {
                setVideoVisible(true);
              }}
            >
              <Image
                src={ExplainerVideoPreview}
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  borderRadius: '8px',
                  boxShadow: '0px 10px 16px 0px rgba(0, 0, 0, 0.1)',
                }}
              />
              <Box
                sx={{
                  display: ['none', 'block'],
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '254px',
                  borderRadius: '8px 8px 0 0',
                  background:
                    'linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);',
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <BtnPlay
                  sx={{ width: [null, '5rem'], height: [null, '5rem'] }}
                  className="assets-btn-play"
                />
              </Box>
            </Box>

            <Text
              variant="h1"
              sx={{
                fontSize: [1, 4, 5],
                color: 'white',
                position: 'absolute',
                top: [0, null, 8, 14],
                left: '50%',
                transform: 'translateX(-50%)',
                width: '100%',
                maxWidth: ['230px', '480px', '620px'],
                m: 0,
                mt: [4, null, 0],
                mb: [16, null, 0],
                textAlign: 'center',
                textTransform: 'none',
                '@media (max-width: 374px)': {
                  mt: 3,
                  fontSize: 0,
                  maxWidth: '210px',
                },
              }}
            >
              Alle Vorteile von Yuu Yoga in 60 Sekunden.
            </Text>
          </Box>

          <Grid columns={[1, null, 2]} gap="2.5rem" sx={{ mt: 8 }}>
            <PoseComparisonInfoBox />
            <SmartMirrorInfoBox />
          </Grid>
        </Container>
      </Box>

      <Box id="faq" sx={{ mt: '20px' }} />
      {/* extremely high margin-bottom because the footer has a negative margin */}
      <Container sx={{ mb: ['15rem', 25], mt: 13 }}>
        <Box>
          <Text variant="h2">Noch Fragen? Wir helfen dir gerne weiter.</Text>
        </Box>

        <Accordion
          elements={[
            {
              title:
                'Warum Yuu Yoga? Welche Vorteile bieten „Pose Comparison“ und „Smart Mirror“?',
              content: (
                <React.Fragment>
                  <p sx={{ m: 0, pt: 5, pb: 0 }}>
                    Yuu Yoga ist Yoga zu Hause weitergedacht. Im Mittelpunkt
                    steht deine persönliche Yoga-Journey. Du möchtest zu Hause
                    Yoga machen, aber bist dir unsicher, ob Du die Übungen
                    wirklich korrekt ausführst und machst deswegen nur langsam
                    Fortschritte? Yuu Yoga hilft dir mit visuellem Feedback
                    diese Probleme zu vermeiden.{' '}
                  </p>

                  <p sx={{ m: 0, pt: 3 }}>
                    Mit dem SmartMirror kannst Du direkt in der Session deine
                    Pose, mit der der Lehrerin vergleichen und diese selbständig
                    verbessern. Alles was Du dazu benötigst ist Dein Laptop und
                    ein Smartphone.
                  </p>

                  <p sx={{ m: 0, pt: 3 }}>
                    Durch die Pose Comparison geben wir Dir nach jeder Session
                    Feedback zu einzelnen Posen und einen direkten Vergleich zu
                    Deiner Yogalehrerin. Das hilft Dir Dich kontinuierlich zu
                    verbessern.
                  </p>
                </React.Fragment>
              ),
            },
            {
              title:
                'Welche verschiedenen Kurse und Sessions bietet Yuu Yoga an?',
              content: (
                <React.Fragment>
                  <p sx={{ m: 0, pt: 5, pb: 0 }}>
                    Unsere YogalehrerInnen helfen dir angepasst an Dein
                    persönliches Level verschiedene Yoga-Styles auszuprobieren.
                    Von sanftem Hatha-Yoga für mehr Flexibilität, über
                    sportlich-fordernde Vinyasa-Flows bis hin zu ruhigen
                    Yin-Yoga-Einheiten ist alles dabei. Möchtest Du Deine
                    Routine selber planen oder einfach entspannt eine einzelne
                    Session absolvieren, kannst aus einer großen Anzahl Sessions
                    in verschiedenen Längen die für dich passende wählen.{' '}
                  </p>

                  <p sx={{ m: 0, pt: 3 }}>
                    Sollte die richtige Session für dich nicht dabei sein,
                    schreib uns gerne unter hello@yuuxyoga.de und wir schauen,
                    ob wir dazu noch mehr ins Programm aufnehmen können.
                  </p>
                </React.Fragment>
              ),
            },
            {
              title: 'Von wem werden die Sessions angeleitet?',
              content: (
                <React.Fragment>
                  <p sx={{ m: 0, pt: 5, pb: 0 }}>
                    Alle Yuu Yoga Kurse werden von natürlich zertifizierten
                    Yogalehrerinnen angeleitet. Am Besten stellen sie sich Dir
                    selber vor:{' '}
                  </p>
                  <p sx={{ m: 0, pt: 3 }}>
                    <b>Lisa</b>
                    <br />
                    Schön Dich kennenzulernen – Ich bin Lisa, zertifizierte
                    Intensiv-Yogalehrerin und freue mich sehr mit Dir zusammen
                    Yoga zu machen. In meinen Yoga-Sessions biete ich Dir die
                    Chance, Yoga als persönliche Energiequelle zu nutzen, bei
                    der Du neue Kraft und Inspiration schöpfen kannst. Dabei
                    kannst Du über Dich selbst hinauswachsen und Dein
                    Selbstbewusstsein stärken. Du bist neugierig geworden? Dann
                    schnapp Dir Deine Yogamatte und starte mit mir Deine
                    Session.
                  </p>
                  <p sx={{ m: 0, pt: 3 }}>
                    <b>Ricarda</b>
                    <br />
                    Hey Yogi, ich bin Ricarda, Yogalehrerin aus Berlin mit dem
                    Schwerpunkt auf Hatha-Vinyasa und Yin Yoga. Nachdem ich
                    selbst schon viele Jahre begeistert Yoga praktiziert habe,
                    habe ich schließlich meine Ausbildung im schönen Mexiko
                    gemacht, im Anschluss gleich angefangen viel zu unterrichten
                    und bin immer neugierig auf mehr Yoga Know-How geblieben.
                    Mir ist sehr wichtig, dass wir uns selbst nicht zu ernst
                    nehmen und gerne auch mal mit Humor durch die Asanas fließen
                    – uns jedoch während &amp; im Anschluss noch wohler, stärker
                    und ausgeglichener fühlen.
                  </p>
                  <p sx={{ m: 0, pt: 3 }}>
                    <b>Juli</b>
                    <br />
                    Namasté, wie schön dass Du da bist! Ich bin Juli,
                    Diplom-Psychologin und zertifizierte Yoga- und
                    Meditationslehrerin. Wenn Du Dir mehr Entspannung,
                    Gelassenheit und Selbstliebe für Dein Leben wünschst und
                    nebenbei auch noch beweglicher werden möchtest, bist Du hier
                    genau richtig. Du findest bei mir Videos zwischen 5 und 30
                    Minuten, vom sanften Hatha-Yoga für mehr Flexibilität, über
                    sportlich-fordernde Vinyasa-Flows bis hin zu ruhigen
                    Yin-Yoga-Einheiten ist alles dabei. Mach’s Dir auf Deiner
                    Yogamatte bequem und lass uns gleich loslegen, ich freu’
                    mich auf Dich!
                  </p>
                  <p sx={{ m: 0, pt: 3 }}>
                    <b>Cora</b>
                    <br />
                    Ich bin zertifizierte Yogalehrerin und Beraterin für
                    holistische Gesundheit &amp; ich lebe mit meinen zwei Hunden
                    &amp; meinem Freund in Sellin an der Ostsee. Schön dass Du
                    da bist und vielleicht ein bisschen Yoga mit mir machst...
                    Yoga ist für mich die Körper/Geist Verbindung - Der äußere
                    Weg der ins Innere führt, denn die Reise zu Dir selbst ist
                    die Spannendste, die Du je machen wirst. Hier darfst Du ganz
                    Du selbst sein. Du darfst ankommen, zur Ruhe kommen, Deine
                    Gefühle rauslassen, über Dich selbst lachen, Dich
                    herausfordern, Dich annehmen. Ich freue mich, wenn ich Dich
                    auf Deinem Weg ein Stück begleiten darf…
                  </p>
                </React.Fragment>
              ),
            },
            {
              title: 'Muss ich bereits Yoga-Erfahrung für Yuu Yoga mitbringen?',
              content: (
                <React.Fragment>
                  <p sx={{ m: 0, pt: 5, pb: 0 }}>
                    Egal ob Neuling oder erfahrene Yogini, Yuu Yoga bietet Dir
                    auf Deine Bedürfnisse angepasste Kurse. Unsere Yoga
                    LehrerInnen begleiten Dich auf Deiner Journey und helfen Dir
                    über Dein aktuelles Level hinauszuwachsen.{' '}
                  </p>
                </React.Fragment>
              ),
            },
            {
              title: 'Mit welchen Geräten kann ich Yuu Yoga nutzen?',
              content: (
                <React.Fragment>
                  <p sx={{ m: 0, pt: 5, pb: 0 }}>
                    Verbindest du Laptop und Smartphone zu Beginn der Session,
                    hilft dir Yuu Yoga mit visuellem Feedback noch mehr aus
                    Deiner Session zu machen. Du nutzst nämlich die Kamera
                    Deines Smartphones, um Dich auf dem großen Bildschirm
                    Seite-an-Seite mit der Yogalehrerin zu sehen.{' '}
                  </p>
                  <p sx={{ m: 0, pt: 3 }}>
                    Die Verbindung der beiden Geräte efolgt über das Scannen von
                    QR-Codes mit Deinem Smartphone. Die Möglichkeit und Anleiung
                    dazu bekommst du im Produkt, sobald du dir ein Video
                    herausgesucht hast.
                  </p>
                  <p sx={{ m: 0, pt: 3 }}>
                    Alternativ zum Laptop kannst du auch ein Tablet für den
                    größeren Screen benutzen, sofern der Bildschirm groß genug
                    ist.
                  </p>
                </React.Fragment>
              ),
            },
            {
              title:
                'Wann werden Bilder und Videos von mir gemacht und wer kann diese sehen?',
              content: (
                <React.Fragment>
                  <p sx={{ m: 0, pt: 5, pb: 0 }}>
                    Deine Daten gehören dir. Deswegen kann weder unser Team,
                    noch eine andere Person dir während Deiner Yoga Session
                    zuschauen.
                  </p>
                  <p sx={{ m: 0, pt: 3 }}>
                    Entscheidest du dich den Smart Mirror zu aktivieren, wird
                    Dein Live-Bild von Deinem Smartphone auf Dein Laptop oder
                    Tablet übertragen, jedoch nicht gespeichert.
                  </p>
                  <p sx={{ m: 0, pt: 3 }}>
                    Möchtest du zusätzlich die Pose Comparison nutzen, werden
                    während der Session Bilder Deiner einzelnen Posen
                    aufgenommen und dir am Ende angezeigt. Die Bilder aller
                    Deiner vergangenen Sessions findest du{' '}
                    <Link to="/app/pose-comparison">hier</Link>.
                    {/* Auch auf diese hast nur du alleine Zugriff UND KANNST SIE
                    JEDERZEIT LÖSCHEN. */}
                  </p>
                </React.Fragment>
              ),
            },
          ]}
        />
      </Container>
    </React.Fragment>
  );
};

export default ExplainerSection;
