import * as React from 'react';
import type { RouteComponentProps } from '@reach/router';
import { Box } from 'theme-ui';

import { useTrainingSession } from '@youga/youga-client-api';

import Header from '../../02_molecules/Header/Header';
import Container from '../../01_atoms/Container/Container';
import Text from '../../01_atoms/Text/Text';
import SessionImages from './SessionImages';
import FullscreenLottie from '../../01_atoms/FullscreenLottie/FullscreenLottie';
import confettiAnimation from '../../../lotties/confetti.json';
import NextSessionSuggestions from './NextSessionSuggestions';

export type Props = RouteComponentProps<{
  trainingSessionId: string;
}>;

function FinishSessionSection({ trainingSessionId }: Props) {
  const {
    data: trainingSession,
    isValidating,
    updateAnimationImage,
    patchTrainingSession,
  } = useTrainingSession(trainingSessionId);

  /**
   * [Mount]
   * Resets the scroll-position to top when rendering this page
   */
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isFinished = trainingSession?.finished === true;

  React.useEffect(() => {
    async function markTrainingSessionAsFinished() {
      if (isFinished === true || isValidating) {
        return;
      }

      try {
        await patchTrainingSession({
          finished: true,
        });
      } catch (error: unknown) {
        console.log('got an error while patching trainingsession', error);
      }
    }
    markTrainingSessionAsFinished();
  }, [isValidating, isFinished, patchTrainingSession]);

  const isLoaded = trainingSession != null;
  const animatedImage = trainingSession?.animatedImage;

  /**
   * [after loading training-session]
   * Sets the finished flag to the training-session
   */
  React.useEffect(() => {
    const updateFinishedFlag = async (): Promise<void> => {
      if (!isLoaded || isValidating) {
        return;
      }

      if (animatedImage != null) {
        return;
      }

      try {
        await updateAnimationImage();
      } catch (error: unknown) {
        console.log('got an error while requesting animated image', error);
      }
    };

    updateFinishedFlag();
  }, [isValidating, isLoaded, animatedImage, updateAnimationImage]);

  return (
    <>
      <Header showButton="back" theme="light" fullWidth />
      <Container>
        <Headline />

        {trainingSession ? (
          <Box
            sx={{
              margin: 'auto',
              width: '100%',
              maxWidth: '100%',
              mb: [6, 14],
            }}
          >
            <SessionImages trainingSession={trainingSession} />
          </Box>
        ) : null}

        {trainingSession != null ? (
          <NextSessionSuggestions trainingSession={trainingSession} />
        ) : null}
      </Container>

      <FullscreenLottie
        loop={false}
        autoplay
        lottieAnimation={confettiAnimation as JSONObject}
        preserveAspectRatio="xMaxYMid slice"
      />
    </>
  );
}

function Headline() {
  return (
    <>
      <Text
        variant="h2"
        sx={{
          fontSize: 4,
          mt: [3, 7],
          mb: 0,
          textAlign: 'center',
        }}
      >
        Namaste
      </Text>
      <Text
        sx={{
          fontSize: 4,
          fontFamily: 'heading',
          textAlign: 'center',
          mb: [5, 8],
        }}
      >
        Danke für die tolle Session!
      </Text>
    </>
  );
}

export default FinishSessionSection;
