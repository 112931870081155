import React, { useMemo } from 'react';
import { Box } from 'theme-ui';

import { useTrainingSessions, useVideoSessions } from '@youga/youga-client-api';

import Spinner from '../../01_atoms/Spinner/Spinner';
import Container from '../../01_atoms/Container/Container';
import PoseComparisonCarouselEmpty from '../../01_atoms/PoseComparisonCarouselEmpty/PoseComparisonCarouselEmpty';
import PoseComparisonSessionCarouselItem from '../PoseComparisonSessionCarouselItem/PoseComparisonSessionCarouselItem';
import Carousel from '../Carousel/Carousel';

interface PoseComparisonCarouselProps {}

const PoseComparisonCarousel: React.FC<PoseComparisonCarouselProps> = ({}) => {
  const { data: videoSessionsData } = useVideoSessions();
  const { data: trainingSessions } = useTrainingSessions();

  const sessionsWithImages = useMemo(
    () =>
      trainingSessions == null
        ? []
        : trainingSessions.filter((session) => session.images.length > 0),
    [trainingSessions],
  );

  if (!trainingSessions && !Array.isArray(trainingSessions)) {
    return (
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spinner />
      </Box>
    );
  }

  if (!sessionsWithImages || sessionsWithImages.length === 0) {
    return <PoseComparisonCarouselEmpty />;
  }

  return (
    <Box
      sx={{ position: 'relative', paddingBottom: '2rem', overflowX: 'hidden' }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '48px',
          backgroundColor: 'white',
          zIndex: 0,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: '48px',
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'grey1',
          zIndex: -1,
        }}
      />
      <Container>
        <Box
          sx={{
            position: 'relative',
            // We need to extend the width a bit, to allow the shadow to be visible
            // This space will be added afterwards as a padding-left at the items
            marginLeft: '-20px',
            marginRight: '-20px',
            width: 'calc(100% + 40px)',
          }}
        >
          <Carousel hideControlShadow fullHeight>
            {sessionsWithImages.map((session, index) => {
              const matchingVideoSession =
                videoSessionsData?.videoSessions[session.videoSessionId || ''];

              let poseIndex = 0;

              if (matchingVideoSession?.highlightPoseName) {
                poseIndex = Math.max(
                  0,
                  session.images.findIndex(
                    (image) =>
                      image.poseName === matchingVideoSession.highlightPoseName,
                  ),
                );
              }

              return (
                <PoseComparisonSessionCarouselItem
                  key={session.id}
                  sessionId={session.videoSessionId || ''}
                  poseImage={session.images[poseIndex].referenceUrl}
                  userImage={session.images[poseIndex].url}
                  sessionName={session.videoSessionName || 'Session'}
                  date={session.timestamp}
                  target={`/app/pose-comparison/${session.id}`}
                  isLastElement={sessionsWithImages.length - 1 === index}
                />
              );
            })}
          </Carousel>
        </Box>
      </Container>
    </Box>
  );
};

export default PoseComparisonCarousel;
