const log = (message: string, payload?: unknown): void => {
  const timestamp = new Date().toISOString();
  // eslint-disable-next-line no-console
  console.log(`${timestamp} - ${message}`);
  if (payload) {
    // eslint-disable-next-line no-console
    console.log(payload);
  }
};

export default log;
