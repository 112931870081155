import React from 'react';
import { navigate } from 'gatsby';

import Seo from '../global/Seo';

function IndexPage() {
  React.useEffect(() => {
    navigate(`/app${window?.location.search}`);
  }, []);

  return (
    <>
      <Seo title="Yuu Yoga" titleTemplate="We make yoga about you" />
    </>
  );
}

export default IndexPage;
