/** @jsxImportSource theme-ui */

import React, { useEffect, useState } from 'react';

import { Box, Image } from 'theme-ui';
import Text from '../../01_atoms/Text/Text';

export interface CountdownProps {
  audioEnabled: boolean;
  beepSound: HTMLAudioElement | null;
  gongSound: HTMLAudioElement | null;
  volume: number;
  showAnimation: boolean;
  takePhoto: () => string;
  onFinish: () => void;
}

const Countdown: React.FC<CountdownProps> = ({
  audioEnabled,
  beepSound,
  gongSound,
  volume,
  showAnimation,
  takePhoto,
  onFinish,
}: CountdownProps) => {
  const [timeLeft, setTimeLeft] = useState(4);
  const [takenPicture, setTakenPicture] = useState<string | null>(null);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    const decrease = (): void => {
      if (!beepSound || !gongSound) {
        return;
      }

      /* eslint-disable no-param-reassign */
      beepSound.volume = audioEnabled ? 0.1 * volume : 0;
      gongSound.volume = audioEnabled ? 0.1 * volume : 0;

      setTimeLeft((state) => {
        const newState = state < -2 ? state : state - 1;

        if (newState >= 1 && newState !== state) {
          beepSound.currentTime = 0;
          // Promise.resolve makes sure that we always have a catchable promise here
          Promise.resolve(beepSound.play()).catch(() => {
            // do nothing, we could not play the sounds
          });
        }

        if (newState === 0 && newState !== state) {
          gongSound.currentTime = 0;
          // Promise.resolve makes sure that we always have a catchable promise here
          Promise.resolve(gongSound.play()).catch(() => {
            // do nothing, we could not play the sounds
          });
        }

        return newState;
      });
      /* eslint-enable no-param-reassign */
    };

    if (typeof window !== 'undefined') {
      timer = setInterval(decrease, 1000);
    }

    decrease();

    return (): void => {
      clearInterval(timer);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (timeLeft === 0) {
      setTakenPicture(takePhoto());
    }

    if (timeLeft === -2) {
      onFinish();
    }
  }, [onFinish, takePhoto, timeLeft]);

  const FULL_DASH_ARRAY = 283;

  const calculateTimeFraction = (): number => {
    const rawTimeFraction = timeLeft / 3;
    return rawTimeFraction - (1 / 3) * (1 - rawTimeFraction);
  };

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          bottom: '10px',
          left: '10px',
          zIndex: 10,
        }}
        style={{
          opacity: timeLeft >= 0 ? 1 : 0,
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '75px',
            height: '75px',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            borderRadius: '50%',
            transition: 'opacity 0.1s ease-in',
          }}
        >
          <svg
            sx={{ transform: 'scaleX(-1)' }}
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g sx={{ fill: 'none', stroke: 'none' }}>
              <circle
                sx={{
                  strokeWidth: '7px',
                  stroke: 'success',
                }}
                cx="50"
                cy="50"
                r="45"
              />
              <path
                sx={{
                  strokeWidth: '7px',
                  strokeLinecap: 'round',
                  transform: 'rotate(90deg)',
                  transformOrigin: 'center',
                  transition: '1s linear all',
                  stroke: 'white',
                }}
                id="base-timer-path-remaining"
                strokeDasharray={`${(
                  calculateTimeFraction() * FULL_DASH_ARRAY
                ).toFixed(0)} 283`}
                className="base-timer__path-remaining"
                d="
              M 50, 50
              m -45, 0
              a 45,45 0 1,0 90,0
              a 45,45 0 1,0 -90,0
            "
              />
            </g>
          </svg>
          <Text
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              fontSize: 2,
              color: 'black',
              fontFamily: 'heading',
            }}
          >
            {timeLeft}
          </Text>
        </Box>
      </Box>
      {takenPicture && (
        <>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              zIndex: 20,
              transition: 'opacity ease-out 0.3s, transform ease-out 0.5s',
            }}
            style={{
              opacity: timeLeft === -1 ? 1 : 0.5,
              transform: timeLeft <= -1 ? 'translateX(100%) scale(0.8)' : '',
            }}
          >
            {showAnimation && (
              <Image
                className="fs-exclude"
                data-hj-suppress=""
                src={takenPicture}
                sx={{
                  maxHeight: '100%',
                  width: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center center',
                }}
              />
            )}
          </Box>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 30,
              backgroundColor: 'white',
              transition: 'opacity 0.1s ease-out',
            }}
            style={{
              opacity: timeLeft === 0 ? 1 : 0,
            }}
          />
        </>
      )}
    </>
  );
};

export default Countdown;
