import DefaultPageLayout from '../components/04_templates/DefaultPageLayout';

function PrivacyPolicyPage() {
  return (
    <DefaultPageLayout title="Datenschutzerklärung">
      <p>
        Wir, die Initium GmbH (nachfolgend „wir“ oder „Initium“), freuen uns
        über Ihr Interesse an unserem Online-Angebot Yuu Yoga, das wir Ihnen per
        App und ergänzend per Website anbieten (nachfolgend das
        „Online-Angebot“). Wir nehmen den Schutz Ihrer personenbezogenen Daten
        sehr ernst. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt
        ausschließlich im Rahmen der gesetzlichen Bestimmungen des
        Datenschutzrechts, insbesondere der Datenschutzgrundverordnung
        (nachfolgend „DSGVO“). Mit dieser Datenschutzerklärung informieren wir
        Sie über die Verarbeitung Ihrer personenbezogenen Daten und über Ihre
        Rechte als betroffene Person im Rahmen des Online-Angebots.{' '}
      </p>

      <p>
        Sofern wir von externen Social-Media-Profilen auf diese
        Datenschutzerklärung verweisen, gelten die folgenden Erläute-rungen nur
        insoweit, als die Verarbeitung in unserem Verantwortungsbereich erfolgt
        und soweit keine spezielleren und deshalb vorrangigen Hinweise zum
        Datenschutz im Rahmen solcher Social-Media-Profile zur Verfügung
        gestellt werden.
      </p>

      <h4>1. Verantwortlicher und Datenschutzbeauftragter</h4>

      <p>
        Verantwortlich für die Datenverarbeitung im Sinne der Datenschutzgesetze
        ist die:
      </p>

      <p>
        Initium GmbH
        <br />
        Stralauer Allee 12
        <br />
        10245 Berlin
        <br />
        Deutschland
        <br />
        E-Mail: <a href="mailto:info@yuuxyoga.de">info@yuuxyoga.de</a>
      </p>

      <p>
        Sollten Sie Fragen oder Anregungen zum Datenschutz haben, können Sie
        sich gerne an uns wenden. Unseren Datenschutzbeauftragten erreichen Sie
        wie folgt:
      </p>

      <p>
        Initium GmbH
        <br />
        Beauftragter für Datenschutz
        <br />
        Stralauer Allee 12
        <br />
        10245 Berlin
        <br />
        Deutschland
        <br />
        E-Mail:{' '}
        <a href="mailto:datenschutzbeauftragter@initium.gmbh">
          datenschutzbeauftragter@initium.gmbh
        </a>
      </p>

      <h4>2. Gegenstand des Datenschutzes</h4>

      <p>
        Gegenstand des Datenschutzes ist der Schutz personenbezogener Daten.
        Diese sind alle Informationen, die sich auf eine identifizierte oder
        identifizierbare natürliche Person (sog. betroffene Person) beziehen.
        Hierunter fallen Angaben wie z.B. Name, Post-Adresse, E-Mail-Adresse
        oder Telefonnummer, aber auch sonstige Informationen, die im Rahmen der
        Nutzung des Online-Angebots entstehen, insbesondere Angaben über Beginn,
        Ende und Umfang der Nutzung sowie die Übermittlung Ihrer IP-Adresse.
      </p>

      <h4>3. Zwecke und Rechtsgrundlagen der Datenverarbeitung</h4>

      <p>
        Nachfolgend erhalten Sie einen Überblick über Zwecke und
        Rechtsgrundlagen der Datenverarbeitung im Rahmen des Online-Angebots.
        Wir verarbeiten personenbezogene Daten jedenfalls entsprechend der
        rechtlichen Vorgaben, auch wenn im Einzelfall eine andere
        Rechtsgrundlage einschlägig sein sollte als im Folgenden angegeben.
      </p>

      <p>
        Die Bereitstellung personenbezogener Daten durch Sie kann dabei
        gesetzlich oder vertraglich vorgeschrieben bzw. für einen
        Vertragsabschluss erforderlich sein. Wir weisen Sie gesondert darauf
        hin, wenn Sie verpflichtet sein sollten, personenbezogene Daten
        bereitzustellen und welche möglichen Folgen die Nichtbereitstellung dann
        hätte (z.B. ein Verlust von Ansprüchen oder unser Hinweis, ohne
        Bereitstellung bestimmter Angaben die angeforderte Leistung nicht zu
        erbringen). Die Nutzung des Online-Angebots ist grundsätzlich ohne
        Registrierung möglich. Die Nutzung einzelner Dienste und Funktionen kann
        eine vorherige Registrierung erfordern. Auch wenn Sie unser
        Online-Angebot ohne Registrierung nutzen, können dennoch
        personenbezogene Daten verarbeitet werden.
      </p>

      <h4>3.1 Erfüllung vertraglicher und vorvertraglicher Pflichten</h4>

      <p>
        Wir verarbeiten Ihre personenbezogenen Daten, wenn dies für die
        Durchführung eines Vertrages, dessen Partei Sie sind, bzw. für die
        Durchführung vorvertraglicher Maßnahmen, die auf Ihre Anfrage hin
        erfolgen, erforderlich ist. Die Datenverarbeitung erfolgt auf Basis des
        Artikels 6 Absatz 1 Buchstabe b) DSGVO. Die Zwecke der Verarbeitung
        umfassen die Ermöglichung der Nutzung unserer spezifischen Produkte und
        Dienstleistungen im Rahmen des Online-Angebots. Bitte beachten Sie
        insofern auch die Beschreibungen dieser Produkte und Dienstleistungen im
        Rahmen des Online-Angebots. Dies sind im konkreten Fall insbesondere die
        folgenden Funktionen:{' '}
      </p>

      <p>
        <strong>- Registrierung für ein Konto</strong>
      </p>

      <p>
        Mit der Registrierung für ein Yuu Yoga-Konto mit Ihrer E-Mail-Adresse
        wählen Sie einen selbst vergebenen Benutzerna-men und ein Passwort und
        können über Ihre Kontoeinstellungen nach Wunsch weitere Angaben in Ihrem
        Konto hinzufü-gen. Über Ihr Konto können Sie dann die von Ihnen
        genutzten Funktionen und Inhalte verwalten (Überblick über absolvierte
        Trainings, etc.).
      </p>

      <p>
        Wir verwenden zur Registrierung und Anmeldung ein sogenanntes
        „Single-Sign-On-Verfahren“ unter Einbindung der Dienste dritter
        Anbieter. Mithilfe des Single-Sign-On-Verfahrens können Sie sich als
        eingeloggter Nutzer von Facebook (Verantwortlicher: Facebook Ireland
        Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland),
        Google (Verantwortlicher: Google Ireland Ltd., Gordon House, Barrow
        Street, Dublin 4, Irland) oder Apple (Verantwortlicher: Apple Inc.,
        Infinite Loop, Cupertino, CA 95014, USA) für ein Yuu Yoga-Konto
        registrieren und anmelden. Dadurch müssen Sie sich für Yuu Yoga kein
        neues Passwort merken. Alternativ können Sie sich über Ihre
        E-Mail-Adresse registrieren und anmel-den. Dafür benötigen Sie Ihre
        E-Mail-Adresse und ein selbstgewähltes Passwort.
      </p>

      <p>
        Sollten Sie sich für das Single-Sign-On-Verfahren entscheiden, werden
        Sie auf die Login-/Anmelde-Maske Ihres Accounts bei dem jeweiligen
        Anbieter weitergeleitet, über den das Single-Sign-On-Verfahren
        abgewickelt wird. Hier melden Sie sich mit Ihrem dortigen Benutzernamen
        und Passwort an. Wir erhalten von dem Anbieter die Nachricht, dass Sie
        sich erfolgreich angemeldet haben und die Registrierung bzw. Anmeldung
        wird abgeschlossen. Wir kommen bei diesem Prozess weder mit Ihrem
        Passwort noch mit Ihrem Benutzernamen beim Anbieter, über den das
        Single-Sign-On-Verfahren abgewickelt wird, in Berührung. Bei
        erfolgreicher Registrierung bzw. Anmeldung müssen Sie dem Anbieter
        bestätigen, dass wir auf Ihre Basis-Profildaten (siehe unten für den
        jeweiligen Anbieter) zugreifen dürfen. Dadurch müssen Sie bei Yuu Yoga
        Ihre Basis-Profildaten nicht nochmals pflegen und aktuell halten (wenn
        sich z.B. Ihre Adresse ändert). Sollten Sie sich entscheiden, die
        Verknüpfung ihres Yuu Yoga-Kontos mit dem Account bei dem Anbieter, über
        den das Single-Sign-On-Verfahren abgewickelt wird, aufzuheben, stehen
        Ihnen die entsprechenden Einstellungen bei diesem Anbieter zur
        Verfügung.{' '}
      </p>

      <p>
        Wenn Sie ein Yuu Yoga-Konto über Facebook erstellen oder sich über
        Facebook in ein Yuu Yoga-Konto einloggen, übermittelt Facebook bestimmte
        Angaben Ihres Facebook-Accounts an uns: Name, Foto-URL, User-ID, E-Mail.
        Weitere Informationen zu Facebook und den Privatsphäre-Einstellungen
        sind den Datenschutzhinweisen von Facebook unter
        <a href="https://www.facebook.com/policy.php">
          https://www.facebook.com/policy.php
        </a>{' '}
        zu entnehmen. Wenn Sie ein Yuu Yoga-Konto über Google erstellen oder
        sich über Google in ein Yuu Yoga-Konto einloggen, übermittelt Google
        bestimmte Angaben Ihres Google-Accounts an uns: Name, Foto-URL, User-ID,
        E-Mail. Weitere Informationen zu Google und den
        Privatsphäre-Einstellungen sind den Datenschutzhinweisen von Google
        unter
        <a href="https://policies.google.com/privacy">
          https://policies.google.com/privacy
        </a>{' '}
        zu entnehmen. Wenn Sie ein Yuu Yoga-Konto über Apple erstellen oder sich
        über Apple in ein Yuu Yoga-Konto einloggen, übermittelt Apple bestimmte
        Angaben Ihres Apple-Accounts an uns: Name, Foto-URL, User-ID, E-Mail.
        Weitere Informationen zu Apple und den Privatsphäre-Einstellungen sind
        den Datenschutzhinweisen von Apple unter
        <a href="https://www.apple.com/legal/privacy/de-ww/">
          https://www.apple.com/legal/privacy/de-ww/
        </a>{' '}
        zu entnehmen.
      </p>

      <p>
        Zur Durchführung der Authentifizierung verwenden wir die Dienste von
        Amazon Cognito (Anbieter: Amazon Web Services, Inc., P.O. Box 81226,
        Seattle, WA 98108-1226, USA). Weitere Informationen finden Sie unter{' '}
        <a href="https://docs.aws.amazon.com/de_de/cognito/latest/developerguide/cognito-user-pools-identity-federation.html">
          https://docs.aws.amazon.com/de_de/cognito/latest/developerguide/cognito-user-pools-identity-federation.html
        </a>
        . Hinweise zum Datenschutz bei Amazon Cognito finden Sie unter{' '}
        <a href="https://aws.amazon.com/de/privacy/">
          https://aws.amazon.com/de/privacy/
        </a>
        .
      </p>

      <p>
        Die erhobenen Daten verarbeiten wir auf Basis des Artikels 6 Absatz 1
        Buchstabe b) und f) DSGVO, um Sie mit Ihrem Konto anzule-gen und Sie bei
        jeder Anmeldung zu identifizieren. Wir verfolgen dabei – neben der
        Durchführung des von Ihnen gewünschten Vorgehens bzw. Verfahrens – das
        Interesse, den Prozess von Registrierung und Anmeldung effizient und
        komfortabel auszugestal-ten.
      </p>

      <p>
        <strong>- Nutzung der Funktionen</strong>
      </p>

      <p>
        YuuYoga bietet die Möglichkeit, auf eine Bibliothek von Videoinhalten
        wie Yoga-Workouts und Übungen zuzugreifen und während und nach einem
        durchgeführten Training personalisiertes Feedback über die Art der
        Ausführung zu erhalten. Das personalisierte Feedback zu Ihren
        Fortschritten basiert auf Videoaufzeichnungen des Trainings. Es gibt
        folgende Varianten der Videoaufzeichnung:
      </p>

      <p>
        - Smart Mirror: Sie können sich während des Trainings selbst von der
        gleichen Seitenperspektive wie den Trainer sehen. Es erfolgt
        grundsätzlich keine dauerhafte Aufzeichnung des Videostreams. Eine
        Speicherung einzelner Snapshots kann für die Bereitstellung der
        folgenden Funktionen erfolgen.
      </p>
      <p>
        - Pose Comparison: Nach dem Training können Sie für bestimmte
        Schlüsselposen im Training anhand von Snapshots Ihre Ausführung mit der
        des Trainers vergleichen. Die Snapshots werden dauerhaft aufgezeichnet
        und stehen Ihnen in Ihrem Konto zum Abruf zur Verfügung.
      </p>
      <p>
        - Pose Progress: Sie können die in der Pose Comparison für bestimmte
        Schlüsselposen gemachten Snapshots im Zeitverlauf ansehen, um Ihren
        Fortschritt sichtbar zu machen. Die Snapshots werden dauerhaft
        aufgezeichnet und stehen Ihnen in Ihrem Konto zum Abruf zur Verfügung.
      </p>

      <p>
        Soweit dauerhafte Videoaufzeichnungen im Rahmen von Snapshots erfolgen,
        werden diese grundsätzlich so lange ge-speichert, bis Ihr Konto gelöscht
        wird oder Sie einzelne Snapshots im Rahmen der Verwaltung Ihres Kontos
        löschen. Bitte stellen Sie sicher, dass keine weiteren Personen
        aufgezeichnet werden.
      </p>

      <p>
        Bitte beachten Sie die weiteren Informationen zur Beschreibung der
        Funktionen in den Nutzungsbedingungen. Soweit für die Nutzung der
        Funktionen ein kostenpflichtiges Abonnement erforderlich sein sollte,
        erhalten Sie weitere Informationen zur damit verbundenen
        Datenverarbeitung und zu den erforderlichen Angaben im Rahmen des
        Bestellprozesses.
      </p>

      <p>
        Die bei der Nutzung der Funktionen erhobenen Daten und insbesondere die
        Videoaufzeichnungen sowie Daten zur Verifi-kation und Abrechnung von
        Abonnements verarbeiten wir auf Basis des Artikels 6 Absatz 1 Buchstabe
        b) DSGVO, um Ihnen die von Ihnen ausgewählten Funktionen im Rahmen des
        Angebots zur Verfügung zu stellen.{' '}
      </p>

      <h4>3.2 Erfüllung rechtlicher Verflichtungen</h4>

      <p>
        Wir verarbeiten Ihre personenbezogenen Daten, um rechtliche
        Verpflichtungen einzuhalten, denen wir unterliegen. Die
        Datenverarbeitung erfolgt auf Basis des Artikels 6 Absatz 1 Buchstabe c)
        DSGVO. Die Verpflichtungen können sich z.B. aus dem Handels-, Steuer-,
        Geldwäsche-, Finanz- oder Strafrecht ergeben. Die Zwecke der
        Verarbei-tung ergeben sich aus der jeweiligen rechtlichen Verpflichtung;
        die Verarbeitung dient in der Regel dem Zweck, staatlichen Kontroll- und
        Auskunftspflichten nachzu-kommen.{' '}
      </p>

      <h4>3.3 Wahrung berechtigter Interessen</h4>

      <p>
        Wir verarbeiten Ihre personenbezogenen Daten darüber hinaus zur Wahrung
        berechtigter Interessen von uns oder Dritten, soweit nicht Ihre
        Interessen, die den Schutz Ihrer personenbezogener Daten erfordern,
        überwiegen. Die Datenverarbeitung erfolgt auf Basis des Artikels 6
        Absatz 1 Buchstabe f) DSGVO. Die Verarbeitung zur Wahrung berechtigter
        Interesse erfolgt zu folgenden Zwecken bzw. zur Wahrung folgender
        Interessen.
      </p>

      <p>
        - Weiterentwicklung von Produkten, Diensten, Dienstleistungen und
        Betreuungsangeboten sowie sonstige Maßnahmen zur Steuerung von
        Geschäftsvorfällen und -prozessen
      </p>
      <p>
        - Verbesserung der Produktqualität, Beseitigung von Fehlern und
        Störungen u.a. mithilfe der Analyse von Nutzungsdaten und
        Kundenrückmeldungen
      </p>
      <p>
        - Bearbeitung von außervertraglichen Interessenten- und Kundenanfragen
      </p>
      <p>
        - Sicherstellung rechtskonformen Handelns, Prävention von und Schutz vor
        Rechtsverstößen (insb. Straftaten), Geltendmachung von und Verteidigung
        gegen rechtliche Ansprüche
      </p>
      <p>
        - Gewährleistung von Verfügbarkeit, Betrieb und Sicherheit technischer
        Systeme sowie technisches Datenmanagement
      </p>

      <p>
        Wenn Sie das Online-Angebot aufrufen, werden Daten in Bezug auf Ihr
        Endgerät und Ihre Nutzung des Online-Angebots verarbeitet und in einer
        sog. Log-Datei gespeichert. Dies betrifft insbesondere technische Daten
        wie Datum und Uhrzeit des Zugriffs, Dauer des Besuchs, Art des
        Endgeräts, verwendetes Betriebssystem, genutzte Funktionen, Menge der
        gesen-deten Daten, IP-Adresse und Referrer-URL. Diese Daten verarbeiten
        wir zur Sicherstellung des technischen Betriebs sowie zur Ermittlung und
        Beseitigung von Störungen. Wir verfolgen dabei das Interesse, die
        technische Funktionsfähigkeit dauerhaft sicherzustellen. Wir verwenden
        diese Daten nicht zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen.{' '}
      </p>

      <h4>3.4 Einwilligung</h4>

      <p>
        Wir verarbeiten Ihre personenbezogenen Daten auf Grundlage
        entsprechender Einwilligung. Die Datenverarbeitung erfolgt auf Basis des
        Artikels 6 Absatz 1 Buchstabe a) DSGVO. Sollten Sie eine Einwilligung
        erteilen, ist diese stets zweckgebunden; die Zwecke der Verarbeitung
        ergeben sich jeweils aus dem Inhalt Ihrer Einwilligungserklärung. Eine
        erteilte Einwilligung können Sie jederzeit wider-rufen, ohne dass die
        Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten
        Verarbeitung hiervon berührt wird.
      </p>

      <p>
        Sie können sich bei der Registrierung oder im Rahmen der Verwaltung
        Ihres Kontos für unseren Newsletter mit Informationen über Yuu Yoga
        anmelden. Wir versenden Newsletter nur nachentsprechender Anmeldung,
        d.h. mit Ihrer Einwilligung auf Basis von Artikel 6 Absatz 1 Buchstabe
        a) DSGVO. Sofern im Rahmen einer Anmeldung zum Newsletter dessen Inhalte
        konkret umschrieben werden, sind diese für die Reichweite der
        Einwilligung maßgeblich. Im Übrigen enthält unser Newsletter
        Informationen zu den Funktionen und Aktionen im Zusammenhang mit Yuu
        Yoga. Sie können Ihre Einwilligung in den Empfang unseres Newsletters
        jederzeit widerrufen, indem Sie sich vom Newsletter abmelden. Einen
        Abmelde-Link zur Ausübung dieses Rechts finden Sie am Ende eines jeden
        Newsletters.{' '}
      </p>

      <p>
        Bei der Erhebung und Verwaltung Ihrer E-Mail-Adresse nutzen wir den
        Dienst MailChimp (Anbieter: Rocket Science Group, LLC, 675 Ponce De Leon
        Ave NE 5000, Atlanta, GA 30308, USA). Um unseren Newsletter für Sie
        möglichst interessant zu gestalten, wertet MailChimp in unserem Auftrag
        das Nutzungsverhalten aus, ohne Rückschlüsse auf Ihre Person bzw.
        Identität zu ziehen. Auf diese Weise können wir z.B. erfahren, wie viele
        unserer Leser unseren Newsletter geöffnet haben und welche Links
        besonders häufig aufgerufen wurden. Hierbei kommen marktübliche
        Technologien, z.B. Cookies oder in unserem Newsletter eingebaute
        Zählpixel zum Einsatz. Die Datenverarbeitung erfolgt dabei auf Grundlage
        unserer berechtigten Interessen im Sinne des Artikel 6 Absatz 1
        Buchstabe f) DSGVO, nämlich unserem Interesse an der Analyse und
        inhaltlichen Optimierung unseres Newsletters. Weitere Informationen zum
        Datenschutz bei MailChimp finden Sie unter
        <a href="https://mailchimp.com/legal/privacy/">
          https://mailchimp.com/legal/privacy/
        </a>
        .
      </p>

      <h4>3.5 Zweckänderung</h4>

      <p>
        Soweit wir Ihre personenbezogenen Daten jenseits einer entsprechenden
        Einwilligung oder einer zwingenden Rechts-grundlage zu einem anderen
        Zweck verarbeiten als zu demjenigen, zu dem die Daten erhoben wurden,
        berücksichtigen wir gemäß Artikel 6 Absatz 4 DSGVO die Vereinbarkeit des
        ursprünglichen und des nunmehr verfolgten Zwecks, die Art der
        personenbezogenen Daten, die möglichen Folgen der weiteren Verarbeitung
        für Sie und die Garantien zum Schutz der personenbezogenen Daten.{' '}
      </p>

      <h4>3.6 Profiling</h4>

      <p>
        Wir führen keine automatisierte Entscheidungsfindung bzw. kein Profiling
        gemäß Artikel 22 DSGVO durch. Eine Profilbildung er-folgt nur zur
        Wahrung unserer berechtigten Interessen wie oben beschrieben.
      </p>

      <h4>4. Zugriffsberechtigungen im Endgerät</h4>

      <p>
        Soweit Funktionen unseres Online-Angebots die Erteilung von
        Berechtigungen zum Zugriff auf Ihr Endgerät (z.B. Zugriff auf
        Standortdaten oder Fotos) erfordern, ist die Erteilung der
        Berechtigungen freiwillig. Sollten Sie jedoch die entsprechenden
        Funktionen nutzen wollen, ist die Ertei-lung der entsprechenden
        Berechtigungen erforderlich, da Sie andernfalls diese Funktionen nicht
        nutzen können. Die Berechtigungen bleiben so lange aktiv, wie Sie diese
        nicht in ihrem Endgerät durch die Deaktivierung der jeweiligen
        Einstellung wieder zurückgesetzt haben.{' '}
      </p>

      <p>
        Für die Nutzung der unter Ziffer 3.1 (Nutzung der Funktionen)
        erläuterten Funktionen ist die Verwendung der Kamera Ihres Endge-räts
        erforderlich. Sollten Sie die genannten Funktionen nutzen wollen, so ist
        die Erteilung der entsprechenden Berechtigung erfor-derlich, da Sie
        andernfalls diese Funktionen nicht nutzen können. Die grundsätzliche
        Berechtigung zur Verwendung der Kamera bei der Nutzung der oben
        erläuterten Funktionen bleibt so lange aktiv, wie Sie diese nicht in
        ihrem Endgerät durch die Deaktivierung der jeweiligen Einstellung wieder
        zurückgesetzt haben.{' '}
      </p>

      <h4>5. Cookies und vergleichbare Technologien</h4>

      <p>
        Wir verwenden im Rahmen des Online-Angebots Cookies und vergleichbare
        Technologien, die dazu dienen, mit Ihrem Endgerät zu kommunizieren und
        gespeicherte Informationen auszutauschen (nachfolgend insgesamt
        „Cookies“). Diese Cookies dienen in erster Linie dazu, die Funktionen
        des Online-Angebots nutzbar zu machen. Allgemeine Beispiele, in denen
        der Einsatz von Cookies in diesem Sinne technisch erforderlich ist, sind
        die Speicherung einer Sprachauswahl, von Login-Daten oder einer
        Einkaufs- bzw. Merkliste. Dementsprechend können technisch erforderliche
        Cookies von uns eingesetzt werden, um die unter Ziffer 3.1 beschriebenen
        Verarbeitungen zu ermöglichen und den ordnungsgemäßen und sicheren
        Betrieb des Online-Angebots zu gewährleisten. Die Datenverarbeitung
        erfolgt dann auf Basis des Artikels 6 Absatz 1 Buchstabe b) und f)
        DSGVO, da sie erforderlich ist, um die von Ihnen gewählten Funktionen
        umzusetzen bzw. um unser berechtigtes Interesse an der
        Funktionsfähigkeit des Online-Angebots zu wahren.{' '}
      </p>

      <p>
        Soweit wir darüber hinaus Cookies einsetzen sollten, um die Nutzung des
        Online-Angebots zu analysieren und zielgerichtet auf Ihre Interessen
        ausrichten zu können und ggf. auch, um Ihnen interessenbasierte Inhalte
        und Anzeigen bereitstellen zu können, erfolgt dies ausschließlich auf
        Basis Ihrer freiwilligen Einwilligung gemäß Artikel 6 Absatz 1 Buchstabe
        a) DSGVO. Sie haben dann die Möglichkeit, über entsprechende
        Einstellungen im Rahmen des Online-Angebots Ihre entsprechenden
        Einstellungen vorzunehmen. Sie können eine hierüber erteilte
        Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen. Weitere
        Informationen zu den Cookies und zu deren Funktion im Einzelnen sowie zu
        Einstellungs- und Widerrufsmöglichkeiten erhalten Sie in unserer{' '}
        <a href="https://app.yuuxyoga.de/cookie-policy">Cookie Policy</a>.
      </p>

      <p>
        Wenn Sie den Einsatz von Cookies insgesamt nicht wünschen, können Sie
        auch deren Speicherung ggf. durch entspre-chende Einstellungen Ihres
        Endgeräts verhindern. Gespeicherte Cookies können Sie in den
        Systemeinstellungen Ihres Endgeräts jederzeit löschen. Bitte beachten
        Sie, dass die Blockierung bestimmter Arten von Cookies zu einer
        beeinträch-tigten Nutzung unseres Online-Angebots führen kann.
      </p>

      <h4>6. Eingebundene Drittdienste</h4>

      <p>
        Soweit wir im Rahmen unseres Online-Angebots Dienste anderer Anbieter
        einbinden, um Ihnen bestimmte Inhalte oder Funktionen zu bieten (z.B.
        das Abspielen von Videos oder eine Routenplanung) und wir dabei
        personenbezogene Daten verarbeiten, erfolgt dies auf Basis des Artikels
        6 Absatz 1 Buchstabe b) und f) DSGVO. Denn die Datenverarbeitung ist
        dann erforderlich, um die von Ihnen gewählten Funktionen umzusetzen bzw.
        um unser berechtigtes Interesse an einem optimalen Funktionsumfang des
        Online-Angebots zu wahren. Soweit im Rahmen dieser Drittdienste
        möglicherweise Coo-kies eingesetzt werden, gelten die Ausführungen unter
        Ziffer 5. Bitte informieren Sie sich in Bezug auf die Drittdienste auch
        über die Datenschutzerklärung des jeweiligen Anbieters.
      </p>

      <p>
        Dienste anderer Anbieter, die wir einbinden bzw. auf die von uns
        verwiesen wird, werden von den entsprechenden Dritten bereitgestellt.
        Wir haben grundsätzlich keinen Einfluss auf Inhalt und Funktion der
        Drittdienste und sind für die Verarbeitung Ihrer personenbezogenen Daten
        durch deren Anbieter grundsätzlich nicht verantwortlich, soweit die
        Drittdienste nicht vollständig in unserem Auftrag konzipiert und dann
        von uns in eigener Verantwortlichkeit eingebunden werden. Soweit die
        Einbindung eines Drittdienstes dazu führt, dass wir mit dessen Anbieter
        gemeinsame Prozesse etablieren, legen wir mit diesem Anbieter in einer
        Vereinbarung über die gemeinsame Verantwortlichkeit nach Artikel 26
        DSGVO fest, wie die jeweiligen Aufgaben und Zuständigkeiten bei der
        Verarbeitung personenbezogener Daten ausgestaltet sind und wer welche
        datenschutz-rechtlichen Verpflichtungen erfüllt. Soweit Cookies auch auf
        Basis Ihrer Einwilligung gesetzt werden sollen, erhalten Sie weitere
        Informationen zur Verantwortlichkeit für das Setzen dieser Cookies bzw.
        damit ggf. verbundene Drittdienste im entsprechenden Bereich in der{' '}
        <a href="https://app.yuuxyoga.de/cookie-policy">Cookie Policy</a>.
      </p>

      <p>
        Soweit nicht anders angegeben, werden Profile auf Social Media in
        unserem Online-Angebot grundsätzlich lediglich als Link zu den
        entsprechenden Drittdiensten eingebunden. Nach dem Anklicken des
        eingebundenen Text-/Bild-Links werden Sie auf das Angebot des jeweiligen
        Social-Media-Anbieters weitergeleitet. Nach der Weiterleitung werden
        ggf. personenbezogene Daten direkt durch den Drittanbieter erhoben.
        Sollten Sie währenddessen in Ihrem Benutzerkonto des jeweiligen
        Social-Media-Anbieters eingeloggt sein, kann dieser ggf. die gesammelten
        Informationen des konkreten Besuchs dem persönlichen Benutzerkonto
        zuordnen. Interagieren Sie über einen „Teilen“-Button des jeweiligen
        Social-Media-Anbieters, können diese Informationen in dem persönlichen
        Benutzerkonto gespeichert und ggf. veröffentlicht werden. Möchten Sie
        verhindern, dass die gesammelten Informationen unmittelbar Ihrem
        Benutzerkonto zugeordnet werden, müssen Sie sich vor dem Anklicken des
        eingebundenen Text-/Bild-Links ausloggen.
      </p>

      <p>
        Für die Analyse und Verwaltung von In-App-Käufen nutzt Yuu Yoga den
        Dienst RevenueCat (Anbieter: Revenue Cat, Inc., 633 Taraval St. Suite
        101, San Francisco, CA, 94116, USA). Die Übermittlung erfolgt
        DSGVO-konform, analog den Vorschriften des Art. 46 DSGVO. RevenueCat
        speichert bzw. Verarbeitet die Daten und bereitet sie in einer
        Web-Oberfläche auf. Die Daten werden durch RevenueCat in den USA oder
        Europa gespeichert. RevenueCat unterstellt sich der EU-DSGVO (EU
        2016/679) per DPA:{' '}
        <a href="https://www.revenuecat.com/dpa">
          https://www.revenuecat.com/dpa
        </a>
      </p>

      <p>
        Du stimmst der Datenerfassung durch Yuu Yoga und der Übermittlung der
        Daten an RevenueCat zu. Du kannst die eigenen Daten in Yuu Yoga bzw. bei
        RevenueCat aus der Datenbank löschen lassen, hierzu kannst du eine
        E-Mail an <a href="mailto:info@yuuxyoga.de">info@yuuxyoga.de</a>{' '}
        verfassen und die Löschung mit dem Hinweis „Löschung meiner Daten bei
        RevenueCat“ fordern. Wenn dies nicht erfolgt, so werden die Daten
        spätestens nach den gesetzlich vorgeschriebenen Aufbewahrungsfristen
        gelöscht. Wir verweisen auf die Datenschutzbestimmungen und auf die
        Allgemeinen Geschäftsbedingungen von RevenueCat:
      </p>

      <p>
        Datenschutzbestimmungen:{' '}
        <a href="https://www.revenuecat.com/privacy">
          https://www.revenuecat.com/privacy
        </a>
      </p>

      <p>
        Allgemeine Geschäftsbedingungen:{' '}
        <a href="https://www.revenuecat.com/terms">
          https://www.revenuecat.com/terms
        </a>
      </p>

      <p>
        Unter folgendem Link können die Daten, die von RevenueCat gespeichert
        und aufbereitet werden, eingesehen werden:{' '}
        <a href="https://www.revenuecat.com/dpa">
          https://www.revenuecat.com/dpa
        </a>
      </p>

      <h4>7. Empfänger personenbezogener Daten</h4>

      <p>
        Innerhalb unseres Unternehmens haben nur diejenigen Personen Zugriff auf
        Ihre personenbezogenen Daten, die diese für die jeweils genannten Zwecke
        benötigen. An externe Empfänger geben Ihre personenbezogenen Daten nur
        weiter, wenn hierfür eine gesetzliche Erlaubnis besteht oder uns Ihre
        Einwilligung vorliegt. Nachfolgend finden Sie eine Übersicht über die
        entsprechenden Empfänger:{' '}
      </p>

      <p>
        - Auftragsverarbeiter: Konzerngesellschaften oder externe Dienstleister,
        beispielsweise in den Bereichen der technischen Infra-struktur und
        Wartung, die sorgfältig ausgewählt und überprüft werden. Die
        Auftragsverarbeiter dürfen die Daten ausschließ-lich nach unseren
        Weisungen verwenden.{' '}
      </p>
      <p>
        - Öffentliche Stellen: Behörden und staatliche Institutionen, wie z.B.
        Steuerbehörden, Staatsanwaltschaften oder Gerichte, an die wir z.B. zur
        Erfüllung rechtlicher Verpflichtungen oder zur Wahrung berechtigter
        Interessen personenbezogene Daten übermitteln (müssen).
      </p>
      <p>
        - Private Stellen: Kooperationspartner sowie andere Dienstleister oder
        Personen, an die Daten auf Basis einer Einwilli-gung, zur Durchführung
        eines Vertrages mit Ihnen oder zur Wahrung berechtigter Interessen
        übermittelt werden.
      </p>

      <h4>8. Datenverarbeitung in Drittländern</h4>

      <p>
        Findet eine Datenübermittlung an Stellen statt, deren Sitz oder deren
        Ort der Datenverarbeitung nicht in einem Mitgliedstaat der Europäischen
        Union, einem anderen Vertragsstaat des Abkommens über den Europäischen
        Wirtschaftsraum oder einem Staat, für den durch einen Beschluss der
        Europäischen Kommission ein angemesse-nes Datenschutzniveau festgestellt
        wurde, gelegen ist, stellen wir vor der Weitergabe sicher, dass die
        Datenübermittlung entweder durch einen gesetzlichen Erlaubnis-tatbestand
        gedeckt ist, Garantien für ein angemessenes Datenschutzniveau in Bezug
        auf die Datenübermittlung bestehen (z.B. durch die Vereinbarung
        vertraglicher Gewährleistungen, behördlich anerkannte Regelungen oder
        verbindliche interne Datenschutzvorschriften beim Empfänger) oder Sie
        Ihre Einwilligung in die Daten-übermittlung erteilt haben.
      </p>

      <p>
        Soweit die Datenübermittlung auf Grundlage von Artikel 46, 47 oder 49
        Absatz 1 Unterabsatz 2 DSGVO erfolgt, können Sie bei uns eine Kopie der
        Garantien für das Bestehen eines angemessenen Datenschutzniveaus in
        Bezug auf die Daten-übermittlung oder einen Hinweis auf die
        Verfügbarkeit einer Kopie der Garantien erhalten. Bitte nutzen Sie
        hierfür die Angaben unter Ziffer 1.
      </p>

      <h4>9. Speicherdauer, Löschung</h4>

      <p>
        Wir speichern Ihre personenbezogenen Daten, wenn hierfür eine
        gesetzliche Erlaubnis besteht, nur so lange wie zur Erreichung der
        verfolgten Zwecke erforderlich bzw. solange Sie die Einwilligung nicht
        widerrufen haben. Im Falle eines Widerspruchs gegen die Verarbeitung
        löschen wir Ihre personenbezogenen Daten, es sei denn, die
        Weiterverarbeitung ist nach den gesetzlichen Bestimmungen weiterhin
        erlaubt. Wir löschen Ihre personenbezogenen Daten auch dann, wenn wir
        hierzu aus anderen rechtlichen Gründen verpflichtet sind. Unter
        Anwendung dieser allgemeinen Grundsätze löschen wir Ihre
        personenbezogenen Daten in der Regel unverzüglich{' '}
      </p>

      <p>
        - nach Wegfall der rechtlichen Grundlage und sofern keine anderweitige
        Rechtsgrundlage (z.B. handels- und steuerrechtliche
        Aufbewah-rungsfristen) eingreift. Falls Letzteres zutrifft, löschen wir
        die Daten nach Wegfall der anderweitigen Rechtsgrundlage;
      </p>
      <p>
        - wenn Ihre personenbezogenen Daten für die von uns verfolgten Zwecke
        nicht mehr erforderlich sind und keine ander-weitige Rechtsgrundlage
        (z.B. handels- und steuerrechtliche Aufbewahrungsfristen) eingreift.
        Falls Letzteres zutrifft, löschen wir die Daten nach Wegfall der
        anderweitigen Rechtsgrundlage.
      </p>

      <h4>10. Betroffenenrechte</h4>

      <p>
        Auskunftsrecht: Sie haben das Recht, Auskunft über die von uns zu Ihrer
        Person gespeicherten Daten zu erhalten.{' '}
      </p>

      <p>
        Berichtigungs- und Löschungsrecht: Sie können von uns die Berichtigung
        falscher Daten und - soweit die gesetzlichen Vorausset-zungen erfüllt
        sind - Löschung Ihrer Daten verlangen.{' '}
      </p>

      <p>
        Einschränkung der Verarbeitung: Sie können von uns - soweit die
        gesetzlichen Voraussetzungen erfüllt sind - verlangen, dass wir die
        Verarbeitung Ihrer Daten einschränken.{' '}
      </p>

      <p>
        Datenübertragbarkeit: Sollten Sie uns Daten auf Basis eines Vertrages
        oder einer Einwilligung bereitgestellt haben, so können Sie bei
        Vorliegen der gesetzlichen Voraussetzungen verlangen, dass Sie die von
        Ihnen bereitgestellten Daten in einem strukturierten, gängigen und
        maschinenlesbaren Format erhalten oder dass wir diese an einen anderen
        Verant-wortlichen übermitteln.
      </p>

      <p>
        Widerspruch: Sie haben das Recht, aus Gründen, die sich aus ihrer
        besonderen Situation ergeben, jederzeit der Datenverarbeitung durch uns
        zu widersprechen, soweit diese auf der Wahrung berechtigter Interesse
        beruht. Sofern Sie von Ihrem Widerspruchs-recht Gebrauch machen, werden
        wir die Verarbeitung einstellen, es sei denn, wir können zwingende
        schutzwürdige Gründe für die Weiterverarbeitung nachweisen, welche Ihre
        Rechte und Interessen überwiegen.{' '}
      </p>

      <p>
        Widerspruch gegen Direktwerbung: Sofern wir Ihre personenbezogenen Daten
        verarbeiten, um Direktwerbung zu betreiben, haben Sie das Recht,
        jederzeit der Datenverarbeitung durch uns zu diesem Zweck zu
        widersprechen. Sofern Sie von Ihrem Widerspruchsrecht Gebrauch machen,
        werden wir die Verarbeitung zu diesem Zweck einstellen.
      </p>

      <p>
        Widerruf der Einwilligung: Sofern Sie uns eine Einwilligung in die
        Verarbeitung Ihrer personenbezogenen Daten erteilt ha-ben, können Sie
        diese jederzeit mit Wirkung für die Zukunft widerrufen. Die
        Rechtmäßigkeit der Verarbeitung Ihrer Daten bis zum Widerruf bleibt
        hiervon unberührt.
      </p>

      <p>
        Beschwerderecht bei der Aufsichtsbehörde: Sie können zudem eine
        Beschwerde bei der zuständigen Aufsichtsbehörde einreichen, wenn Sie der
        Meinung sind, dass die Verarbeitung Ihrer Daten gegen geltendes Recht
        verstößt. Sie können sich hierzu an die Auf-sichtsbehörde wenden, die
        für Ihren Wohnort bzw. Ihr Land zuständig ist oder an die für uns
        zuständige Aufsichtsbehörde.{' '}
      </p>

      <p>
        Ihr Kontakt zu uns und die Ausübung Ihrer Rechte: Des Weiteren können
        Sie sich bei Fragen zur Verarbeitung Ihrer personenbezogenen Daten und
        zu Ihren Betroffenenrechten unentgeltlich mit uns in Verbindung setzen.
        Wenden Sie sich bitte an{' '}
        <a href="mailto:betroffenenrechte@initium.gmbh">
          betroffenenrechte@initium.gmbh
        </a>{' '}
        oder postalisch an die oben unter Ziffer 1 angegebene Anschrift. Bitte
        stellen Sie dabei sicher, dass uns eine eindeutige Identifizierung Ihrer
        Person möglich ist. Beim Widerruf der Einwilligung können Sie alternativ
        auch den Kontaktweg wählen, den Sie bei der Abgabe der Einwilligung
        ver-wendet haben.
      </p>

      <h4>11. Stand</h4>

      <p>
        Es gilt die aktuellste Version dieser Datenschutzerklärung. Stand
        12.10.2021.
      </p>
    </DefaultPageLayout>
  );
}

export default PrivacyPolicyPage;
