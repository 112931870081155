import type { HomeContents } from '@youga/youga-interfaces';

import { useInstructors } from './useInstructors';
import { useVideoSessions } from './useVideoSessions';
import { useCourses } from './useCourses';

export function useHome() {
  const courses = useCourses();
  const instructors = useInstructors();
  const videoSessions = useVideoSessions();

  const data: HomeContents | null =
    courses.data && instructors.data && videoSessions.data
      ? {
          courses: courses.data?.courses,
          sortedCourses: courses.data?.sortedCourses,
          instructors: instructors.data?.instructors,
          videoSessions: videoSessions.data?.videoSessions,
        }
      : null;

  return {
    data,
    isValidating:
      courses.isValidating ||
      instructors.isValidating ||
      videoSessions.isValidating,
  };
}

export function usePrefetchHome(): void {
  useHome();
}
