/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import debounce from 'lodash/debounce';
import { Box } from 'theme-ui';
import { navigate } from 'gatsby';
import { preloadScript } from 'opentok-react';

import type { VideoSession, StreamingSession } from '@youga/youga-interfaces';
import {
  useCourses,
  useInstructors,
  useTrainingSession,
  useUser,
} from '@youga/youga-client-api';

import type { TrainingSession } from '../../../types/interfaces';
import findPoseByName from '../../../utils/findPoseByName';
import { saveBurstPhotos, stepFromTime } from './utils';
import { takePhoto } from '../../02_molecules/VideoSessionSidebar/MirrorContainer';
import toggleFullScreen, {
  isFullScreen,
} from '../../../utils/toggleFullScreen';
import API from '../../../services/API';
import useApi from '../../../hooks/useAPI';
import useTracking from '../../../hooks/useTracking';
import useAuth from '../../../hooks/useAuth';
import { Marker } from '../../01_atoms/VideoPlayer/MarkerOverlay';
import Header from '../../02_molecules/Header/Header';
import calculateVideoSize from '../../../utils/calculateVideoSize';
import VideoSessionSidebar from '../../02_molecules/VideoSessionSidebar/VideoSessionSidebar';
import VideoPlayer from '../../01_atoms/VideoPlayer/VideoPlayer';
import VideoTitlesOverlay from '../../01_atoms/VideoPlayer/VideoTitlesOverlay';
import Spinner from '../../01_atoms/Spinner/Spinner';

enum VideoState {
  Pause,
  Play,
  Stop,
}

const BURST_PICTURE_INTERVAL = 1;
const BURST_PICTURE_COUNT = 7;
const MAX_UPLOAD_ATTEMPTS = 3;

export interface PictureSchedule {
  id: string;
  time: number;
  images: {
    time: number;
    picture: string | null;
  }[];
  sending: boolean;
}

export interface Props {
  videoId: string;
  trainingSessionId: string;
  streamingSession: StreamingSession;
  passive?: boolean;
}

function TrainingSessionSection({
  videoId,
  trainingSessionId,
  streamingSession,
  passive = false,
}: Props) {
  const [displayControls, setDisplayControls] = useState(true);
  const [darkOverlayVisible, setDarkOverlayVisible] = useState(true);
  const auth = useAuth();

  const [videoSession, setVideoSession] = useState<VideoSession | undefined>();

  const { track } = useTracking();
  const { data: instructorsData } = useInstructors();
  const { data: coursesData } = useCourses();
  const { data: user } = useUser();
  const {
    data: trainingSession,
    patchTrainingSession,
    mutate,
  } = useTrainingSession(trainingSessionId);
  const { data: posesData } = useApi('posesData');

  const [videoState, setVideoState] = useState<VideoState>(VideoState.Pause);
  const [showVideoOverlay, setShowVideoOverlay] = useState(true);
  const pictureSchedule = useRef<PictureSchedule[] | undefined>();
  const playerRef = useRef(null);
  const currentTime = useRef(0);
  const lastUpdatedCurrentTime = useRef(0);
  const totalLength = useRef(3600);
  const elapsedSeconds = useRef(0);
  const completionRate = useRef(0);
  const smartMirrorOn = useRef(false);
  const [currentPose, setCurrentPose] = useState('');
  const currentPoseName = findPoseByName(posesData ?? [], currentPose);
  const [forceVideoControls, setForceVideoControls] = useState(false);
  const [videoSize, setVideoSize] = useState<{
    width: number;
    height: number;
  } | null>(null);

  const failedUploads = useRef<
    {
      time: string;
      id: string;
      image: { difference: number; picture: string | null };
      attempts: number;
    }[]
  >([]);

  const markSessionAsFinished = useCallback(async (): Promise<void> => {
    if (trainingSession?.finished === true) {
      return;
    }
    await patchTrainingSession({
      finished: true,
    });
  }, [trainingSession, patchTrainingSession]);

  const setPictureSchedule = (video: VideoSession): void => {
    const steps = video.steps ?? [];
    if (!Array.isArray(steps)) {
      // TODO
      throw new Error('Expected a array of video steps');
    }
    pictureSchedule.current = steps
      .filter((s) => s.id !== 'intro')
      .map((step) => {
        return {
          id: step.id,
          time: step.time,
          images: new Array(BURST_PICTURE_COUNT).fill(null).map((_, index) => {
            return {
              time:
                step.time -
                Math.floor(BURST_PICTURE_COUNT / 2) * BURST_PICTURE_INTERVAL +
                index * BURST_PICTURE_INTERVAL,
              picture: null,
            };
          }),
          sending: false,
        };
      });
  };

  /**
   * [Mount/Unmount]
   * Sets the display-state of the header (and potentially other control elements) depending on the mouse/touch activity
   */
  useEffect(() => {
    let isMounted = true;

    const debounceHideControls = debounce((): void => {
      if (isMounted) {
        setDisplayControls(false);
      }
    }, 3000);

    const onInteraction = (): void => {
      setDisplayControls(true);
      debounceHideControls();
    };

    onInteraction();

    if (typeof window !== 'undefined') {
      window.addEventListener('mousemove', onInteraction);
      window.addEventListener('click', onInteraction);
      window.addEventListener('touchmove', onInteraction);
      window.addEventListener('touch', onInteraction);
      window.addEventListener('touchstart', onInteraction);
      window.addEventListener('touchmove', onInteraction);
    }

    return (): void => {
      isMounted = false;

      window.removeEventListener('mousemove', onInteraction);
      window.removeEventListener('mousemove', onInteraction);
      window.removeEventListener('click', onInteraction);
      window.removeEventListener('touchmove', onInteraction);
      window.removeEventListener('touch', onInteraction);
      window.removeEventListener('touchstart', onInteraction);
      window.removeEventListener('touchmove', onInteraction);
    };
  }, []);

  const courseTitle = React.useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [_key, value] of Object.entries(coursesData?.courses || {})) {
      if (videoId && value.sessions.includes(videoId)) {
        return value.title;
      }
    }
  }, [videoId, coursesData]);

  const trainerName = React.useMemo(() => {
    return videoSession?.instructor
      ? (instructorsData?.instructors[videoSession?.instructor] || {}).name
      : videoSession?.instructor;
  }, [instructorsData, videoSession?.instructor]);

  /**
   * [Mount/Unmount]
   * Listens for resize-events and triggers the calculation of the video size
   */
  useEffect(() => {
    let isMounted = true;

    const updateVideoSize = (): void => {
      setVideoSize(calculateVideoSize());
    };

    const onResize = debounce((): void => {
      if (isMounted) {
        updateVideoSize();
      }
    }, 100);

    updateVideoSize();

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', onResize);
    }

    return (): void => {
      isMounted = false;
      window.removeEventListener('resize', onResize);
    };
  }, []);

  /**
   * [videoId, homeContent]
   * Searches the videoSessions and puts the selected one in our state
   */
  useEffect(() => {
    // Only load the videoSession if we know which videoId and we did not load it yet
    if (videoId && videoId !== videoSession?.id) {
      // We need to fetch exactly this API because the `home` API is not public
      // and unauthenticated users that use the /connect flow wont be able to fetch the home API
      API.getCuratedVideos()
        .then((videos: VideoSession[]) =>
          videos.find((video) => video.id === videoId),
        )
        .then((video: VideoSession | undefined) => {
          if (video) {
            setVideoSession(video);
            setPictureSchedule(video);
          }
        });
    }
  }, [videoId, videoSession?.id]);

  /**
   * [videoSession]
   * Tracks the page-view only once per selected video
   */
  useEffect(() => {
    if (!videoSession) {
      return;
    }

    // DO NOT REMOVE
    // This creates the `trainingSession` entry in our database
    track(
      'training-session-section',
      {
        videoSessionId: videoId,
        trainingSessionId: trainingSessionId,
        videoCode: videoSession.path || 'video-code',
        videoSessionName: videoSession.title,
        smartMirrorOn: smartMirrorOn.current,
        instructorId: videoSession.instructor,
        passiveScreen: passive,
      },
      auth.user,
    );
  }, [videoSession, auth.user, passive, track, trainingSessionId, videoId]);

  /**
   * Advance timer when the video is playing.
   * If the pose has changed, update and take a picture.
   */
  const onTimeTicked = useCallback(async (): Promise<void> => {
    if (!videoSession || videoState !== VideoState.Play) {
      return Promise.resolve();
    }

    if (darkOverlayVisible) {
      setDarkOverlayVisible(false);
    }

    // currentTime will be updated from the players state change hooks
    //
    // Unfortunately there is no possibility to also get the elapsed seconds.
    // So we store the lastUpdated current time and only add the difference to elapsed
    const workingCurrentTime = currentTime.current;
    const lastRunTime = lastUpdatedCurrentTime.current;

    elapsedSeconds.current +=
      workingCurrentTime - lastUpdatedCurrentTime.current;
    lastUpdatedCurrentTime.current = workingCurrentTime;

    const newCompletionRate =
      100 * (elapsedSeconds.current / totalLength.current);

    if (
      newCompletionRate >= completionRate.current + 1 &&
      completionRate.current <= 100
    ) {
      completionRate.current += 1;

      track(
        'training-session-completion-update',
        {
          trainingSessionId,
          videoCode: videoSession.path || 'video-code',
          videoSessionId: videoSession.id,
          videoSessionName: videoSession.title,
          completionRate: Math.min(completionRate.current, 100),
          smartMirrorOn: smartMirrorOn.current,
          instructorId: videoSession.instructor,
        },
        auth.user,
      );
    }

    const { steps } = videoSession;

    if (!Array.isArray(steps)) {
      return Promise.resolve();
    }

    if (workingCurrentTime >= totalLength.current - 6) {
      markSessionAsFinished().catch(() => {
        // eslint-disable-next-line no-console
        console.log("The session couldn't be marked as finished");
      });
    }

    // Step 2: Display current step decription with an offset of 3s before and after
    const offsetLabel = stepFromTime(steps, workingCurrentTime, 3, 3);
    setCurrentPose(offsetLabel ? offsetLabel.id : '');

    // If we are right in a step, we need to force display the controls
    if (offsetLabel) {
      setForceVideoControls(true);
    } else {
      setForceVideoControls(false);
    }

    // Step 3: Take picture if we have reached the exact pose timing
    if ((pictureSchedule.current || []).length > 0) {
      const tasks = (pictureSchedule.current || []).reduce<
        {
          time: number;
          picture: string | null;
        }[]
      >(
        (prev, curr) => [
          ...prev,
          ...curr.images.filter(
            (i) =>
              lastRunTime <= i.time &&
              workingCurrentTime >= i.time &&
              i.picture === null,
          ),
        ],
        [],
      );

      if (tasks.length > 0) {
        const photo = takePhoto();

        if (photo) {
          tasks.forEach((t) => {
            // eslint-disable-next-line no-param-reassign
            t.picture = photo;
          });
        }
      }
    }

    // Step 4: Upload all picture batches that are completed or done
    const uploadingQueue = (pictureSchedule.current || []).filter((s) => {
      if (s.sending) {
        return false;
      }

      // upload it, if there are no empty picture slots anymore
      if (!s.images.some((i) => i.picture === null)) {
        return true;
      }

      // upload it, if we are past this step and there is at least one picture to upload
      return (
        s.images.some((i) => i.picture !== null) &&
        Math.max(...s.images.map((i) => i.time)) < lastRunTime
      );
    });

    // Directly set them to sending so we do not send them twice
    uploadingQueue.forEach((s) => {
      // eslint-disable-next-line no-param-reassign
      s.sending = true;
    });

    // If there have been failed image uploads, a retry will be attempted
    const secondAttempt = failedUploads.current.pop();
    if (secondAttempt) {
      if (user == null) {
        throw new Error(`Missing user`);
      }

      await saveBurstPhotos(
        // user object comes directly from cognito
        user?.id,
        trainingSessionId,
        secondAttempt.time,
        secondAttempt.id,
        videoSession.path || '',
        videoSession.id,
        videoSession.title,
        videoSession.instructor,
        [secondAttempt.image],
        secondAttempt.image.picture,
      ).catch(() => {
        secondAttempt.attempts += 1;
        if (secondAttempt.attempts <= MAX_UPLOAD_ATTEMPTS) {
          failedUploads.current.push(secondAttempt);
        }
      });
    }

    await Promise.all(
      uploadingQueue.map((s) =>
        s.images
          .filter((i) => !!i.picture)
          .map((i) => {
            return {
              difference: i.time - s.time,
              picture: i.picture,
            };
          })
          .map(async (i) =>
            saveBurstPhotos(
              user?.id || '',
              trainingSessionId,
              `${s.time}`,
              `${s.id}`,
              videoSession.path || '',
              videoSession.id,
              videoSession.title,
              videoSession.instructor,
              [i],
              i.picture,
            )
              .then((res) => {
                if (!res) {
                  return;
                }
                mutate(res.data as TrainingSession, false);
                return res;
              })
              .catch((e) => {
                // eslint-disable-next-line no-console
                console.log(
                  `An error occurred while trying to update the picture ${i.difference}. A second attempt will be scheduled.`,
                  e,
                );
                failedUploads.current.push({
                  time: `${s.time}`,
                  id: `${s.id}`,
                  image: i,
                  attempts: 1,
                });
              }),
          ),
      ),
    );

    return Promise.resolve();
  }, [
    mutate,
    trainingSessionId,
    user,
    darkOverlayVisible,
    markSessionAsFinished,
    track,
    auth.user,
    videoSession,
    videoState,
  ]);

  /**
   * [videoSession, videoState]
   * Start 0.5s interval (if the video is playing) that increments the ticked time
   */
  useEffect(() => {
    let timer: number;

    if (
      typeof window !== 'undefined' &&
      videoSession &&
      videoState === VideoState.Play
    ) {
      timer = window.setInterval((): void => {
        onTimeTicked();
      }, 500);
    }

    return (): void => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [onTimeTicked, videoSession, videoState]);

  /**
   * Triggers the tracking once the player state changed to `paused = true`
   */
  const onPauseHandler = useCallback((): void => {
    setVideoState(VideoState.Pause);

    setShowVideoOverlay(false);

    const videoData = {
      time: currentTime.current,
      url: videoSession?.url,
    };

    if (videoSession) {
      track(
        'video-pause',
        {
          trainingSessionId,
          videoCode: videoSession.path || 'video-code',
          videoSessionId: videoSession.id,
          videoSessionName: videoSession.title,
          completionRate: completionRate.current,
          smartMirrorOn: smartMirrorOn.current,
          instructorId: videoSession.instructor,
          ...videoData,
        },
        auth.user,
      );
    }
  }, [track, auth.user, videoSession, trainingSessionId]);

  /**
   * Triggers the tracking once the player state changed to `paused = false`
   *
   * We need to update the `lastUpdatedCurrentTime` value because otherwise we would count the skipped seconds as elapsed
   */
  const onPlayHandler = useCallback((): void => {
    lastUpdatedCurrentTime.current = currentTime.current;
    setVideoState(VideoState.Play);
    setShowVideoOverlay(false);

    if (videoSession) {
      track(
        'video-play',
        {
          trainingSessionId,
          videoCode: videoSession.path || 'video-code',
          videoSessionId: videoSession.id,
          videoSessionName: videoSession.title,
          completionRate: completionRate.current,
          smartMirrorOn: smartMirrorOn.current,
          instructorId: videoSession.instructor,
          time: currentTime.current,
        },
        auth.user,
      );
    }
  }, [track, auth.user, videoSession, trainingSessionId]);

  /**
   * Triggers the tracking to get insights into the waiting state
   */
  const onDebugHandler = useCallback(
    (
      waiting: boolean,
      autoPaused: boolean,
      readyState: number,
      networkState: number,
    ): void => {
      if (videoSession) {
        track(
          'video-debug',
          {
            trainingSessionId,
            videoCode: videoSession.path || 'video-code',
            videoSessionId: videoSession.id,
            videoSessionName: videoSession.title,
            completionRate: completionRate.current,
            smartMirrorOn: smartMirrorOn.current,
            instructorId: videoSession.instructor,
            time: currentTime.current,
            waiting,
            autoPaused,
            readyState,
            networkState,
          },
          auth.user,
        );
      }
    },
    [track, auth.user, videoSession, trainingSessionId],
  );

  /**
   * Triggers the tracking once the user finished the video
   */
  const onEndHandler = useCallback(async (): Promise<void> => {
    if (isFullScreen()) {
      toggleFullScreen();
    }

    if (videoSession) {
      await track(
        'video-finished',
        {
          trainingSessionId,
          videoCode: videoSession.path || 'video-code',
          videoSessionId: videoSession.id,
          videoSessionName: videoSession.title,
          completionRate: completionRate.current,
          smartMirrorOn: smartMirrorOn.current,
          instructorId: videoSession.instructor,
          poseComparisonShown: true,
        },
        auth.user,
      );
    }

    navigate(`/app/finish-session/${trainingSessionId}`, {
      replace: true,
    });
  }, [track, auth.user, videoSession]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Checks for important state-changes of the player and updates our own state
   *
   * - currentTime change -> setting our own currentTime value
   * - duration change -> setting our own duration value
   * - paused change -> trigger play/pause handler
   * - ended change -> trigger ended handler
   */
  const handleStateChange = useCallback(
    (state, prevState): void => {
      const stateChange = [
        'duration',
        'currentTime',
        'waiting',
        'paused',
        'ended',
        'autoPaused',
        'readyState',
        'networkState',
      ]
        .filter((field) => state[field] !== prevState[field])
        .reduce<{ [key: string]: any }>(
          (
            val,
            field,
          ): {
            [key: string]: any;
          } => {
            // eslint-disable-next-line no-param-reassign
            val[field] = state[field];
            return val;
          },
          {},
        );

      if (Object.keys(stateChange).length === 0) {
        return;
      }

      if (
        typeof stateChange.waiting !== 'undefined' ||
        typeof stateChange.autoPaused !== 'undefined' ||
        typeof stateChange.readyState !== 'undefined' ||
        typeof stateChange.networkState !== 'undefined'
      ) {
        onDebugHandler(
          stateChange.waiting,
          stateChange.autoPaused,
          stateChange.readyState,
          stateChange.networkState,
        );
      }

      if (typeof stateChange.duration !== 'undefined') {
        totalLength.current = stateChange.duration;
      }

      if (typeof stateChange.currentTime !== 'undefined') {
        currentTime.current = stateChange.currentTime;
      }

      if (
        typeof stateChange.ended !== 'undefined' &&
        stateChange.ended === true
      ) {
        onEndHandler();
        return;
      }

      if (typeof stateChange.paused !== 'undefined') {
        if (stateChange.paused === false) {
          onPlayHandler();
        } else {
          onPauseHandler();
        }
        return;
      }

      if (typeof stateChange.waiting !== 'undefined') {
        if (stateChange.waiting === false) {
          onPlayHandler();
        } else {
          onPauseHandler();
        }
      }
    },
    [onDebugHandler, onEndHandler, onPauseHandler, onPlayHandler],
  );

  /**
   * [videoSession, playerRef]
   * Listens to state-changes of the player and triggers our own play/pause/end listener
   */
  useEffect(() => {
    let unsubscribe: () => void;

    if (playerRef.current) {
      unsubscribe = (playerRef.current as any).subscribeToStateChange(
        handleStateChange,
      );
    }

    return (): void => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [videoSession, playerRef, handleStateChange]);

  const videoSteps = React.useMemo(
    () => (Array.isArray(videoSession?.steps) ? videoSession?.steps || [] : []),
    [videoSession],
  );

  const videoMarker = useMemo((): Marker[] => {
    return videoSteps
      .filter((step) => step.id !== 'intro')
      .filter((step) => !!step.referenceImage)
      .map((step) => {
        return {
          start: step.time - 3,
          length: 2 * 3,
          label: findPoseByName(posesData ?? [], step.id)?.de || step.id,
          thumbnailUrl: step.referenceImage as string,
        };
      });
  }, [videoSteps, posesData]);

  const videoUrl = videoSession?.urlHls;

  return (
    <>
      <Header
        showButton="back"
        theme="dark-transparent"
        fullWidth
        fadeOut={!(videoState === VideoState.Pause || displayControls)}
      />
      <Box
        className="video-wrapper"
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          backgroundColor: 'black',
        }}
      >
        {videoSession?.headerImage && videoUrl && videoSize ? (
          <Box
            sx={{
              position: 'relative',
              outline: 'none',
              mx: 'auto',
              width: '100%',
              height: '100%',
              maxWidth: `${videoSize.width}px`,
              maxHeight: `${videoSize.height}px`,
            }}
          >
            {showVideoOverlay && (
              <VideoTitlesOverlay
                title={courseTitle}
                subtitle={videoSession.title}
                trainer={trainerName}
              />
            )}
            <VideoPlayer
              forceDisplayControlBar={forceVideoControls}
              src={videoUrl}
              ref={playerRef}
              marker={videoMarker}
              poster={videoSession.headerImage}
              title={courseTitle}
              subtitle={videoSession.title}
              trainer={trainerName}
            />

            {currentPose && (
              <Box
                sx={{
                  position: 'absolute',
                  margin: 'auto',
                  padding: '0.5rem',
                  bottom: '4rem',
                  color: 'white',
                  textAlign: 'center',
                  backgroundColor: '#00000055',
                  left: '50%',
                  transform: 'translate(-50%, 0)',
                }}
              >
                &nbsp;{currentPoseName?.de || currentPose}&nbsp;
              </Box>
            )}

            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'black',
                opacity: darkOverlayVisible ? 0.5 : 0,
                pointerEvents: 'none',
                transition: 'opacity ease-in 0.3s',
              }}
            />
          </Box>
        ) : null}
      </Box>

      <VideoSessionSidebar
        takenPictures={trainingSession?.images}
        videoSessionId={videoId}
        steps={videoSteps}
        videoPlayerStore={(playerRef.current as any)?.manager?.store}
        passiveScreen={passive}
        streamingSession={streamingSession}
        videoState={videoState}
        smartMirrorTranslate={
          videoState === VideoState.Pause || displayControls
        }
        onStartClick={(): void => {
          if (playerRef.current) {
            (playerRef.current as unknown as HTMLVideoElement).play();
          }
        }}
        onSmartMirrorEnabled={(): void => {
          // Only track it once every session
          if (!videoSession || smartMirrorOn.current) {
            return;
          }

          setDarkOverlayVisible(false);

          smartMirrorOn.current = true;
          track(
            'smart-mirror-on',
            {
              videoSessionId: videoId,
              trainingSessionId,
              videoCode: videoSession.path || 'video-code',
              videoSessionName: videoSession.title,
              smartMirrorOn: smartMirrorOn.current,
              instructorId: videoSession.instructor,
              completionRate: completionRate.current || 0,
            },
            auth.user,
          );
        }}
      />
    </>
  );
}

function TrainingSessionSectionWrapper(props: Props) {
  const TrainingSessionSectionWithScriptLoader = preloadScript(() => (
    <TrainingSessionSection {...props} />
  ));

  return (
    <TrainingSessionSectionWithScriptLoader
      loadingDelegate={
        <Box
          sx={{
            position: 'relative',
            height: '100vh',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spinner />
        </Box>
      }
    />
  );
}

export default TrainingSessionSectionWrapper;
