import React from 'react';
import { Image } from 'theme-ui';
import moment from 'moment';
import { useTrainingSessions, useUser } from '@youga/youga-client-api';
import type { TrainingSession } from '@youga/youga-interfaces';
import Text from '../../01_atoms/Text/Text';
import JourneyProgressUi from './JourneyProgressUi';
import ChallengePassedIcon from '../../../assets/icons/iconCalendarCheck.svg';
import ChallengeFailIcon from '../../../assets/icons/iconCalendarFail.svg';
import ProgressBar from '@ramonak/react-progress-bar';
import { sessionCompleteNumber } from '../../../helper/JourneyHelpers';
import './JourneyProgress.css';

const TODAY = moment(new Date(), 'YYYY-MM-DD');

interface Challenge {
  day: number;
  title: string | undefined;
  challengeCompleted: boolean;
}

interface JourneyProgressChallengeProps {
  onOpenCourseDetails: (courseId: string) => void | Promise<void>;
  onDismiss: () => void;
}

function JourneyProgressChallenge(props: JourneyProgressChallengeProps) {
  const { onDismiss } = props;

  //const courseId = GoalsMeta['30_days_challenge'].id;

  const { data: user } = useUser();
  const { data: trainingSessions } = useTrainingSessions();

  const userChallenge = user?.preferences?.challenge;
  const userChallengeStartDate = userChallenge?.challengeStartDate;

  const daysSinceChallengeStart: number = React.useMemo(() => {
    const today = moment(new Date(), 'YYYY-MM-DD');
    const challengeStartDay = moment(userChallengeStartDate, 'YYYY-MM-DD');

    if (!Number.isNaN(today.diff(challengeStartDay, 'days'))) {
      return today.diff(challengeStartDay, 'days');
    }

    return 0;
  }, [userChallengeStartDate]);

  const challenges: Challenge[] = React.useMemo(() => {
    let counter = 0;
    const newChallenges = [];
    while (counter <= daysSinceChallengeStart) {
      const getDailyTrainingSession: TrainingSession | undefined =
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        trainingSessions?.find((session: TrainingSession) => {
          const dateFormatToDay = moment(session.timestamp, 'YYYY-MM-DD');
          return (
            session.completion != null &&
            session.completion >= sessionCompleteNumber &&
            TODAY.diff(dateFormatToDay, 'days') === counter
          );
        });

      const newChallenge: Challenge =
        getDailyTrainingSession !== undefined
          ? {
              title: getDailyTrainingSession.videoSessionName,
              day: daysSinceChallengeStart - counter + 1,
              challengeCompleted: true,
            }
          : {
              title: '-',
              day: daysSinceChallengeStart - counter + 1,
              challengeCompleted: false,
            };
      newChallenges.push(newChallenge);
      counter += 1;
    }
    return newChallenges;
  }, [daysSinceChallengeStart, trainingSessions]);

  const finishedSessions = React.useMemo(() => {
    return challenges.filter(
      (challenge: Challenge) => challenge.challengeCompleted,
    ).length;
  }, [challenges]);

  //const allSessionsFinished = finishedSessions === challenges.length;

  return (
    <JourneyProgressUi title="Tag für Tag" onDismiss={onDismiss}>
      <>
        <div
          style={{
            marginTop: 40,
            marginBottom: 60,
            alignSelf: 'center',
          }}
        >
          <h2
            style={{
              textAlign: 'center',
              marginBottom: 8,
            }}
          >
            Noch {30 - daysSinceChallengeStart} Tage
          </h2>
          <ProgressBar
            labelColor="black"
            bgColor="#cfebe9"
            completed={(100 / 30) * finishedSessions}
            barContainerClassName="progressBarContainer"
            className="progressBar"
            labelClassName="progressLabel"
            labelAlignment="center"
            customLabel={finishedSessions + '/30'}
          />
        </div>
        <Text
          size="h3light"
          variant="black"
          style={{
            fontSize: '25px',
            marginTop: 8,
            marginBottom: 8,
          }}
        >
          Meine Sessions bisher
        </Text>
        <div
          style={{
            alignSelf: 'center',
            marginBottom: 100,
            marginTop: 20,
            maxHeight: 200,
            overflow: 'auto',
          }}
        >
          {challenges.map((item) => (
            <div
              style={{
                display: 'block',
                clear: 'both',
              }}
              key={`day-${item.day}`}
            >
              {item.challengeCompleted ? (
                <Image
                  src={ChallengePassedIcon}
                  style={{ height: '30px', float: 'left' }}
                />
              ) : (
                <Image
                  src={ChallengeFailIcon}
                  style={{ height: '30px', float: 'left' }}
                />
              )}
              <div style={{ paddingTop: '7px' }}>
                <Text
                  size="h6"
                  variant="baseBlack"
                  style={{
                    fontWeight: 'bold',
                    width: 90,
                    paddingLeft: 16,
                  }}
                >
                  Tag #{item.day}
                </Text>
                <Text
                  size="h6light"
                  variant="baseBlack"
                  style={{ paddingLeft: '50px' }}
                >
                  {item.title}
                </Text>
              </div>
            </div>
          ))}
        </div>
      </>
    </JourneyProgressUi>
  );
}

export default JourneyProgressChallenge;
