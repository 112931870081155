/** @jsxImportSource theme-ui */
import React from 'react';

import { Link } from 'gatsby';
import { Box, Image } from 'theme-ui';
import Button from '../Button/Button';

interface SplashInstructorProps {
  thumbnail: string;
  target: string;
  name: string;
  collabLogo: string;
}

const SplashInstructor: React.FC<SplashInstructorProps> = ({
  thumbnail,
  target,
  name,
  collabLogo,
}: SplashInstructorProps) => (
  <Box
    sx={{
      position: 'relative',
      height: '100%',
      '::after': {
        content: "''",
        display: ['none', null, 'block'],
        paddingTop: '100%',
      },
    }}
  >
    <Image
      src={thumbnail}
      sx={{
        position: ['relative', null, 'absolute'],
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      }}
    />
    <Image
      src={collabLogo}
      sx={{
        position: 'absolute',
        top: '2rem',
        left: '50%',
        width: '100%',
        maxWidth: ['120px', '200px'],
        transform: 'translateX(-50%)',
      }}
    />
    <Button
      variant="white"
      sx={{
        position: 'absolute',
        left: '50%',
        bottom: 6,
        maxWidth: 'calc(100% - 40px)',
        transform: 'translateX(-50%)',
      }}
    >
      Mehr über {name}
    </Button>
    <Link
      to={target}
      sx={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
    />
  </Box>
);

export default SplashInstructor;
