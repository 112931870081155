import React, { CSSProperties, SyntheticEvent } from 'react';
import { Image } from '@theme-ui/components';

import { AuthVariant } from './AuthBox';
import Button from '../../01_atoms/Button/Button';
import PasswordInput from '../../01_atoms/PasswordInput/PasswordInput';
import Text from '../../01_atoms/Text/Text';
import TextInput from '../../01_atoms/TextInput/TextInput';

import IconFb from '../../../assets/icons/iconFb.svg';
import IconGoogle from '../../../assets/icons/iconGoogle.svg';
import IconApple from '../../../assets/icons/iconApple.svg';

type SignInFormProps = {
  errorInformation: null | {
    position: string;
    element: React.ReactNode;
  };
  onClickFacebookLogin: (e: SyntheticEvent<HTMLButtonElement>) => void;
  onClickGoogleLogin: (e: SyntheticEvent<HTMLButtonElement>) => void;
  onClickAppleLogin: (e: SyntheticEvent<HTMLButtonElement>) => void;
  setEmail: (v: string) => void;
  setPassword: (v: string) => void;
  setCurrentVariant: (variant: AuthVariant) => void;
  loading: boolean;
};

const Style = {
  socialMediaButton: {
    cursor: 'pointer',
    marginTop: '0.5rem',
    backgroundColor: 'rgb(243, 243, 243)',
    flexDirection: 'row' as CSSProperties['flexDirection'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    maxHeight: 47,
  },
  buttonIcon: {
    width: '8%',
  },
  buttonText: {
    fontWeight: 'bold',
    width: '92%',
  },
  loginButton: {
    cursor: 'pointer',
    marginTop: '3rem',
    display: 'block',
    alignSelf: 'flex-end',
  },
};

export default function SignInForm({
  errorInformation,
  onClickFacebookLogin,
  onClickGoogleLogin,
  onClickAppleLogin,
  setEmail,
  setPassword,
  setCurrentVariant,
  loading,
}: SignInFormProps) {
  return (
    <React.Fragment>
      <Button
        onClick={onClickGoogleLogin}
        style={Style.socialMediaButton}
        type="button"
      >
        <Image style={Style.buttonIcon} src={IconGoogle} />
        <Text sx={Style.buttonText}>Mit Google Einloggen</Text>
      </Button>

      <Button
        onClick={onClickFacebookLogin}
        style={Style.socialMediaButton}
        type="button"
      >
        <Image style={Style.buttonIcon} src={IconFb} />
        <Text sx={Style.buttonText}>Mit Facebook Einloggen</Text>
      </Button>

      <Button
        onClick={onClickAppleLogin}
        style={Style.socialMediaButton}
        type="button"
      >
        <Image style={Style.buttonIcon} src={IconApple} />
        <Text sx={Style.buttonText}>Mit Apple Einloggen</Text>
      </Button>

      <Text
        sx={{
          textAlign: 'center',
          margin: '2rem',
          color: 'rgb(98, 102, 105)',
          fontSize: '16px',
        }}
      >
        {'oder'}
      </Text>

      <TextInput
        id="email"
        type="text"
        name="email"
        placeholder="E-Mail Adresse"
        required
        onChange={(e): void => {
          setEmail(e.target.value);
        }}
        error={
          errorInformation?.position === 'email' && errorInformation.element
        }
        disabled={loading}
      />

      <PasswordInput
        id="password"
        name="password"
        onChange={setPassword}
        placeholder="Passwort"
        required
        overlayLink={
          <Button
            type="button"
            variant="textGrey"
            sx={{ fontSize: 1 }}
            onClick={(): void => setCurrentVariant('resetPassword')}
          >
            vergessen?
          </Button>
        }
        error={
          errorInformation?.position === 'password' && errorInformation.element
        }
        disabled={loading}
      />

      <Button type="submit" style={Style.loginButton} disabled={loading}>
        Einloggen
      </Button>

      {/* <Button
        type="button"
        variant="text"
        style={{ marginTop: '2rem' }}
        onClick={(): void => setCurrentVariant('signup')}
      >
        Du hast noch keinen Account?
      </Button> */}
    </React.Fragment>
  );
}
