import { useCourses, useUser } from '@youga/youga-client-api';

import MhmJourneyChallengeSection from '../MhmJourneyChallangeSection/MhmJourneyChallangeSection';
import MhmJourneySection from '../MhmJourneySection/MhmJourneySection';

function YourJourney() {
  const { data: coursesData } = useCourses();
  const { data: user } = useUser();

  const userGoal = user?.preferences?.goal;

  const is30DayChallenge = userGoal === '30_days_challenge';

  if (is30DayChallenge) {
    return <MhmJourneyChallengeSection />;
  }

  const userCourse =
    userGoal != null && userGoal !== 'other'
      ? coursesData?.courses[userGoal]
      : null;

  if (userCourse != null) {
    return <MhmJourneySection course={userCourse} />;
  }

  return null;
}

export default YourJourney;
