/** @jsxImportSource theme-ui */
import React from 'react';
import { Link } from 'gatsby';
import { Box, ThemeUIStyleObject } from 'theme-ui';
import Text from '../Text/Text';
import Button from '../Button/Button';
import ExplainerSectionTeaser from '../../../assets/explainerSectionTeaser.png';

export interface VisualFeedbackInfoBoxProps {
  className?: string;
  sx?: ThemeUIStyleObject;
}

const VisualFeedbackInfoBox: React.FC<VisualFeedbackInfoBoxProps> = ({
  className = '',
  sx,
}: VisualFeedbackInfoBoxProps) => (
  <Box
    className={className}
    sx={{
      position: 'relative',
      boxShadow: '0px 10px 16px 0px rgba(0,0,0,0.10)',
      borderRadius: 0,
      backgroundColor: 'white',
      backgroundImage: `url(${ExplainerSectionTeaser})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center right',
      paddingTop: '100%',
      ...sx,
    }}
  >
    <Box
      sx={{
        position: 'absolute',
        zIndex: 0,
        top: 0,
        left: 0,
        width: '100%',
        height: '188px',
        borderRadius: 0,
        background:
          'linear-gradient(-180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%)',
      }}
    />
    <Box
      sx={{
        position: 'absolute',
        zIndex: 0,
        bottom: 0,
        left: 0,
        width: '100%',
        height: '164px',
        borderRadius: 0,
        background:
          'linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%)',
      }}
    />

    <Text
      variant="h2"
      sx={{
        position: 'absolute',
        zIndex: 1,
        top: 0,
        left: 0,
        width: '100%',
        color: 'white',
        m: 0,
        px: 5,
        pt: 8,
        pb: 0,
        maxWidth: ['350px', '440px'],
        fontSize: [3, 4],
      }}
    >
      Visuelles Feedback – So funktioniert&apos;s
    </Text>

    <Button
      variant="white"
      sx={{
        position: 'absolute',
        bottom: [4, 6],
        left: '50%',
        maxWidth: 'calc(100% - 40px)',
        transform: 'translateX(-50%)',
      }}
    >
      Mehr erfahren
    </Button>

    <Link
      to="/app/how-it-works"
      sx={{
        display: 'block',
        position: 'absolute',
        zIndex: 5,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    />
  </Box>
);

export default VisualFeedbackInfoBox;
