import React from 'react';
import { Flex, Link } from 'theme-ui';
import { RouteComponentProps } from '@reach/router';

import { useUser } from '@youga/youga-client-api';

import useAuth from '../../../hooks/useAuth';
import useSubscriptionStatus from '../../../hooks/useSubscriptionStatus';

import Header from '../../02_molecules/Header/Header';
import Text from '../../01_atoms/Text/Text';
import Button from '../../01_atoms/Button/Button';
import Container from '../../01_atoms/Container/Container';
import Spinner from '../../01_atoms/Spinner/Spinner';
import styled from 'styled-components';

const Space = styled.div`
  height: 10rem;
`;

const planName = (productId?: string) => {
  switch (productId) {
    case 'yuuyoga_1_m_standard':
      return '1 Monat';
    case 'yuuyoga_3_m_standard':
      return '3 Monate';
    case 'yuuyoga_1_y_standard':
      return '12 Monate';
    default:
      return 'unknown';
  }
};

const MyAccountSection: React.FC<RouteComponentProps> = () => {
  const { handleSignOut } = useAuth();
  const { data: user, isValidating } = useUser();
  const { fullAppAccess } = useSubscriptionStatus();

  const headingStyles = {
    color: 'grey3',
    display: 'block',
    fontWeight: 'bold',
    fontSize: '12px',
    marginTop: 5,
  };

  const formatDate = (isoDate?: string | number): string => {
    if (!isoDate) return '';
    const zeroFill = (input: number): string => {
      if (input < 10) return `0${input}`;
      return `${input}`;
    };
    const date = new Date(isoDate);
    return `${zeroFill(date.getDate())}.${zeroFill(
      date.getMonth() + 1,
    )}.${date.getFullYear()}`;
  };

  if (user == null && isValidating) {
    return (
      <Container>
        <Header showButton="back" theme="light" />
        <Spinner fullscreen />
      </Container>
    );
  }

  return (
    <>
      <Header showButton="back" theme="light" />
      <Container>
        <Text
          variant="h2"
          sx={{
            fontSize: [2, 4],
            mt: [3, 7],
            mb: [7, 12],
            display: 'block',
          }}
          highlighted
        >
          Mein Account
        </Text>
        <Text variant="heading" sx={headingStyles}>
          Deine Email-Adresse
        </Text>
        <Text>{user?.email}</Text>
        <Text variant="heading" sx={headingStyles}>
          Angemeldet seit
        </Text>
        <Text>{formatDate(user?.stats?.joinDate)}</Text>
        <Text variant="heading" sx={headingStyles}>
          Dein aktuelles Abonnement:
        </Text>
        <Text>{`${planName(
          fullAppAccess?.product_identifier,
        )} (gekauft am ${formatDate(fullAppAccess?.purchase_date)})`}</Text>

        <Flex
          style={{
            flexDirection: 'column',
            maxWidth: 'max-content',
            alignItems: 'center',
          }}
        >
          <Button
            variant="primary"
            sx={{
              marginTop: 5,
            }}
            onClick={(): void => {
              handleSignOut();
            }}
          >
            Ausloggen
          </Button>

          <Link
            style={{
              cursor: 'pointer',
              marginTop: 24,
              fontFamily: 'Montserrat, sans-serif',
              fontWeight: 'normal',
            }}
            onClick={(e) => {
              window.location.href =
                'mailto:info@yuuxyoga.de?subject=Ich möchte meinen Account löschen';
              e.preventDefault();
            }}
          >
            {'Ich möchte meinen Account löschen'}
          </Link>
        </Flex>

        <Space />
      </Container>
    </>
  );
};

export default MyAccountSection;
