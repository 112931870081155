import React, { SyntheticEvent } from 'react';

import { Link } from 'gatsby';
import { Image } from '@theme-ui/components';

import { AuthVariant } from './AuthBox';
import Button from '../../01_atoms/Button/Button';
import Checkbox from '../../01_atoms/Checkbox/Checkbox';
import IconApple from '../../../assets/icons/iconApple.svg';
import IconFb from '../../../assets/icons/iconFb.svg';
import IconGoogle from '../../../assets/icons/iconGoogle.svg';
import PasswordInput from '../../01_atoms/PasswordInput/PasswordInput';
import Text from '../../01_atoms/Text/Text';
import TextInput from '../../01_atoms/TextInput/TextInput';

type SignUpFormProps = {
  errorInformation: null | {
    position: string;
    element: React.ReactNode;
  };
  onClickFacebookLogin: (e: SyntheticEvent<HTMLButtonElement>) => void;
  onClickGoogleLogin: (e: SyntheticEvent<HTMLButtonElement>) => void;
  onClickAppleLogin: (e: SyntheticEvent<HTMLButtonElement>) => void;
  setEmail: (v: string) => void;
  setPassword: (v: string) => void;
  setCurrentVariant: (variant: AuthVariant) => void;
  conditionsAccepted: boolean;
  setConditionsAccepted: (v: boolean) => void;
  newsletter: string;
  setNewsletter: (v: string) => void;
  setCode: (v: string) => void;
  confirmCode?: string;
};

const Style = {
  socialMediaButton: {
    cursor: 'pointer',
    marginTop: '0.5rem',
    backgroundColor: 'rgb(243, 243, 243)',
    flexDirection: 'row' as const,
    display: 'flex',
    alignItems: 'center',
  },
  buttonIcon: {},
  buttonText: {
    fontWeight: 'bold',
    marginLeft: 2,
  },
  loginButton: {
    cursor: 'pointer',
    marginTop: '3rem',
    // width: '12rem',
    display: 'block',
    alignSelf: 'flex-end',
  },
};

export default function SignUpForm({
  errorInformation,
  onClickFacebookLogin,
  onClickGoogleLogin,
  onClickAppleLogin,
  setEmail,
  setPassword,
  setCurrentVariant,
  conditionsAccepted,
  setConditionsAccepted,
  newsletter,
  setNewsletter,
  setCode,
  confirmCode,
}: SignUpFormProps) {
  return (
    <React.Fragment key="signup-submit-fragment">
      <Button
        disabled={!conditionsAccepted}
        onClick={onClickGoogleLogin}
        style={Style.socialMediaButton}
      >
        <Image style={Style.buttonIcon} src={IconGoogle} />
        <Text sx={Style.buttonText}>Mit Google Einloggen</Text>
      </Button>

      <Button
        disabled={!conditionsAccepted}
        onClick={onClickFacebookLogin}
        style={Style.socialMediaButton}
      >
        <Image style={Style.buttonIcon} src={IconFb} />
        <Text sx={Style.buttonText}>Mit Facebook Einloggen</Text>
      </Button>

      <Button
        disabled={!conditionsAccepted}
        onClick={onClickAppleLogin}
        style={Style.socialMediaButton}
      >
        <Image style={Style.buttonIcon} src={IconApple} />
        <Text sx={Style.buttonText}>Mit Apple Einloggen</Text>
      </Button>

      <Text
        sx={{
          textAlign: 'center',
          margin: '2rem',
          color: 'rgb(98, 102, 105)',
          fontSize: '16px',
        }}
      >
        {'oder'}
      </Text>

      <TextInput
        id="email"
        type="text"
        name="email"
        placeholder="E-Mail Adresse"
        required
        onChange={(e): void => {
          setEmail(e.target.value);
        }}
        error={
          errorInformation?.position === 'email' && errorInformation.element
        }
      />
      <PasswordInput
        id="password"
        name="password"
        onChange={setPassword}
        placeholder="Passwort"
        showValidation={true}
        required
        error={
          errorInformation?.position === 'password' && errorInformation.element
        }
      />

      <Button
        type="submit"
        style={{ cursor: 'pointer', display: 'block', marginTop: '3rem' }}
        disabled={!conditionsAccepted}
      >
        Kostenlos registrieren
      </Button>

      <Checkbox
        id="conditions"
        name="conditions"
        checked={conditionsAccepted}
        onChange={(e): void => {
          setConditionsAccepted(e.target.checked);
        }}
      >
        Ich stimme den{' '}
        <Link target="_blank" to="/terms-of-use">
          Nutzungsbedinungen
        </Link>{' '}
        zu und nehme die{' '}
        <Link target="_blank" to="/privacy-policy">
          Datenschutzbestimmungen
        </Link>{' '}
        zur Kenntnis.
      </Checkbox>

      <Checkbox
        id="newsletter"
        name="newsletter"
        checked={newsletter === 'true'}
        onChange={(e): void => {
          setNewsletter(e.target.checked ? 'true' : 'false');
        }}
      >
        Ich möchte E-Mails zu Neuigkeiten von Yuu Yoga erhalten und erteile
        meine jederzeit widerrufbare Einwilligung in die Nutzung meiner Daten zu
        diesem Zweck.
      </Checkbox>

      {errorInformation?.position === 'default' && errorInformation.element}

      <TextInput
        id="code"
        type="text"
        name="code"
        onChange={(e): void => {
          setCode(e.target.value);
        }}
        required
        placeholder="Code aus der E-Mail"
        defaultValue={confirmCode}
        error={
          errorInformation?.position === 'code' && errorInformation.element
        }
      />

      {errorInformation?.position === 'default' && errorInformation.element}

      <Button
        type="submit"
        style={{
          cursor: 'pointer',
          display: 'block',
          marginTop: '3rem',
        }}
      >
        Bestätigen
      </Button>

      <Button
        type="button"
        variant="text"
        style={{ marginTop: '2rem' }}
        onClick={(): void => setCurrentVariant('signin')}
      >
        Du hast schon einen Account?
      </Button>
    </React.Fragment>
  );
}
