import { Text } from 'theme-ui';

import DefaultPageLayout from '../components/04_templates/DefaultPageLayout';

function NotFoundPage() {
  return (
    <DefaultPageLayout title="Nutzungsbedingungen">
      <p>
        Auf der Webseite www.yuuxyoga.de („
        <strong>
          <i>Webseite</i>
        </strong>
        ”) sowie mit mit mit mit mit mittels einer Applikation („
        <strong>
          <i>App</i>
        </strong>
        ”) bietet die Initium GmbH, Stralauer Allee 12, 10245 Berlin,
        Deutschland („
        <strong>
          <i>Wir</i>
        </strong>
        “) eine Plattform, die es dem Nutzer („
        <strong>
          <i>Du</i>
        </strong>
        “) im Rahmen des nachfolgend beschriebenen Leistungsumfang ermöglicht,
        Workouts mit Übungsanleitungen verschiedener Trainer anzusehen und diese
        im Rahmen Deines eigenen Workouts zu wiederholen („
        <strong>
          <i>YuuYoga</i>
        </strong>
        “). Eine Nutzung von YuuYoga setzt voraus, dass Du diesen
        Nutzungsbedingungen zustimmst, die Gegenstand des zwischen uns und Dir
        geschlossenen Nutzungsvertrags sind. Lies daher diese
        Nutzungsbedingungen sorgfältig durch und mache Dich mit den hierin
        vorgesehenen Regelungen vertraut.
      </p>
      <h4>1. Leistungsgegenstand von YuuYoga</h4>
      <Text sx={{ float: 'left' }}> 1.1. </Text>
      <Text sx={{ ml: '2rem' }}>
        {' '}
        YuuYoga bietet Dir die Möglichkeit, auf eine Bibliothek von
        Video-Inhalten wie Yoga Workouts, Übungen und Trainings („
        <b>
          <i>Workouts</i>
        </b>
        “) von ausgewählten Lehrern („
        <b>
          <i>Trainer</i>
        </b>
        “) zuzugreifen und während und nach einer durchgeführten Einheit
        personalisiertes Feedback über die Art der Ausführung zu erhalten.{' '}
      </Text>
      <Text sx={{ float: 'left' }}> 1.2. </Text>
      <Text sx={{ ml: '2rem' }}>
        {' '}
        Das personalisierte Feedback, erfolgt dergestalt, dass Du über das
        Aufstellen und Verknüpfen von Kameras Deines Endgerätes (z.B.
        Smartphone, Tablet, Laptop etc.) einen virtuellen Spiegel angezeigt
        bekommst („
        <b>
          <i>Smart Mirror</i>
        </b>
        “) und so während und nach einer Session einen direkten Vergleich Deiner
        Ausführung von Übungen zu der Ausführung der Trainer erhältst.{' '}
      </Text>
      <Text sx={{ float: 'left' }}> 1.3. </Text>
      <Text sx={{ ml: '2rem' }}>
        {' '}
        Die Nutzung von YuuYoga erfordert, dass Du gegebenenfalls weitere
        Verträge mit Dritten schließen bzw. geschlossen hast (z.B. mit Deinem
        Mobilfunkanbieter); daraus entstehende Kosten – insbesondere für in
        Anspruch genommenes Datenvolumen – hast Du zu tragen.{' '}
      </Text>
      <Text sx={{ float: 'left' }}> 1.4. </Text>
      <Text sx={{ ml: '2rem' }}>
        {' '}
        Zur vollumfänglichen Nutzung von YuuYoga werden ggf. Hilfsmittel wie ein
        Endgerät oder Trainingsgeräte benötigt. Diese sind nicht Teil des
        YuuYoga Angebots und müssen von Dir gegebenenfalls separat und auf
        eigene Kosten erworben werden.{' '}
      </Text>
      <h4> 2. Voraussetzungen für die Nutzung von YuuYoga</h4>
      <Text sx={{ float: 'left' }}> 2.1. </Text>
      <Text sx={{ ml: '2rem' }}>
        {' '}
        Du kannst YuuYoga nutzen, wenn Du eine natürliche Person bist, die das
        18. Lebensjahr vollendet hat. Bitte beachte außerdem, dass für die
        Nutzung von YuuYoga erforderlich ist, dass Du über ein Apple Endgerät
        mit mindestens dem Betriebssystem IOS 8 verfügst.{' '}
      </Text>
      <Text sx={{ float: 'left' }}> 2.2. </Text>
      <Text sx={{ ml: '2rem' }}>
        {' '}
        Soweit Du Dich für ein kostenpflichtiges Abonnements entscheidest, ist
        die Zahlung der Vergütung an uns für die weitere Nutzung von Yuu Yoga
        erforderlich.. Die Einrichtung eines Nutzerkontos erfolgt durch Angabe
        einer E-Mail-Adresse und{' '}
      </Text>
      <Text sx={{ float: 'left', ml: '2rem' }}> a) </Text>
      <Text sx={{ ml: '4rem' }}> der Festlegung eines Passworts oder </Text>
      <Text sx={{ float: 'left', ml: '2rem' }}> b) </Text>
      <Text sx={{ ml: '4rem' }}>
        {' '}
        der Verwendung eines Nutzerprofils bei einem von Dritten betriebenen
        (sozialen) Netzwerk, wie etwa Facebook, Google oder LinkedIn („
        <b>
          <i>Social Login</i>
        </b>
        “); sofern Du Dich mittels Social Login bei YuuYoga registrieren
        möchtest, wirst Du auf die Login-/Anmelde-Maske des Drittanbieters
        weitergeleitet und kannst Dich dort mit Deinem bei diesem Drittanbieter
        hinterlegten Benutzernamen und Passwort anmelden („
        <b>
          <i>Zugangsdaten</i>
        </b>
        “).{' '}
      </Text>
      <Text sx={{ float: 'left' }}> 2.3. </Text>
      <Text sx={{ ml: '2rem' }}>
        {' '}
        Du hast dafür Sorge zu tragen, dass die verwendeten Zugangsdaten vor dem
        Zugriff Dritter geschützt sind und sorgfältig aufbewahrt werden. Sofern
        Du Grund zu der Annahme hast, dass die Vertraulichkeit Deines Passworts
        beeinträchtigt wurde oder unberechtigter Zugriff auf Dein Nutzerkonto
        auf andere Weise möglich ist, verpflichtest Du Dich, unverzüglich Dein
        Passwort zu ändern und Uns zu benachrichtigen.{' '}
      </Text>
      <Text sx={{ float: 'left' }}> 2.4. </Text>
      <Text sx={{ ml: '2rem' }}>
        {' '}
        Wir haben das Recht, von Zeit zu Zeit Änderungen des Passworts
        anzufordern. Wir können den Zugriff auf das Nutzerkonto sperren, bis
        eine solche Änderung des Passworts vorgenommen wurde.{' '}
      </Text>
      <Text sx={{ float: 'left' }}> 2.5. </Text>
      <Text sx={{ ml: '2rem' }}>
        {' '}
        Nach Einrichtung des Nutzerkontos kannst Du zudem einen Benutzernamen
        festlegen. Hinsichtlich der Wahl des Benutzernamens verpflichtest Du
        dich dazu, die berechtigten Interessen Dritter zu wahren und keinen
        Benutzernamen zu wählen, der beleidigend, anstößig, diskriminierend,
        Gewalt verherrlichend, verleumderisch oder irreführend ist, eine
        gesetzeswidrige Handlung fördert oder unterstützt oder sonst gegen
        geltendes Recht verstößt. Bei einem Verstoß gegen diese Grundsätze
        behalten Wir Uns vor, Dich auch nachträglich zur Änderung Deines
        Benutzernamens aufzufordern. In diesem Fall können Wir Deinen Zugang zum
        Nutzerkonto gemäß Ziffer 6 sperren, bis eine Änderung des Benutzernamens
        vollzogen ist.{' '}
      </Text>
      <Text sx={{ float: 'left' }}> 2.6. </Text>
      <Text sx={{ ml: '2rem' }}>
        {' '}
        Im Rahmen von YuuYoga werden Dir Übungsanleitungen gezeigt (z.B. für
        bestimmte Übungen und Trainings). Diese Anleitungen müssen in jedem Fall
        durch Dich eingehalten werden, da ansonsten Verletzungs- und oder
        Gesundheitsrisiken bestehen.{' '}
      </Text>
      <Text sx={{ float: 'left' }}> 2.7. </Text>
      <Text sx={{ ml: '2rem' }}>
        {' '}
        Wir weisen Dich darauf hin, dass Yuu Yoga nicht deine individuelle
        Sportlichkeit, Beweglichkeit oder Belastungsgrenze ermittelt. Auch ist
        Uns Dein Gesundheitszustand nicht bekannt. Deshalb erfolgt die
        Durchführung der Sessions bei YuuYoga auf eigenes Risiko. Bitte stelle
        daher sicher, dass Du YuuYoga nur nutzt, wenn Du über einen guten
        körperlichen und gesundheitlichen Zustand verfügst. Wir möchten Dich
        hinweisen, dass YuuYoga nicht genutzt werden sollte, wenn Du
        beispielsweise starke Schmerzen, allgemeines Unwohlsein, Kurzatmigkeit,
        Übelkeit oder Schwindel verspürst. Sollten bereits bei Dir
        Vorerkrankungen bestehen oder bekannt sein, so hole bitte vor der
        Nutzung von YuuYoga ärztlichen Rat ein und befolge diese Empfehlungen.
        Dies gilt insbesondere, wenn Du unter Herz-/Kreislauferkrankungen,
        Wirbelsäulen, Gelenkproblemen, und/oder anderen Einschränkungen
        gesundheitlicher Art leidest, die Deine sportliche Leistungsfähigkeit
        einschränken.{' '}
      </Text>
      <h4>3. Verfügbarkeit, Funktionalitäten und Anpassungen</h4>
      <Text sx={{ float: 'left' }}> 3.1. </Text>
      <Text sx={{ ml: '2rem' }}>
        {' '}
        Wir bieten die Möglichkeit zur Nutzung von YuuYoga nur mit den
        wesentlichen Funktionalitäten, welche in Ziffer 1 beschrieben sind.
        Hiervon ausgenommen sind unwesentliche Funktionalitäten sowie Zeiträume
        für geplante Wartungen, Updates, Upgrades etc.{' '}
      </Text>
      <Text sx={{ float: 'left' }}> 3.2. </Text>
      <Text sx={{ ml: '2em' }}>
        {' '}
        Wir sind berechtigt, YuuYoga und seine Funktionalitäten jederzeit und
        ohne Vorankündigung anzupassen („
        <b>
          <i>Anpassungen</i>
        </b>
        “), vorausgesetzt dass YuuYoga den in Ziffer 1 beschriebenen
        Leistungsumfang weiterhin grundsätzlich beibehält und solche Anpassungen
        Dir zumutbar sind. Zu Anpassungen sind Wir allerdings nicht
        verpflichtet. Anpassungen können dazu führen, dass Du zur Weiternutzung
        von YuuYoga eine aktuelle Version der App installieren musst oder dass
        ein bisher genutztes Endgerät zur Nutzung von YuuYoga nicht mehr
        geeignet ist.{' '}
      </Text>
      <h4>4. Geistiges Eigentum und andere Schutzrechte</h4>
      <Text sx={{ float: 'left' }}> 4.1. </Text>
      <Text sx={{ ml: '2em' }}>
        {' '}
        Wir behalten Uns an YuuYoga und den im Rahmen von YuuYoga dargestellten
        Inhalten sämtliche Urheber- und sonstigen Schutzrechte vor, soweit uns
        diese zustehen. Du darfst YuuYoga und die darin dargestellten Inhalte
        nur für eigene persönliche und nicht-kommerzielle Zwecke verwenden. Jede
        Nutzung von YuuYoga und den darin dargestellten Inhalten für
        kommerzielle Zwecke (z.B. zur eigenen Gewinnerzielung) ist untersagt,
        soweit Wir nicht im Einzelfall schriftlich zugestimmt haben.{' '}
      </Text>
      <Text sx={{ float: 'left' }}> 4.2. </Text>
      <Text sx={{ ml: '2em' }}>
        {' '}
        Hinweise und Angaben zu Urheberrechten, Markenrechten oder anderen
        Rechten geistigen Eigentums an YuuYoga und den Inhalten dürfen nicht
        verändert oder entfernt werden.{' '}
      </Text>
      <h4>5. Vertragsdauer und Kündigung</h4>
      <Text sx={{ float: 'left' }}> 5.1. </Text>
      <Text sx={{ ml: '2rem' }}>
        {' '}
        Der zwischen Dir und Uns geschlossene Nutzungsvertrag läuft für eine
        unbestimmte Zeit.{' '}
      </Text>
      <Text sx={{ float: 'left' }}> 5.2. </Text>
      <Text sx={{ ml: '2rem' }}>
        {' '}
        Du kannst den Nutzungsvertrag jederzeit ohne Einhaltung einer
        Kündigungsfrist sowie ohne Vorliegen und Angabe eines Grundes kündigen.
        Dies kann auch durch Löschung Deines Nutzerkontos erfolgen. Bei
        Abschluss eines kostenpflichtigen Abonnements ist, abweichend von Satz 1
        und 2, eine Kündigung erst zum Ablauf der Mindestvertragslaufzeit des
        jeweiligen Abonnements möglich..{' '}
      </Text>
      <Text sx={{ float: 'left' }}> 5.3. </Text>
      <Text sx={{ ml: '2rem' }}>
        {' '}
        Wir können den Nutzungsvertrag nur unter Einhaltung einer angemessenen
        Frist ordentlich kündigen.{' '}
      </Text>
      <Text sx={{ float: 'left' }}> 5.4. </Text>
      <Text sx={{ ml: '2rem' }}>
        {' '}
        Das Recht jeder Partei zur außerordentlichen fristlosen Kündigung aus
        wichtigem Grund sowie Unser Recht zur Sperrung Deines Zugriffs auf Dein
        Nutzerkonto bleiben unberührt.{' '}
      </Text>
      <Text sx={{ float: 'left' }}> 5.5. </Text>
      <Text sx={{ ml: '2rem' }}>
        Ab Wirksamwerden einer Kündigung löschen Wir Dein Nutzerkonto
      </Text>
      <h4>6. Sperrung des Nutzerkontos</h4>
      <Text sx={{ float: 'left' }}> 6.1. </Text>
      <Text sx={{ ml: '2rem' }}>
        {' '}
        Wir können Dir den Zugriff auf Dein Nutzerkonto vorübergehend sperren,
        wenn Wir feststellen oder begründeten Verdacht haben, dass Deine Nutzung
        von YuuYoga gegen diese Nutzungsbedingungen und/oder gegen geltendes
        Recht verstößt,{' '}
      </Text>
      <Text sx={{ float: 'left', ml: '2rem' }}> a) </Text>
      <Text sx={{ ml: '4rem' }}> Rechte Dritter verletzt, </Text>
      <Text sx={{ float: 'left', ml: '2rem' }}> b) </Text>
      <Text sx={{ ml: '4rem' }}>
        {' '}
        ein Sicherheitsrisiko für die Funktionsfähigkeit von YuuYoga darstellt,{' '}
      </Text>
      <Text sx={{ float: 'left', ml: '2rem' }}> c) </Text>
      <Text sx={{ ml: '4rem' }}>
        {' '}
        YuuYoga und/oder andere Nutzer einer Haftung unterwerfen könnte, oder{' '}
      </Text>
      <Text sx={{ float: 'left', ml: '2rem' }}> d) </Text>
      <Text sx={{ ml: '4rem' }}> betrügerisch sein könnte. </Text>
      <Text sx={{ float: 'left' }}> 6.2. </Text>
      <Text sx={{ ml: '2rem' }}>
        {' '}
        Wir behalten Uns eine Sperrung Deines Zugriffs auf Dein Nutzerkonto auch
        dann vor, wenn Du einer zumutbaren Änderung dieser Nutzungsbedingungen
        gemäß Ziffer 10.1 bis zum geplanten Termin von deren Wirksamwerden nicht
        zugestimmt hast.{' '}
      </Text>
      <Text sx={{ float: 'left' }}> 6.3. </Text>
      <Text sx={{ ml: '2rem' }}>
        {' '}
        Wir werden die Sperrung aufheben, sobald der Grund der Sperrung nicht
        länger fortbesteht. Zu diesem Zweck kannst Du Uns gerne unter{' '}
        <b>
          <a href="mailto:infoa@yuuxyoga.de">infoa@yuuxyoga.de</a>
        </b>{' '}
        kontaktieren, um eine Klärung herbeizuführen.{' '}
      </Text>
      <Text sx={{ float: 'left' }}> 6.4. </Text>
      <Text sx={{ ml: '2rem' }}>
        {' '}
        Weitergehende Ansprüche gegen Dich bleiben unberührt.{' '}
      </Text>
      <h4>7. Haftungsbeschränkung</h4>
      <Text sx={{ float: 'left' }}> 7.1. </Text>
      <Text sx={{ ml: '2rem' }}>
        {' '}
        Wir haften – gleich aus welchem Rechtsgrund – uneingeschränkt{' '}
      </Text>
      <Text sx={{ float: 'left', ml: '2rem' }}> a) </Text>
      <Text sx={{ ml: '4rem' }}> bei Vorsatz oder grober Fahrlässigkeit, </Text>
      <Text sx={{ float: 'left', ml: '2rem' }}> b) </Text>
      <Text sx={{ ml: '4rem' }}>
        {' '}
        bei vorsätzlicher oder fahrlässiger Verletzung des Lebens, des Körpers
        oder der Gesundheit,{' '}
      </Text>
      <Text sx={{ float: 'left', ml: '2rem' }}> c) </Text>
      <Text sx={{ ml: '4rem' }}>
        {' '}
        bei Fehlen einer garantierten Beschaffenheit oder bei arglistig
        verschwiegenen Mängeln, sowie{' '}
      </Text>
      <Text sx={{ float: 'left', ml: '2rem' }}> d) </Text>
      <Text sx={{ ml: '4rem' }}>
        {' '}
        aufgrund zwingender Haftung wie etwa nach dem Produkthaftungsgesetz.{' '}
      </Text>
      <Text sx={{ float: 'left' }}> 7.2. </Text>
      <Text sx={{ ml: '2rem' }}>
        {' '}
        Bei fahrlässig verursachten Sach- und Vermögensschäden ist Unsere
        Haftung beschränkt auf Fälle der Verletzung einer wesentlichen
        Vertragspflicht, sowie der Höhe nach auf den vertragstypischen,
        vorhersehbaren Schaden begrenzt, soweit gemäß vorstehender Ziffer 7.1
        keine unbeschränkte Haftung besteht. Wesentliche Vertragspflichten sind
        Pflichten, die der Nutzungsvertrag Uns seinem Inhalt nach zur Erreichung
        des Vertragszwecks auferlegt, deren Erfüllung die ordnungsgemäße
        Durchführung des Vertrags überhaupt erst ermöglicht und auf deren
        Einhaltung Du als Nutzer regelmäßig vertrauen darfst.{' '}
      </Text>
      <Text sx={{ float: 'left' }}> 7.3. </Text>
      <Text sx={{ ml: '2rem' }}>
        {' '}
        Im Übrigen ist eine Haftung von Uns ausgeschlossen.{' '}
      </Text>
      <Text sx={{ float: 'left' }}> 7.4. </Text>
      <Text sx={{ ml: '2rem' }}>
        {' '}
        Vorstehende Haftungsregelungen gelten auch im Hinblick auf Unsere
        Haftung für Unsere Erfüllungsgehilfen, Mitarbeiter und gesetzlichen
        Vertreter.{' '}
      </Text>
      <Text sx={{ float: 'left' }}> 7.5. </Text>
      <Text sx={{ ml: '2rem' }}>
        {' '}
        Gesetzliche Gewährleistungsansprüche werden durch diese
        Haftungsregelungen nicht berührt.{' '}
      </Text>
      <h4>8. Datenschutz</h4>
      <p>
        Die Verarbeitung personenbezogener Daten durch Uns in Bezug auf YuuYoga
        richtet sich nach Unserer Datenschutzerklärung, die Du hier einsehen
        kannst:{' '}
        <b>
          <a href="mailto:infoa@yuuxyoga.de">infoa@yuuxyoga.de</a>
        </b>
        .
      </p>
      <h4>9. Alternative Streitbeilegung</h4>
      <p>
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
        vor einer Verbraucherschlichtungsstelle teilzunehmen.
      </p>

      <h4>10. Schlussvorschriften</h4>
      <Text sx={{ float: 'left' }}> 10.1. </Text>
      <Text sx={{ ml: '3rem' }}>
        {' '}
        Anpassungen von YuuYoga sowie Änderungen gesetzlicher Rahmenbedingungen
        können es erforderlich machen, dass Wir diese Nutzungsbedingungen
        ändern. Wir werden Dich im Fall einer geplanten Änderung dieser
        Nutzungsbedingungen über den Inhalt der Änderung mindestens vier (4)
        Wochen vor dem geplanten Termin des Wirksamwerdens informieren; die
        geänderten Nutzungsbedingungen werden Dir gegenüber in diesem Fall nur
        wirksam, wenn Du den Änderungen zugestimmt hast.{' '}
      </Text>
      <Text sx={{ float: 'left' }}> 10.2. </Text>
      <Text sx={{ ml: '3rem' }}>
        {' '}
        Du bist nicht berechtigt, den Nutzungsvertrag ohne vorherige
        schriftliche Zustimmung von Uns an Dritte zu übertragen.{' '}
      </Text>
      <Text sx={{ float: 'left' }}> 10.3. </Text>
      <Text sx={{ ml: '3rem' }}>
        {' '}
        Der Nutzungsvertrag unterliegt dem Recht der Bundesrepublik Deutschland
        unter Ausschluss des UN-Kaufrechts. Gerichtsstand für
        vermögensrechtliche Streitigkeiten aus oder im Zusammenhang mit diesem
        Nutzungsvertrag ist Stuttgart, sofern Du als zu verklagende Partei
        Deinen Wohnsitz oder gewöhnlichen Aufenthalt an einen Ort außerhalb der
        Bundesrepublik Deutschland verlegt hast oder Uns Dein Wohnsitz oder
        gewöhnlicher Aufenthalt im Zeitpunkt der Klageerhebung nicht bekannt
        ist.{' '}
      </Text>
    </DefaultPageLayout>
  );
}

export default NotFoundPage;
