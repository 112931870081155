import React, { useRef, useEffect, useState } from 'react';
import { Image, Label, Radio, Box } from 'theme-ui';
import debounce from 'lodash/debounce';
import OverlayModal from '../../01_atoms/OverlayModal/OverlayModal';
import Button from '../../01_atoms/Button/Button';
import Text from '../../01_atoms/Text/Text';
import IllustrationPoseDelete from '../../../assets/images/IllustrationPoseDelete.svg';

interface DeletePoseComparisonOverlayProps {
  visible: boolean;
  onClose: () => void;
  onConfirm: (reason: string) => Promise<void>;
}

const DeletePoseComparisonOverlay: React.FC<DeletePoseComparisonOverlayProps> =
  ({ visible, onClose, onConfirm }: DeletePoseComparisonOverlayProps) => {
    const [containerHeights, setContainerHeights] = useState<{
      [key: string]: number;
    }>({});
    const [checkedAnswer, setCheckedAnswer] = useState<string | null>(null);
    const [confirmDisabled, setConfirmDisabled] = useState(false);

    const timingContainerRef = useRef<HTMLDivElement | null>(null);
    const qualityContainerRef = useRef<HTMLDivElement | null>(null);
    const comfortContainerRef = useRef<HTMLDivElement | null>(null);
    const privacyContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      const updateHeights = (): void => {
        setContainerHeights({
          timing:
            timingContainerRef.current?.getBoundingClientRect()?.height || 0,
          quality:
            qualityContainerRef.current?.getBoundingClientRect()?.height || 0,
          comfort:
            comfortContainerRef.current?.getBoundingClientRect()?.height || 0,
          privacy:
            privacyContainerRef.current?.getBoundingClientRect()?.height || 0,
        });
      };

      const debounced = debounce(updateHeights, 300);

      window.addEventListener('resize', debounced);
      updateHeights();

      return (): void => {
        window.removeEventListener('resize', debounced);
      };
    }, []);

    const questions = [
      {
        id: 'timing',
        ref: timingContainerRef,
        headline: 'Ich kann mich nicht richtig sehen / falsches Timing.',
        answer:
          'Das das Timing manchmal nicht stimmt kann passieren. Über die Serienbild-Aufnahme hast du aber die einfache Möglichkeit den optimalen Zeitpunkt selbst zu bestimmen. Nutze hierfür einfach die Pfeile links unten im Bild. Sollte in der Serie immer noch keine passende Comparison dabei sein, versuche den selben Flow mehrere Male zu machen, um mit dem Flow der Asanas noch vertrauter zu werden.',
      },
      {
        id: 'quality',
        ref: qualityContainerRef,
        headline: 'Die Bildqualität ist nicht ausreichend.',
        answer:
          'Die Qualität der Bilder hängt natürlich von verschiedenen Faktoren ab. Je nach Smartphone und Umgebung kann diese sehr unterschiedlich ausfallen. Wenn du mit der Bildqualität unzufrieden bist, versuche vielleicht bei der nächsten Session die Position deines Setups zu verändern. Achte zum Beispiel darauf, dass die Ausleuchtung gut ist (kein Gegenlicht, nicht zu dunkel) und nimm dir Zeit Dein Smartphone richtig zu positionieren.',
      },
      {
        id: 'comfort',
        ref: comfortContainerRef,
        headline: 'Ich fühle mich unwohl mit diesem Bild.',
        answer:
          'Keine Sorge. Die Pose Comparison ist nicht nur dafür da möglichst gut auszusehen, sondern soll dir dabei helfen besser zu werden und zu verstehen, wo du deine Ausführung noch verbessern kannst. Wenn du deine Posen über einen längeren Zeitraum sammelst helfen dir diese darüber hinaus noch durch “My Progress” deinen Fortschritt über die Zeit hinweg sichtbar zu machen.',
      },
      {
        id: 'privacy',
        ref: privacyContainerRef,
        headline: 'Ich möchte nicht, dass Bilder von mir gespeichert werden.',
        answer:
          'Natürlich kannst du Dein Bild löschen, allerdings musst du dir im Bezug auf deine Privatsphäre keine Sorgen machen. Deine Bilder sind bei Yuu Yoga 100% privat und sicher. Sie können von niemandem außer dir gesehen werden. Darüber hinaus hat das Thema Sicherheit bei uns oberste Priorität.',
      },
    ];

    const onCloseWrapped = (): void => {
      onClose();
      setCheckedAnswer(null);
    };

    return (
      <OverlayModal
        maxWidth={640}
        allowClose
        visible={visible}
        onClose={onCloseWrapped}
      >
        {(hide, rendered): React.ReactElement => (
          <>
            <Text
              variant="h2"
              sx={{
                fontSize: [3, 4],
                mt: 0,
                mb: [4, 6],
                zIndex: 0,
                '@media (max-width: 320px)': {
                  fontSize: 2,
                  mt: 4,
                },
              }}
            >
              Aus welchem Grund möchtest Du diese Pose löschen?
            </Text>

            <Box
              sx={{
                textAlign: 'center',
                mb: [4, 6],
                '@media (max-width: 320px)': {
                  display: 'none',
                },
              }}
            >
              <Image
                src={IllustrationPoseDelete}
                sx={{
                  margin: '0 auto',
                  width: '100%',
                  maxWidth: ['200px', '300px'],
                  transform: !rendered ? 'scale(0.7)' : 'scale(1)',
                  transition: 'transform ease-in 0.3s 0.1s',
                  '@media (max-height: 576px) and (orientation: landscape)': {
                    display: 'none',
                  },
                  '@media (max-width: 320px)': {
                    maxWidth: '150px',
                  },
                }}
              />
            </Box>

            {questions.map((question) => (
              <Box sx={{ mb: 4 }} key={question.id}>
                <Box sx={{ textAlign: 'left' }}>
                  <Label
                    sx={{
                      cursor: 'pointer',
                      position: 'relative',
                      pl: '40px',
                    }}
                  >
                    <Radio
                      name="delete-reason"
                      value={question.id}
                      checked={checkedAnswer === question.id}
                      onChange={(): void => {
                        setCheckedAnswer(question.id);
                      }}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: 0,
                        transform: 'translateY(-50%)',
                      }}
                    />
                    <Text
                      variant="h5"
                      sx={{
                        '@media (max-width: 320px)': {
                          fontSize: 0,
                        },
                      }}
                    >
                      {question.headline}
                    </Text>
                  </Label>
                </Box>

                <Box
                  sx={{
                    pt: 1,
                    overflow: 'hidden',
                    transition: 'max-height 0.1s ease-out',
                  }}
                  style={{
                    maxHeight:
                      checkedAnswer === question.id
                        ? containerHeights[question.id]
                        : 0,
                  }}
                >
                  <Box
                    sx={{ textAlign: 'left', pl: '40px' }}
                    ref={question.ref}
                  >
                    {question.answer}
                  </Box>
                </Box>
              </Box>
            ))}

            <Box
              sx={{
                mt: [6, 8],
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: ['column-reverse', 'row'],
              }}
            >
              <Button
                variant="white"
                onClick={(): void => {
                  hide(onCloseWrapped);
                }}
              >
                Abbrechen
              </Button>
              <Button
                variant="primary"
                disabled={confirmDisabled || !checkedAnswer}
                onClick={async (): Promise<void> => {
                  setConfirmDisabled(true);
                  await onConfirm(checkedAnswer || '');
                  hide(onCloseWrapped);
                  setConfirmDisabled(false);
                }}
              >
                Pose löschen
              </Button>
            </Box>
          </>
        )}
      </OverlayModal>
    );
  };

export default DeletePoseComparisonOverlay;
