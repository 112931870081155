import * as React from 'react';
import { Box, Image } from 'theme-ui';
import debounce from 'lodash/debounce';

import BtnCloseWhite from '../../../assets/btnCloseWhite.svg';

export interface VideoOverlayProps {
  onClose: () => void;
  onFinish?: () => void;
  onPause?: (e: React.SyntheticEvent<HTMLVideoElement, Event>) => void;
  onPlay?: (e: React.SyntheticEvent<HTMLVideoElement, Event>) => void;
  closeOnFinish?: boolean;
  visible: boolean;
  videoUrl: string;
  videoMaxWidth?: number;
}

const VideoOverlay: React.FC<VideoOverlayProps> = ({
  onClose,
  onFinish,
  onPause,
  onPlay,
  closeOnFinish = false,
  visible,
  videoUrl,
  videoMaxWidth,
}: VideoOverlayProps) => {
  const [viewportWidth, setViewportWidth] = React.useState<number | null>(null);

  React.useEffect(() => {
    const onResize = debounce((): void => {
      setViewportWidth(window.innerWidth);
    }, 100);

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', onResize);
      onResize();
    }

    return (): void => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  React.useEffect(() => {
    const onKeyDown = (e: KeyboardEvent): void => {
      if (e.key === 'Escape' || e.keyCode === 27) {
        onClose();
      }
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('keydown', onKeyDown);
    }

    return (): void => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('keydown', onKeyDown);
      }
    };
  }, [onClose]);

  const onEnded = (): void => {
    if (closeOnFinish) {
      onClose();
    }

    if (onFinish) {
      onFinish();
    }
  };

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'black',
          zIndex: 5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          transition: 'opacity ease-in 0.3s, transform ease-in 0.3s',
        }}
        style={{
          opacity: visible ? 1 : 0,
          transform: visible ? 'translateY(0)' : 'translateY(30px)',
          pointerEvents: visible ? 'all' : 'none',
        }}
      >
        {/* eslint-disable jsx-a11y/media-has-caption */}
        {visible && videoUrl && (
          <video
            autoPlay
            onPlay={onPlay}
            onEnded={onEnded}
            onPause={onPause}
            style={{ maxWidth: '100vw', maxHeight: '100vh', outline: 'none' }}
            controls
            controlsList="nodownload nofullscreen noremoteplayback noremoteplayback"
          >
            <source src={videoUrl} type="video/mp4" />
          </video>
        )}
        {/* eslint-enable jsx-a11y/media-has-caption */}
      </Box>
      <Image
        src={BtnCloseWhite}
        sx={{
          position: 'fixed',
          zIndex: 100,
          top: '20px',
          right:
            videoMaxWidth && viewportWidth && videoMaxWidth < viewportWidth - 20
              ? `calc((100vw - ${videoMaxWidth}px) / 2)`
              : '20px',
          cursor: 'pointer',
        }}
        onClick={onClose}
      />
    </>
  );
};

export default VideoOverlay;
