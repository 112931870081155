import * as React from 'react';
import { navigate } from 'gatsby';

import LoadingGate from '../components/03_organisms/LoadingGate/LoadingGate';
import useAuth from '../hooks/useAuth';

/**
 * Will be called as the following shows:
 * /callback#access_token=<value>&id_token=<value>&refresh_token=<value>
 *
 * The aws-amplify handles the query parameters. We just need to wait til the user is logged in

 * @see {@link https://github.com/aws-amplify/amplify-js/blob/main/packages/auth/src/OAuth/OAuth.ts#L217}
 *
 */
function CallbackPage() {
  const { isSignedIn } = useAuth();

  React.useEffect(() => {
    console.log('callback()', { isSignedIn });
    if (isSignedIn) {
      navigate(`/app`);
    }
  }, [isSignedIn]);

  return <LoadingGate />;
}

export default CallbackPage;
