import * as React from 'react';
import useSWR from 'swr';

import type { TrainingSession } from '@youga/youga-interfaces';

import { useYougaClientApi } from '../YougaClientApiProvider';

interface Props {
  revalidateOnMount?: boolean;
  revalidateOnFocus?: boolean;
  revalidateOnReconnect?: boolean;
  refreshInterval?: number;
}

export function useTrainingSessions(options?: Props) {
  const { userId } = useYougaClientApi();

  const {
    data: trainingSessions,
    mutate,
    isValidating,
  } = useSWR<TrainingSession[]>(
    userId ? `/training-session?userId=${userId}` : null,
    {
      dedupingInterval: 30000,
      ...options,
    },
  );

  return {
    isValidating,
    refresh: mutate,
    mutate,
    data:
      trainingSessions == null
        ? undefined
        : Array.isArray(trainingSessions)
        ? trainingSessions
        : [trainingSessions],
  };
}

export function useTrainingSession(id?: string) {
  const { api, token } = useYougaClientApi();

  const { data, mutate, ...others } = useSWR<TrainingSession>(
    id ? `/training-session?sessionId=${id}` : null,
  );

  const updateTrainingSession = React.useCallback(
    async function updateTrainingSession(newValues: TrainingSession) {
      if (id == null) {
        return;
      }
      if (token == null) {
        throw new Error(`Token is not defined.`);
      }

      const changedTs = await api.putTrainingSession(token, id, {
        ...data,
        ...newValues,
      });

      return mutate(changedTs, false);
    },
    [id, token, api, mutate],
  );

  const patchTrainingSession = React.useCallback(
    async function patchTrainingSession(
      newValues: Parameters<typeof api.patchTrainingSession>[2],
    ) {
      if (id == null) {
        return;
      }
      if (token == null) {
        throw new Error(`Token is not defined.`);
      }

      const changedTs = await api.patchTrainingSession(token, id, {
        ...newValues,
      });

      return mutate(changedTs, false);
    },
    [id, token, api, mutate],
  );

  const updateAnimationImage = React.useCallback(
    async function updateAnimationImage() {
      if (data == null) {
        return;
      }
      if (token == null) {
        throw new Error(`Token is not defined.`);
      }
      const newValues = await api.getTrainingSessionAnimation(token, data.id);
      await mutate(
        {
          ...data,
          animatedImage: newValues.animatedImage,
          animatedImageSize: newValues.animatedImageSize,
        },
        false,
      );
    },
    [api, mutate, data, token],
  );

  const deleteImage = React.useCallback(
    async function deleteImage(imageName: string) {
      if (id == null) {
        return;
      }
      if (token == null) {
        throw new Error(`Token is not defined.`);
      }
      const updatedSession = await api.deleteTrainingSessionImage(
        token,
        id,
        imageName,
      );
      return mutate(updatedSession, false);
    },
    [api, mutate, id, token],
  );

  return {
    ...others,
    data,
    mutate,
    updateTrainingSession,
    patchTrainingSession,
    updateAnimationImage,
    deleteImage,
  };
}
