/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { Box, Grid, Image } from 'theme-ui';
// eslint-disable-next-line import/no-extraneous-dependencies
import { RouteComponentProps } from '@reach/router';

import { useCourses, useInstructors } from '@youga/youga-client-api';
import { Course } from '@youga/youga-interfaces';

import Text from '../../01_atoms/Text/Text';
import Spinner from '../../01_atoms/Spinner/Spinner';
import Header from '../../02_molecules/Header/Header';
import useTracking from '../../../hooks/useTracking';
import isMobileCheck from '../../../utils/isMobileCheck';
import CourseItem from '../../01_atoms/CourseItem/CourseItem';
import Container from '../../01_atoms/Container/Container';

export type InstructorDetailSectionProps = RouteComponentProps<{
  instructorId: string;
}>;

const InstructorDetailSection = ({
  instructorId,
}: InstructorDetailSectionProps): React.ReactElement => {
  const { data: instructorsData, isValidating } = useInstructors();
  const { data: coursesData, isValidating: isValidatingCourses } = useCourses();
  const { track } = useTracking();
  const isMobile = isMobileCheck();
  const instructor = instructorsData?.instructors[instructorId || 'unknown'];

  // 1.- Track InstructorDetailSection
  useEffect(() => {
    track('instructor-detail-section', { instructorId });
  }, [instructorId, track]);

  if (instructorsData == null && isValidating) {
    return (
      <>
        <Header showButton="back" theme="light" />
        <Spinner fullscreen />
      </>
    );
  }

  if (coursesData == null && isValidatingCourses) {
    return (
      <>
        <Header showButton="back" theme="light" />
        <Spinner fullscreen />
      </>
    );
  }

  if (instructor == null) {
    return (
      <>
        <Header showButton="back" theme="light" />
        <Box sx={{ margin: '10rem auto', textAlign: 'center' }}>
          <Text>Fehler: Nicht gefunden. </Text>
        </Box>
      </>
    );
  }

  const sortedCourses = coursesData?.sortedCourses ?? [];

  const instructorCourses = sortedCourses
    .map((courseId) => coursesData?.courses[courseId])
    .filter((course) => course?.instructor === instructor.id) as Course[];

  return (
    <>
      <Header showButton="back" />

      <Box
        sx={{
          lineHeight: 1,
          position: 'relative',
          height: ['200px', '446px'],
        }}
      >
        <Image
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          src={isMobile ? instructor.thumbnail : instructor.posterImage}
        />

        <Image
          src={instructor.collabLogo}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '100%',
            maxWidth: ['300px', '513px'],
            transform: 'translate(-50%, -50%)',
          }}
        />
      </Box>

      <Container sx={{ mt: [5, 11] }}>
        <Text
          variant="h2light"
          sx={{ fontSize: [3, 4], mt: [3, 7], mb: [3, 4] }}
        >
          Über {instructor.name}
        </Text>

        <Box sx={{ maxWidth: '890px' }}>
          <Text>{instructor.description}</Text>
        </Box>

        <Text variant="h2light" sx={{ fontSize: [3, 4], mt: [5, 15], mb: 5 }}>
          Kurse von {instructor.name}
        </Text>
      </Container>

      <Box sx={{ position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            top: '109px',
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'grey1',
          }}
        />

        <Container>
          <Grid
            columns={[1, 2, 3]}
            sx={{ columnGap: '5rem', rowGap: '3.75rem', pb: 6 }}
          >
            {instructorCourses.map((course) => (
              <CourseItem
                courseId={course.id}
                key={course.id}
                title={course.title}
                content={course.subtitle}
                thumbnailUrl={course.thumbnail}
                thumbnailHqUrl={course.thumbnailHq}
                level={course.level}
                sessions={course.sessions.length}
                instructor={instructor.name}
                instructorId={instructor.id}
                preview={course.preview}
                hideInstructor
                target={`/app/course-detail/${course.id}`}
              />
            ))}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default InstructorDetailSection;
