import * as React from 'react';

import type { Course } from '@youga/youga-interfaces';
import { useUser, useVideoSessions } from '@youga/youga-client-api';

import { CourseSession } from '../../../types/interfaces';
import { getGoalMeta } from '../../../constants/enums';
import { isCompleted } from '../../../helper/JourneyHelpers';
import JourneySection from '../../02_molecules/Journey/JourneySection';

interface Props {
  course: Course;
}

function MhmJourneySection({ course }: Props) {
  const { data: user, refreshUser } = useUser();
  const { data: videoSessionsData } = useVideoSessions();
  //const [currentView, setCurrentView] = React.useState(1);

  const videoSessions = videoSessionsData?.videoSessions;

  React.useEffect(() => {
    refreshUser();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const courseSessions: CourseSession[] = React.useMemo(() => {
    const userSessionsProgress = user?.sessionsProgress;

    const sessions: CourseSession[] = course.sessions.map(
      (sessionName: string, index: number) => {
        const videoSession = videoSessions?.[sessionName];
        const finished = isCompleted(userSessionsProgress?.[sessionName]);
        return {
          id: (index + 1).toString(),
          videoSession,
          courseId: course.id,
          finished,
        };
      },
    );

    const introFinished = isCompleted(
      userSessionsProgress?.[`${course.id}-intro`],
    );

    sessions.unshift({
      id: '0',
      videoSession: undefined,
      courseId: course.id,
      finished: introFinished,
      introUrl: course.introVideoMp4Url,
      introThumb: course.introThumbnail,
    });

    return sessions;
  }, [videoSessions, course, user]);

  const amountOfFinishedSessions = React.useMemo(() => {
    return courseSessions.filter((session: CourseSession) => session.finished)
      .length;
  }, [courseSessions]);

  const allSessionsFinished =
    amountOfFinishedSessions === courseSessions.length;

  const hideJourney = user?.preferences?.hideJourney;
  return hideJourney !== true ? (
    <JourneySection
      bgImage={course.thumbnailHq}
      teaserText={getGoalMeta(course.id)?.title}
      courseId={course.id}
      allSessionsFinished={allSessionsFinished}
      finishedSessions={amountOfFinishedSessions}
      progressText={`(${amountOfFinishedSessions}/${
        course.sessions.length + 1
      })`}
      videos={courseSessions}
      onPressMyProgress={() => {}}
    />
  ) : null;
}

export default MhmJourneySection;
