import React from 'react';
import { Box, Text } from 'theme-ui';
import * as styles from './adaptiveTable.module.css';

export interface AdaptiveTableProps {
  headerItems: string[];
  rows: string[][];
}
const AdaptiveTable: React.FC<AdaptiveTableProps> = ({
  headerItems,
  rows,
}: AdaptiveTableProps) => (
  <Box as="table" className={styles.adaptiveTable}>
    <Box as="thead">
      <Box as="tr">
        {headerItems.map((header) => (
          <Box as="th" key={`header-${header}`}>
            <Text>{header}</Text>
          </Box>
        ))}
      </Box>
    </Box>
    <Box as="tbody">
      {rows.map((row, rowIndex) => (
        <Box as="tr" key={`row-${JSON.stringify(row)}`}>
          {row.map((column, columnIndex) => (
            <Box
              as="td"
              key={`row-${headerItems[rowIndex]}-header-${column}`}
              data-label={headerItems[columnIndex]}
            >
              <Text>{column}</Text>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  </Box>
);

export default AdaptiveTable;
