/** @jsxImportSource theme-ui */

import React from 'react';
import { ThemeUIStyleObject } from 'theme-ui';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Text from '../../01_atoms/Text/Text';
import Container from '../../01_atoms/Container/Container';
import { getDeviceWidth } from '../../../utils/getDeviceWidth';

export interface FooterProps {
  sx?: ThemeUIStyleObject;
  theme?: 'light' | 'dark-transparent' | 'dark-solid';
}

const Wrapper = styled.div`
  background: rgb(255, 255, 255);
  border-radius: 0;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  @media (max-width: ${getDeviceWidth.tablet}) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;
const ContainerStyled = styled(Container)`
  justify-content: space-between;
  align-items: center;
  display: flex;
  @media (max-width: ${getDeviceWidth.tablet}) {
    flex-direction: column;
  }
`;
const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  @media (max-width: ${getDeviceWidth.tablet}) {
    margin-bottom: 40px;
    align-items: center;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  @media (max-width: ${getDeviceWidth.tablet}) {
    flex-direction: column;
  }
`;

const FooterTitle = styled(Text)`
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
  display: flex;
  text-transform: uppercase;
`;

const StyledLink = styled(Link)`
  @media (max-width: ${getDeviceWidth.tablet}) {
    margin-top: 16px;
  }
  color: #000;
  &.active {
    color: #a4d9d6;
  }
`;

const Footer: React.FC<FooterProps> = (props: FooterProps) => {
  const { theme = 'light' } = props;
  let variant = 'links.footer';

  if (theme === 'dark-solid') {
    variant = 'links.footerDarkSolid';
  } else if (theme === 'dark-transparent') {
    variant = 'links.footerDarkTransparent';
  }

  return (
    <Wrapper>
      <ContainerStyled>
        <LeftContainer>
          <FooterTitle>We make Yoga</FooterTitle>
          <FooterTitle>about You</FooterTitle>
        </LeftContainer>

        <LinksContainer>
          <Text variant={variant} sx={{ my: [null, null, 0], mx: [0, 2, 3] }}>
            <StyledLink
              activeClassName="active"
              to="/imprint"
              sx={{ textDecoration: 'none' }}
            >
              Impressum
            </StyledLink>
          </Text>
          <Text variant={variant} sx={{ my: [null, null, 0], mx: [0, 2, 3] }}>
            <StyledLink
              activeClassName="active"
              to="/privacy-policy"
              sx={{ textDecoration: 'none' }}
            >
              Datenschutzerklärung
            </StyledLink>
          </Text>
          <Text variant={variant} sx={{ my: [null, null, 0], mx: [0, 2, 3] }}>
            <StyledLink
              activeClassName="active"
              to="/cookie-policy"
              sx={{ textDecoration: 'none' }}
            >
              Cookie Policy
            </StyledLink>
          </Text>
          <Text variant={variant} sx={{ my: [null, null, 0], mx: [0, 2, 3] }}>
            <StyledLink
              activeClassName="active"
              to="/terms-of-use"
              sx={{ textDecoration: 'none' }}
            >
              Nutzungsbedingungen
            </StyledLink>
          </Text>
        </LinksContainer>
      </ContainerStyled>
    </Wrapper>
  );
};

export default Footer;
