import React, { useRef, useState, useEffect } from 'react';
import { Image, Box, ThemeUIStyleObject } from 'theme-ui';

import debounce from 'lodash/debounce';
import { TrainingSessionImage } from '../../../types/interfaces';
import Text from '../../01_atoms/Text/Text';

export interface ConnectedProps {
  headlineStyles: ThemeUIStyleObject;
  sublineStyles: ThemeUIStyleObject;
  takenPictures?: TrainingSessionImage[];
  onInfoClick: () => void;
  onStartClick: () => void;
}

const Connected: React.FC<ConnectedProps> = ({
  headlineStyles,
  sublineStyles,
  takenPictures,
}: ConnectedProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [availableHeight, setAvailableHeight] = useState<number | null>(null);

  useEffect(() => {
    let isMounted = true;

    const updateAvailableHeight = (): void => {
      if (isMounted && contentRef?.current) {
        if (window.innerHeight >= 992) {
          setAvailableHeight(null);
          return;
        }

        const videoOffset = contentRef?.current
          ?.querySelector('#connected-mirror')
          ?.getBoundingClientRect();

        if (videoOffset != null) {
          setAvailableHeight(window.innerHeight - videoOffset.top);
        }
      }
    };

    const onResize = debounce(updateAvailableHeight, 300);

    updateAvailableHeight();

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', onResize);
    }

    return (): void => {
      isMounted = false;
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <Box ref={contentRef}>
      <Text variant="h3" sx={{ m: 0, ...headlineStyles }}>
        SmartMirror
      </Text>
      <Text variant="h3" sx={{ m: 0, fontWeight: 'normal', ...sublineStyles }}>
        Verbindung erfolgreich.
      </Text>

      <Box
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: '100%',
          width: availableHeight ? `${(availableHeight / 3) * 4}px` : undefined,
        }}
      >
        <Box
          id="connected-mirror"
          sx={{
            mt: 5,
            width: '100%',
            paddingTop: '75%',
            backgroundSize: 'contain',
            backgroundColor: 'grey1',
          }}
        />
      </Box>
      <Box
        sx={{
          width: 'calc(100% + 4px)',
          mx: '-2px',
          pt: '4px',
        }}
      >
        {(takenPictures || [])
          .slice(-3)
          .reverse()
          .map((picture) => (
            <Box
              key={picture.url}
              sx={{
                display: 'inline-block',
                px: '2px',
                width: 'calc(100% / 3)',
              }}
            >
              <Box sx={{ position: 'relative', paddingTop: '75%' }}>
                <Image
                  data-hj-suppress=""
                  src={picture.url}
                  sx={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    objectFit: 'cover',
                    objectPosition: 'center center',
                  }}
                />
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default Connected;
