/** @jsxImportSource theme-ui */

/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useRef, useState, useEffect } from 'react';

import { Box } from 'theme-ui';
import { Link } from 'gatsby';
// eslint-disable-next-line import/no-extraneous-dependencies
import { navigate, RouteComponentProps } from '@reach/router';

import {
  useUser,
  useTrainingSession,
  useVideoSessions,
} from '@youga/youga-client-api';

import { BurstPhoto, TrainingSessionImage } from '../../../types/interfaces';
import useTracking from '../../../hooks/useTracking';
import Header from '../../02_molecules/Header/Header';
import Text from '../../01_atoms/Text/Text';
import Button from '../../01_atoms/Button/Button';
import Container from '../../01_atoms/Container/Container';
import PoseComparisonItem from '../../01_atoms/PoseComparisonItem/PoseComparisonItem';
import Spinner from '../../01_atoms/Spinner/Spinner';
import PrivacyNote from '../../02_molecules/PrivacyNote/PrivacyNote';
import useApi from '../../../hooks/useAPI';
import DeletePoseComparisonOverlay from '../../02_molecules/DeletePoseComparisonOverlay/DeletePoseComparisonOverlay';
import findPoseByName from '../../../utils/findPoseByName';

export type PoseComparisonSectionProps = RouteComponentProps<{
  sessionId: string;
}>;

const PoseComparisonList = ({
  deletable,
  onDeleteClick,
  example = false,
  images,
  currentInfoImage,
  setCurrentInfoImage,
  onUpdateBurstImage,
}: {
  deletable: boolean;
  onDeleteClick: (
    imageName: string,
    beforeCloseCallback?: () => Promise<void>,
  ) => void;
  example?: boolean;
  images: TrainingSessionImage[];
  currentInfoImage: string | null;
  setCurrentInfoImage: (imageName: string | null) => void;
  onUpdateBurstImage: (
    imageName: string,
    burstPhoto: BurstPhoto,
  ) => Promise<void>;
}): React.ReactElement => {
  const { data: posesData } = useApi('posesData');

  return (
    <Container sx={{ maxWidth: '860px' }}>
      {images.map((image) => {
        const pose = findPoseByName(posesData ?? [], image.poseName);

        if (!pose) {
          return null;
        }

        return (
          <PoseComparisonItem
            deletable={deletable}
            onDeleteClick={onDeleteClick}
            example={example}
            key={image.imageName}
            tipsActive={currentInfoImage === image.imageName}
            setTipsActive={setCurrentInfoImage}
            image={image}
            pose={pose}
            onUpdateBurstImage={onUpdateBurstImage}
          />
        );
      })}
    </Container>
  );
};

const PoseComparisonSection = ({
  sessionId,
}: PoseComparisonSectionProps): React.ReactElement => {
  const { track } = useTracking();
  const { data: videoSessionsData } = useVideoSessions();
  const { data: user } = useUser();
  const {
    data: trainingSession,
    isValidating,
    error,
    deleteImage,
    updateTrainingSession,
    updateAnimationImage,
  } = useTrainingSession(sessionId);
  const [imageInfo, setImageInfo] = useState<string | null>(null);
  const beforeDeletionCloseCallback = useRef<() => Promise<void>>();
  const [deletingImageName, setDeletingImageName] = useState<string | null>(
    null,
  );

  useEffect(() => {
    track('pose-comparison-section', { trainingSessionId: sessionId });
  }, [sessionId, track]);

  if (isValidating || (sessionId === 'example' && !trainingSession)) {
    return (
      <>
        <Header showButton="back" theme="light" />
        <Spinner fullscreen />
      </>
    );
  }

  if (
    error != null ||
    !trainingSession ||
    trainingSession.images.length === 0
  ) {
    return (
      <>
        <Header showButton="back" theme="light" />
        <Box sx={{ margin: '10rem auto', textAlign: 'center' }}>
          <Text>
            Fehler: Die Pose Comparison für diese Session ist leider nicht
            verfügbar. Wahrscheinlich wurde kein Foto aufgenommen.
          </Text>
        </Box>
      </>
    );
  }

  const onDeleteClick = (
    imageName: string,
    beforeCloseCallback?: () => Promise<void>,
  ): void => {
    beforeDeletionCloseCallback.current = beforeCloseCallback;
    setDeletingImageName(imageName);
  };

  const onDeleteConfirm = async (
    imageName: string,
    reason: string,
  ): Promise<void> => {
    track('pose-comparison-delete-picture', {
      trainingSessionId: trainingSession.id,
      imageName,
      imagesCount: trainingSession.images.length,
      reason,
    });

    const updatedSession = await deleteImage(imageName);

    if (beforeDeletionCloseCallback.current) {
      await beforeDeletionCloseCallback.current();
    }

    if (updatedSession?.images.length === 0) {
      navigate('/app/pose-comparison/');
      return;
    }
  };

  const onUpdateBurstImage = async (
    imageName: string,
    burstPhoto: BurstPhoto,
  ): Promise<void> => {
    const image = trainingSession.images.find(
      (im) => im.imageName === imageName,
    );
    const burstImages = image?.burstPhotoUrls || [];

    const wasDifference = burstImages.find(
      (bp) => bp.url === image?.url,
    )?.difference;
    const nowDifference = burstPhoto.difference;

    track('pose-comparison-change-burst-image', {
      poseName: image?.poseName,
      imageName,
      previous: `${wasDifference}s`,
      now: `${nowDifference}s`,
    });

    await updateTrainingSession({
      ...trainingSession,
      images: trainingSession.images.map((im) => {
        return {
          ...im,
          url: im.imageName === imageName ? burstPhoto.url : im.url,
        };
      }),
    });
    await updateAnimationImage();
  };

  const images = trainingSession.images.sort((a, b) =>
    b.imageName.localeCompare(a.imageName),
  );

  return (
    <>
      <Header showButton="back" />

      <DeletePoseComparisonOverlay
        visible={deletingImageName != null}
        onClose={(): void => {
          beforeDeletionCloseCallback.current = undefined;
          setDeletingImageName(null);
        }}
        onConfirm={async (reason: string): Promise<void> => {
          if (deletingImageName == null) {
            throw new Error(
              `Unexpected state for deletingImageName: "${deletingImageName}"`,
            );
          }
          return onDeleteConfirm(deletingImageName, reason);
        }}
      />

      <Container
        className="pose-comparison-hide-on-enlarge"
        sx={{
          mt: [5, 9],
          mb: [5, 9],
        }}
      >
        <Text
          variant="h2"
          sx={{
            my: 0,
            fontSize: [3, 5],
            display: 'inline-block',
            width: '100%',
            textAlign: 'center',
          }}
        >
          {trainingSession.id === 'example'
            ? 'Pose Comparison Beispiel'
            : videoSessionsData?.videoSessions[
                trainingSession.videoSessionId as string
              ]?.title || 'Pose Comparison'}
        </Text>
      </Container>

      <PoseComparisonList
        deletable={trainingSession.userId === user?.id}
        example={trainingSession.id === 'example'}
        images={images}
        currentInfoImage={imageInfo}
        setCurrentInfoImage={setImageInfo}
        onDeleteClick={onDeleteClick}
        onUpdateBurstImage={onUpdateBurstImage}
      />

      <Box sx={{ display: 'flex', mt: 6, justifyContent: 'center' }}>
        <PrivacyNote />
      </Box>

      <Container className="pose-comparison-hide-on-enlarge">
        <Box sx={{ textAlign: 'center', mt: 9, mb: '200px' }}>
          <Link to="/app/pose-comparison" sx={{ textDecoration: 'none' }}>
            <Button
              sx={{ display: 'block', mx: 'auto', textDecoration: 'none' }}
              variant="primary"
            >
              Zurück
            </Button>
          </Link>
        </Box>
      </Container>
    </>
  );
};

export default PoseComparisonSection;
