import { useCallback } from 'react';
import type { TrackingData, TrackingEventName } from '@youga/youga-interfaces';

import useAuth from './useAuth';
import API from '../services/API';

interface UserWithAttributes {
  username?: string | null;
  attributes: { sub: string; email: string; username?: string | null };
}

function useTracking() {
  const auth = useAuth();

  const track = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (
      eventName: TrackingEventName,
      payload?: Record<string, unknown>,
      userData?: UserWithAttributes | null,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Promise<void> => {
      if (auth.status !== 'authenticated') {
        return;
      }

      const user = userData || auth.user;

      const userId = user?.attributes.sub;
      const email = user?.attributes.email;
      // TODO: check where username is located
      const username =
        userData?.username ||
        userData?.attributes.username ||
        auth.user.username;

      const trackingData: TrackingData = {
        eventName,
        payload: payload ?? {},
        userAgent:
          typeof navigator !== 'undefined'
            ? navigator.userAgent || undefined
            : undefined,
        userId,
        email: email as string,
        username,
      };

      await API.postTrackingData(trackingData);
    },
    [auth.status, auth.user],
  );

  return {
    track,
  };
}

export default useTracking;
