import React from 'react';
import { Box } from 'theme-ui';
import VideoItem from '../../01_atoms/VideoItem/VideoItem';

interface CourseSessionCarouselItemProps {
  title: string;
  thumbnailHqUrl: string;
  duration: string;
  target: string;
  className?: string;
  isLastElement: boolean;
}
const CourseSessionCarouselItem: React.FC<CourseSessionCarouselItemProps> = ({
  title,
  thumbnailHqUrl,
  duration,
  target,
  isLastElement,
}: CourseSessionCarouselItemProps) => (
  <Box
    sx={{
      scrollSnapStop: 'always',
      scrollSnapAlign: 'start',
      pl: '20px',
      pr: isLastElement ? '20px' : 0,
      ml: 0,
      mt: 0,
      mb: 0, // render shadow. OverflowX: scroll results in OverflowY: auto and not visible
      minWidth: isLastElement ? '413.3333333333px' : '393.3333333333px',
      width: isLastElement ? '413.3333333333px' : '393.3333333333px',
    }}
  >
    <VideoItem
      imageSource={thumbnailHqUrl}
      title={title}
      duration={duration}
      target={target}
    />
  </Box>
);

export default CourseSessionCarouselItem;
