import React from 'react';
import { Box, Image } from 'theme-ui';
import Text from '../../01_atoms/Text/Text';
import IconPrivate from '../../../assets/icons/iconPrivate.svg';

const PrivacyNote: React.FC<unknown> = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    }}
  >
    <Box sx={{ mr: 2, pt: '2px' }}>
      <Image src={IconPrivate} sx={{ width: '16px' }} />
    </Box>
    <Text
      sx={{
        fontSize: 0,
        fontWeight: 'semibold',
        color: 'grey2',
        letterSpacing: 0,
      }}
    >
      Keine Sorge – Deine Bilder sind privat
    </Text>
  </Box>
);

export default PrivacyNote;
