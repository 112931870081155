import * as React from 'react';
import { navigate } from 'gatsby';
import { Box } from 'theme-ui';

import type { LayoutProps } from '../../global/Layout';
import Layout from '../../global/Layout';
import Seo from '../../global/Seo';
import useAuth from '../../hooks/useAuth';

interface Props {
  authRequired: boolean;
  title: string;
  theme?: LayoutProps['theme'];
}

function Page({
  authRequired,
  title,
  theme,
  children,
}: React.PropsWithChildren<Props>) {
  const auth = useAuth();

  React.useEffect(() => {
    if (
      auth.status !== 'init' &&
      auth.status !== 'authenticated' &&
      authRequired
    ) {
      navigate(`/login/${window?.location.search}`);
    }
  }, [auth.status, authRequired]);

  return (
    <Layout theme={theme}>
      <Box id="relative-wrapper" sx={{ position: 'relative' }}>
        <Seo title={title} titleTemplate="" />
        {children}
      </Box>
    </Layout>
  );
}

export default Page;
