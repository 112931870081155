import * as React from 'react';
import { useInstructors } from '@youga/youga-client-api';
import { Box } from 'theme-ui';

import Text from '../../01_atoms/Text/Text';
import shuffle from '../../../utils/shuffle';

import SplashInstructorCarouselItem from '../SplashInstructorCarouselItem/SplashInstructorCarouselItem';
import Carousel from '../../02_molecules/Carousel/Carousel';

function YourInstructors() {
  const { data: instructorsData } = useInstructors();

  const renderInstructorCarouselItems = React.useMemo(
    () =>
      instructorsData?.instructors &&
      shuffle(Object.keys(instructorsData.instructors)).map(
        (instructorId: string) => {
          const instructor = instructorsData.instructors[instructorId];
          // FIXME: (pwo) "??"
          return (
            !instructor.hideOnCarousel && (
              <SplashInstructorCarouselItem
                key={`instructor-${instructor.id}`}
                target={`/app/instructor-detail/${instructor.id}`}
                name={instructor.name}
                thumbnail={instructor.thumbnail ?? ''}
                collabLogo={instructor.collabLogo ?? ''}
              />
            )
          );
        },
      ),
    [instructorsData],
  );

  return (
    <Box>
      <Text variant="h2light" sx={{ fontSize: [3, 4], mt: [3, 7], mb: [3, 4] }}>
        Deine Trainerinnen
      </Text>

      <Box sx={{ mx: ['-20px', 0], position: 'relative' }}>
        <Carousel hideControlShadow fullHeight>
          {renderInstructorCarouselItems}
        </Carousel>
      </Box>
    </Box>
  );
}

export default YourInstructors;
