import useSWRImmutable from 'swr/immutable';

import type { VideoSession } from '@youga/youga-interfaces';
import { useYougaClientApi } from '../YougaClientApiProvider';

export interface UseVideoSessiosResponseBody {
  videoSessions: Record<string, VideoSession>;
}

export function useVideoSessions() {
  const { publicFetcher } = useYougaClientApi();
  const response = useSWRImmutable<UseVideoSessiosResponseBody>(
    `/video-sessions`,
    {
      fetcher: publicFetcher,
    },
  );
  return response;
}
