/** @jsxImportSource theme-ui */

import React from 'react';
import Lottie from 'react-lottie-wrapper';

export interface FullscreenLottieProps {
  loop: boolean;
  autoplay: boolean;
  lottieAnimation: JSONObject;
  preserveAspectRatio: string;
}

const FullscreenLottie: React.FC<FullscreenLottieProps> = ({
  loop = false,
  autoplay = true,
  lottieAnimation,
  preserveAspectRatio = 'xMaxYMid slice',
}: FullscreenLottieProps) => {
  return (
    <Lottie
      sx={{
        pointerEvents: 'none',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      height="100%"
      width="100%"
      options={{
        loop,
        autoplay,
        animationData: lottieAnimation,
        rendererSettings: {
          preserveAspectRatio,
        },
      }}
    />
  );
};

export default FullscreenLottie;
