import * as React from 'react';
import { Link } from 'gatsby';
// eslint-disable-next-line import/no-extraneous-dependencies
import { RouteComponentProps } from '@reach/router';
import Button from '../../01_atoms/Button/Button';
import Container from '../../01_atoms/Container/Container';
import Header from '../../02_molecules/Header/Header';
import useAuth from '../../../hooks/useAuth';

export type ConnectSectionProps = RouteComponentProps;

// eslint-disable-next-line no-empty-pattern
const PairWithConnectSection =
  ({}: ConnectSectionProps): React.ReactElement => {
    const { isSignedIn } = useAuth();

    return (
      <>
        <Header showButton={null} />
        <Container sx={{ textAlign: 'center' }}>
          <h1>Nur noch wenige Schritte entfernt...</h1>
          {isSignedIn ? (
            <>
              <p>
                Bitte wähle nun auf diesem Gerät eine Session aus, die du
                starten möchtest.
              </p>

              <Link to="/app">
                <Button variant="primary">Zur Auswahl</Button>
              </Link>
            </>
          ) : (
            <>
              <p>
                Bitte melde dich nun auf diesem Gerät an und wähle eine Session
                aus, die du starten möchtest.
              </p>

              <Link to="/app">
                <Button variant="primary">Zum Login</Button>
              </Link>
            </>
          )}
        </Container>
      </>
    );
  };

export default PairWithConnectSection;
