import React from 'react';

import { Box, Flex, Image } from 'theme-ui';
import { useUser } from '@youga/youga-client-api';

import Button from '../../01_atoms/Button/Button';
import FadeIn from '../../01_atoms/FadeIn/FadeIn';
import Text from '../../01_atoms/Text/Text';

import OnboardingPlayBarImage from '../../../assets/onboardingPlayBar.png';
import OnboardingArrowPlayBarImage from '../../../assets/onboardingArrowPlayBar.png';
import OnboardingArrowSmartMirrorImage from '../../../assets/onboardingArrowSmartMirror.png';

const style = {
  root: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    background:
      'linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 0%)',
  } as React.CSSProperties,
  sessionStartContainer: {
    position: 'absolute',
    top: '35%',
    left: '2.5%',
  } as React.CSSProperties,
  title: { color: 'white', margin: 0, fontFamily: 'Montserrat' },
  subtitle: {
    color: 'white',
    fontWeight: 'thin',
    margin: 0,
    fontFamily: 'Montserrat',
  },
  body: { color: 'white', marginTop: 16, fontFamily: 'Metropolis' },
  smartMirrorContainer: {
    position: 'absolute',
    top: '15%',
    right: 600,
    width: 480,
  } as React.CSSProperties,
  smartMirrorArrow: {
    position: 'absolute',
    top: 12,
    right: -40,
  } as React.CSSProperties,
  playBarContainer: {
    position: 'absolute',
    bottom: 32,
    left: 0,
    right: 0,
    width: '100%',
  } as React.CSSProperties,
  playBarTextContainer: {
    marginBottom: 64,
    width: 480,
    position: 'absolute',
    bottom: 64,
    left: '45%',
  } as React.CSSProperties,
  playBarArrow: {
    position: 'absolute',
    left: '39.75%',
    bottom: 112,
  } as React.CSSProperties,
  playBarImage: {
    position: 'absolute',
    left: '2%',
    right: '2%',
    bottom: 32,
    width: '96%',
  } as React.CSSProperties,
};

function SessionStartContainer({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled: boolean;
}) {
  return (
    <Box style={style.sessionStartContainer}>
      <Text variant="h2" sx={style.title}>
        Deine erste Session
        <Text variant="h2" sx={style.subtitle}>
          mit aktivem Feedback
        </Text>
      </Text>
      <Button disabled={disabled} style={{ marginTop: 24 }} onClick={onClick}>
        Session starten
      </Button>
    </Box>
  );
}

function ExplainSmartMirrorContainer({ mirrorWidth }: { mirrorWidth: number }) {
  const containerStyle = Object.assign({}, style.smartMirrorContainer, {
    right: (mirrorWidth || 0) + 66,
  });

  return (
    <Box style={containerStyle}>
      <Text variant="h3" sx={style.title}>
        SmartMirror
        <Text variant="h3" sx={style.subtitle}>
          Dein Smartphone wird
        </Text>
        <Text variant="h3" sx={style.subtitle}>
          zum digitalen Spiegel.
        </Text>
      </Text>

      <Image
        src={OnboardingArrowSmartMirrorImage}
        style={style.smartMirrorArrow}
      />

      <Text varian="p" style={style.body}>
        Positioniere den SmartMirror so zur Matte, dass Du Dich selbst während
        der Session in der gleichen Ausrichtung wie die Trainerin sehen kannst.
      </Text>
      <Text varian="p" style={style.body}>
        Damit kannst Du Deine Ausführung selbst direkt während der Session
        überprüfen und verbessern.
      </Text>
    </Box>
  );
}

function ExplainPlaybarContainer() {
  return (
    <Box style={style.playBarContainer}>
      <Flex>
        <Box style={style.playBarTextContainer}>
          <Text variant="h3" sx={style.title}>
            Pose Comparison
            <Text variant="h3" sx={style.subtitle}>
              Mach Deinen Fortschritt sichtbar.
            </Text>
          </Text>
          <Text varian="p" style={style.body}>
            Während ausgewählten Posen in der Session, machen wir ein Bild von
            Dir. Dadurch kannst Du nach der Session in Ruhe Deine Ausführung
            direkt mit der Trainerin vergleichen.
          </Text>
          <Text varian="p" style={style.body}>
            Vor jeder Pose erscheint ein Countdown. Dadurch weißt Du, wann die
            nächste Pose Comparison aufgenommen wird.
          </Text>
        </Box>

        <Image src={OnboardingArrowPlayBarImage} style={style.playBarArrow} />

        <Image src={OnboardingPlayBarImage} style={style.playBarImage} />
      </Flex>
    </Box>
  );
}

export default function OnboardingTrainingssession({
  mirrorWidth,
}: {
  mirrorWidth: number;
}) {
  const { data, updatePreferences } = useUser();
  const [displayOverlay, setDisplayOverlay] = React.useState(false);
  const [disabled, setDisable] = React.useState(false);

  const smCounter = data?.stats?.smartMirror;
  const smOnboardedCompleted = data?.preferences?.smartMirrorOnboarded;

  const onClickResolveOnboarding = async () => {
    setDisable(true);
    await updatePreferences({
      ...data?.preferences,
      smartMirrorOnboarded: true,
    });

    setDisplayOverlay(false);
    setDisable(true);
  };

  React.useEffect(() => {
    const isFirstSession = smCounter === 0 && smOnboardedCompleted !== true;

    if (isFirstSession && !displayOverlay) {
      setDisplayOverlay(true);
    }
  }, [displayOverlay, smCounter, smOnboardedCompleted]);

  if (displayOverlay) {
    return (
      <div style={style.root}>
        <Box>
          <FadeIn delay={750} duration={750}>
            <SessionStartContainer
              disabled={disabled}
              onClick={onClickResolveOnboarding}
            />
          </FadeIn>

          <FadeIn delay={1250} duration={750}>
            <ExplainSmartMirrorContainer mirrorWidth={mirrorWidth} />
          </FadeIn>

          <FadeIn delay={2000} duration={750}>
            <ExplainPlaybarContainer />
          </FadeIn>
        </Box>
      </div>
    );
  }
  return null;
}
