// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-callback-tsx": () => import("./../../../src/pages/callback.tsx" /* webpackChunkName: "component---src-pages-callback-tsx" */),
  "component---src-pages-camera-tsx": () => import("./../../../src/pages/camera.tsx" /* webpackChunkName: "component---src-pages-camera-tsx" */),
  "component---src-pages-confirm-email-tsx": () => import("./../../../src/pages/confirm-email.tsx" /* webpackChunkName: "component---src-pages-confirm-email-tsx" */),
  "component---src-pages-connect-tsx": () => import("./../../../src/pages/connect.tsx" /* webpackChunkName: "component---src-pages-connect-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-imprint-tsx": () => import("./../../../src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-pair-with-connect-tsx": () => import("./../../../src/pages/pair-with-connect.tsx" /* webpackChunkName: "component---src-pages-pair-with-connect-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */)
}

