import React from 'react';
import Text from '../../01_atoms/Text/Text';

const Success: React.FC<unknown> = () => (
  <>
    <Text variant="h2" highlighted sx={{ m: 0, textAlign: 'center' }}>
      Wir melden uns!
    </Text>
    <Text variant="h2" sx={{ m: 0, textAlign: 'center' }}>
      Danke für Dein Interesse.
    </Text>
  </>
);

export default Success;
