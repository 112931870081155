import DefaultPageLayout from '../components/04_templates/DefaultPageLayout';

function NotFoundPage() {
  return (
    <DefaultPageLayout title="404 Seite nicht gefunden">
      Leider konnte die Seite nicht gefunden werden :(
    </DefaultPageLayout>
  );
}

export default NotFoundPage;
