import * as React from 'react';
import { navigate } from 'gatsby';

import Header from '../components/02_molecules/Header/Header';
import AuthSection from '../components/03_organisms/AuthSection/AuthSection';
import useAuth from '../hooks/useAuth';
import IosGate from '../components/03_organisms/IosGate/IosGate';
import UnsupportedOsGate from '../components/03_organisms/UnsupportedOsGate/UnsupportedOsGate';
import useGateInformation, {
  GATE_INFORMATION,
} from '../hooks/useGateInformation';
import Page from '../components/04_templates/Page';
import Footer from '../components/02_molecules/Footer/Footer';

function LoginPage() {
  const { isSignedIn, isInitialized } = useAuth();

  React.useEffect(() => {
    if (isInitialized && isSignedIn) {
      navigate(`/app`, { replace: true });
    }
  }, [isInitialized, isSignedIn]);

  return (
    <Page title="Yuu Yoga - Anmelden" authRequired={false}>
      <LoginPageContent />
      <Footer />
    </Page>
  );
}

function LoginPageContent() {
  const gate = useGateInformation();

  if (gate === GATE_INFORMATION.AGENT_IOS) {
    return <IosGate />;
  }

  if (
    gate === GATE_INFORMATION.AGENT_ANDROID ||
    gate === GATE_INFORMATION.MOBILE
  ) {
    return <UnsupportedOsGate />;
  }

  return (
    <>
      <Header
        theme="light"
        backLinkOverride="https://www.yuuxyoga.de/"
        showConnectButton={true}
      />
      <AuthSection />
    </>
  );
}

export default LoginPage;
