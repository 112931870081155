import React, { useRef, useState, useEffect } from 'react';
import { Image, Box, ThemeUIStyleObject } from 'theme-ui';

import { useUser } from '@youga/youga-client-api';

import {
  StreamingSession,
  Step,
  VideoPlayerStore,
  TrainingSessionImage,
} from '../../../types/interfaces';
import CollapsedLabel from './CollapsedLabel';
import Intro from './Intro';
import MirrorContainer from './MirrorContainer';
import Connected from './Connected';
import Spinner from '../../01_atoms/Spinner/Spinner';
import btnClose from '../../../assets/btnClose.svg';

export enum VideoState {
  Pause,
  Play,
  Stop,
}

export interface VideoSessionSidebarProps {
  takenPictures?: TrainingSessionImage[];
  steps?: Step[];
  videoSessionId: string;
  videoPlayerStore?: VideoPlayerStore;
  passiveScreen: boolean;
  streamingSession: StreamingSession;
  videoState: VideoState;
  smartMirrorTranslate: boolean;
  onStartClick: () => void;
  onSmartMirrorEnabled: () => void;
}

const getMirrorWidthBySize = (size: string): number => {
  switch (size) {
    case 'small':
      return 15;

    case 'medium':
      return 25;

    default:
    case 'large':
      return 35;
  }
};

const VideoSessionSidebar: React.FC<VideoSessionSidebarProps> = ({
  takenPictures,
  videoSessionId,
  steps,
  videoPlayerStore,
  passiveScreen,
  streamingSession,
  videoState,
  smartMirrorTranslate,
  onStartClick,
  onSmartMirrorEnabled,
}: VideoSessionSidebarProps) => {
  const { data: user } = useUser();
  const sidebarRef = useRef(null);
  const smartMirrorConnected = useRef(false);
  const forcedExpandedState = useRef(false);
  const [waitForMobileAction, setWaitForMobileAction] = useState(passiveScreen);
  const [instructionsPage, setInstructionsPage] = useState<string | null>(null);
  const [isConnected, setConnected] = useState(false);
  const [originalPosition, setOriginalPosition] = useState(true);
  const [isExpanded, setRawExpanded] = useState(false);
  const [isFullyExpanded, setFullyExpanded] = useState(false);
  const [showMirror, setShowMirror] = useState(
    typeof user?.preferences?.smartMirrorShowMirror === 'undefined'
      ? true
      : user?.preferences.smartMirrorShowMirror,
  );
  const [mirrorSize, setMirrorSize] = useState(
    typeof user?.preferences?.smartMirrorSize === 'undefined'
      ? 'large'
      : user?.preferences.smartMirrorSize,
  );
  const [placementSelector, setPlacementSelector] = useState<
    string | undefined
  >(undefined);

  /**
   * Sets the expanded-mode of the sidebar to a specified state and triggers a callback once the transition is finished
   */
  const setExpanded = (
    expanded: boolean,
    onFinishAnimation?: () => void,
  ): void => {
    if (!expanded) {
      setFullyExpanded(false);
    }

    if (sidebarRef.current) {
      (sidebarRef.current as unknown as HTMLElement).addEventListener(
        'transitionend',
        (): void => {
          if (expanded) {
            setFullyExpanded(true);
          }

          if (onFinishAnimation) {
            onFinishAnimation();
          }
        },
        { once: true },
      );
    }

    setRawExpanded(expanded);
  };

  /**
   * Expand the Sidebar Menu when mounting. This way, we get the slide-in animation.
   */
  useEffect(() => {
    const slideIn = (): void => {
      setExpanded(true);
    };

    if (typeof window !== 'undefined') {
      window.setTimeout(slideIn, 200);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * [videoState]:
   * Automatically hides the sidebar after 3s once we start playing the video
   */
  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (typeof window !== 'undefined' && videoState === VideoState.Play) {
      timer = setTimeout((): void => {
        if (!forcedExpandedState.current) {
          setExpanded(false);
        }
      }, 3000);
    }

    return (): void => {
      clearTimeout(timer);
    };
  }, [videoState]);

  /**
   * Triggers the `onSmartMirrorEnabled` callback when we connected the smart mirror for the first time
   */
  useEffect(() => {
    if (isConnected && !smartMirrorConnected.current) {
      // it's the first time we connected the smartMirror
      smartMirrorConnected.current = true;
      onSmartMirrorEnabled();
    }

    if (isConnected) {
      setExpanded(false);
    }
  }, [isConnected]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Sets the placement-selector depending on the current state
   */
  useEffect((): void => {
    if (!isConnected || instructionsPage) {
      setPlacementSelector(undefined);
      return;
    }

    if (isFullyExpanded || !showMirror) {
      setPlacementSelector('#connected-mirror');
      return;
    }

    setPlacementSelector('#playing-mirror');
  }, [isConnected, isFullyExpanded, instructionsPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const headlineStyles: ThemeUIStyleObject = {
    '@media (max-height: 991px)': {
      fontSize: 3,
    },
  };

  const sublineStyles: ThemeUIStyleObject = {
    '@media (max-height: 991px)': {
      fontSize: 3,
    },
  };

  return (
    <>
      <Box
        ref={sidebarRef}
        sx={{
          position: 'fixed',
          zIndex: 101,
          top: 0,
          right: 0,
          bottom: 0,
          width: '35%',
          minWidth: '360px',
          maxWidth: '600px',
          px: 3,
          pt: 4,
          backgroundColor: 'white',
          transition: 'transform ease-in 0.5s',
          display: 'flex',
          alignItems: 'center',
          '@media (min-height: 768px)': {
            pt: 8,
            pb: 8,
            px: 6,
          },
          '@media (min-height: 992px)': {
            pt: 12,
            pb: 12,
          },
          '@media (min-height: 1200px)': {
            pt: 18,
            pb: 18,
          },
        }}
        style={{
          transform: !isExpanded ? 'translateX(100%)' : undefined,
        }}
      >
        <CollapsedLabel
          isMenuExpanded={isExpanded}
          direction={
            isConnected && originalPosition && showMirror
              ? 'horizontal'
              : 'vertical'
          }
          onClick={(): void => {
            forcedExpandedState.current = true;
            setExpanded(true);
          }}
        />

        <Box
          style={{
            overflowY: isFullyExpanded ? 'auto' : undefined,
            overflowX: 'hidden',
            maxHeight: '100%',
            width: '100%',
          }}
        >
          {waitForMobileAction && (
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Spinner />
            </Box>
          )}

          {!waitForMobileAction && (
            <>
              <Box
                sx={{
                  cursor: 'pointer',
                  position: 'fixed',
                  top: '1.5rem',
                  right: '1.5rem',
                }}
                onClick={(): void => {
                  setExpanded(false);
                }}
              >
                <Image src={btnClose} sx={{ width: '20px', height: '20px' }} />
              </Box>

              {!instructionsPage && !isConnected && (
                <Intro
                  headlineStyles={headlineStyles}
                  sublineStyles={sublineStyles}
                  codeDataUrl={streamingSession.codeDataUrl}
                />
              )}

              {!instructionsPage && isConnected && (
                <Connected
                  headlineStyles={headlineStyles}
                  sublineStyles={sublineStyles}
                  takenPictures={takenPictures}
                  onStartClick={(): void => {
                    setExpanded(false, (): void => {
                      onStartClick();
                    });
                  }}
                  onInfoClick={(): void => {
                    setInstructionsPage('setup');
                  }}
                />
              )}
            </>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          position: 'fixed',
          top: 0,
          right: 0,
          width: `${getMirrorWidthBySize(mirrorSize)}%`,
          visibility: 'hidden',
          pointerEvents: 'none',
        }}
      >
        <Box id="playing-mirror" sx={{ paddingTop: '75%' }} />
      </Box>

      <MirrorContainer
        smartMirrorTranslate={smartMirrorTranslate}
        isOriginalPosition={originalPosition}
        videoSessionId={videoSessionId}
        steps={steps}
        videoPlayerStore={videoPlayerStore}
        videoState={videoState}
        isConnected={isConnected}
        showMirror={showMirror}
        streamingSession={streamingSession}
        onVideoDragged={(dragged: boolean): void => {
          setOriginalPosition(!dragged);
        }}
        onStateChange={(state: boolean): void => {
          setConnected(state);
          setWaitForMobileAction(false);
        }}
        placementSelector={placementSelector}
        activeMirrorSize={mirrorSize}
        onMirrorSizeChange={(size: string): void => {
          setMirrorSize(size);
        }}
        onShowMirrorChange={(show: boolean): void => {
          setShowMirror(show);
        }}
      />
    </>
  );
};

export default VideoSessionSidebar;
