/** @jsxImportSource theme-ui */

import React from 'react';

import AuthForm from './AuthForm';

export type AuthVariant =
  | 'signin'
  | 'resetPassword'
  | 'confirmResetPassword'
  | 'signup'
  | 'confirmSignup'
  | 'qr-code';

interface AuthBoxProps {
  confirmCode?: string;
  variant?: AuthVariant;
  referral?: string;
}

export default function AuthBox({
  confirmCode,
  variant = 'signin',
  referral = '',
}: AuthBoxProps) {
  const [currentVariant, setCurrentVariant] = React.useState(variant);

  return (
    <AuthForm
      confirmCode={confirmCode}
      referral={referral}
      variant={currentVariant}
      setCurrentVariant={setCurrentVariant}
    />
  );
}
