import * as React from 'react';
import { Box } from 'theme-ui';

import type { TrainingSession, VideoSession } from '@youga/youga-interfaces';
import {
  useCourses,
  useInstructors,
  useVideoSessions,
} from '@youga/youga-client-api';

import Text from '../../01_atoms/Text/Text';

import CourseItem from '../../01_atoms/CourseItem/CourseItem';
import Carousel from '../../02_molecules/Carousel/Carousel';
import CourseSessionCarouselItem from '../CourseSessionCarouselItem/CourseSessionCarouselItem';

interface Props {
  trainingSession: TrainingSession;
}

function NextSessionSuggestions({ trainingSession }: Props) {
  const { data: instructorsData } = useInstructors();
  const { data: videoSessionsData } = useVideoSessions();
  const { data: coursesData } = useCourses();

  const suggestedCourse = React.useMemo(() => {
    if (coursesData == null) {
      return null;
    }

    const videoSessionId = trainingSession.videoSessionId || 'unknown';
    return Object.values(coursesData.courses).find((c) =>
      c.sessions.includes(videoSessionId),
    );
  }, [trainingSession, coursesData]);

  const suggestedSessions = React.useMemo(() => {
    if (suggestedCourse == null) {
      return [];
    }

    const videoSessionId = trainingSession.videoSessionId;

    const sessionIndex = suggestedCourse.sessions.findIndex(
      (s) => s === videoSessionId,
    );

    if (sessionIndex < 0) {
      // this should not happen, because we found a course that included this session
      return;
    }

    const suggestedSessions = [];
    suggestedSessions.push(...suggestedCourse.sessions.slice(sessionIndex + 1));

    if (sessionIndex > 0) {
      suggestedSessions.push(
        ...suggestedCourse.sessions.slice(0, sessionIndex),
      );
    }

    // move the last session to the first place, so the next session will be the center one
    const lastOne = suggestedSessions.pop();
    suggestedSessions.unshift(lastOne);

    return suggestedSessions
      .map((id) => videoSessionsData?.videoSessions[id as string])
      .filter(notEmpty);
  }, [suggestedCourse, videoSessionsData, trainingSession]);

  return (
    <React.Fragment>
      <Text
        variant="h2light"
        sx={{
          fontSize: [2, 3],
          mt: [8, 7],
          mb: [3, 5],
        }}
      >
        Weiter geht’s? Die nächste Session wartet…
      </Text>

      <Box
        style={{
          position: 'relative',
          // We need to extend the width a bit, to allow the shadow to be visible
          // This space will be added afterwards as a padding-left at the items
          marginLeft: '-20px',
          marginRight: '-20px',
          width: 'calc(100% + 40px)',
          height: '297px',
        }}
      >
        <Carousel
          imageHeight={297}
          hideControlShadow
          hideControlsOnTouchDevices
          alignItems="center"
          fullHeight
          swipeLeftStyle={{
            '@media (min-width: 1200px)': {
              left: 'calc(50% - 45px - 195px)',
            },
          }}
          swipeRightStyle={{
            '@media (min-width: 1200px)': {
              right: 'calc(50% - 45px - 195px)',
            },
          }}
        >
          {suggestedSessions?.map(
            (videoSession: VideoSession, sessionIndex: number) => (
              <CourseSessionCarouselItem
                key={videoSession.id}
                title={videoSession.title}
                thumbnailHqUrl={videoSession.thumbnailSmallHq}
                duration={videoSession.duration}
                target={`/app/session/${videoSession.id}`}
                isLastElement={sessionIndex === suggestedSessions.length - 1}
              />
            ),
          )}
        </Carousel>

        {suggestedCourse != null ? (
          <CourseItem
            target={`/app/course-detail/${suggestedCourse.id}`}
            key={`coursecarousel-item-${suggestedCourse.id}`}
            courseId={suggestedCourse.id}
            title={suggestedCourse.title}
            content={suggestedCourse.subtitle}
            sessions={suggestedCourse.sessions.length}
            thumbnailUrl={suggestedCourse.thumbnail}
            thumbnailHqUrl={suggestedCourse.thumbnailHq}
            level={suggestedCourse.level}
            preview={suggestedCourse.preview}
            hideInstructor={false}
            instructorId={suggestedCourse.instructor}
            instructor={
              (instructorsData?.instructors[suggestedCourse.instructor] || {})
                .name || ''
            }
            sx={{
              position: 'absolute',
              top: 0,
              left: '20px',
              '@media (max-width: 1199px)': {
                display: 'none',
              },
            }}
          />
        ) : null}
      </Box>

      <Box
        sx={{
          position: 'absolute',
          top: [
            (trainingSession?.images || []).length > 0 ? '830px' : '500px',
            null,
            (trainingSession?.images || []).length > 0 ? '810px' : '500px',
          ],
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'grey1',
          zIndex: -1,
        }}
      />
    </React.Fragment>
  );
}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export default NextSessionSuggestions;
