import React, { useEffect } from 'react';
import { Box, Flex, Image } from 'theme-ui';
// eslint-disable-next-line import/no-extraneous-dependencies
import { RouteComponentProps } from '@reach/router';

import { useTrainingSessions } from '@youga/youga-client-api';

import Header from '../../02_molecules/Header/Header';
import useTracking from '../../../hooks/useTracking';
import Text from '../../01_atoms/Text/Text';
import Spinner from '../../01_atoms/Spinner/Spinner';
import Container from '../../01_atoms/Container/Container';
import PoseComparisonOverviewList from '../../02_molecules/PoseComparisonOverviewList/PoseComparisonOverviewList';
import PoseComparisonOverviewEmpty from '../../02_molecules/PoseComparisonOverviewEmpty/PoseComparisonOverviewEmpty';
import PoseComparisonOverviewCourses from '../../02_molecules/PoseComparisonOverviewCourses/PoseComparisonOverviewCourses';
import PrivacyNote from '../../02_molecules/PrivacyNote/PrivacyNote';
import IllustrationPoseComparison from '../../../assets/images/IllustrationPoseComparison.svg';

const PoseComparisonOverviewSection: React.FC<RouteComponentProps> = () => {
  const { track } = useTracking();
  const { data: trainingSessions, isValidating } = useTrainingSessions();

  useEffect(() => {
    track('pose-comparison-overview-section');
  }, [track]);

  if (trainingSessions == null && isValidating) {
    return (
      <>
        <Header showButton="back" theme="light" />
        <Spinner fullscreen />
      </>
    );
  }

  return (
    <Box>
      <Header showButton="back" />

      <Container sx={{ mt: [3, 11] }}>
        <Flex
          sx={{
            flexDirection: ['column', 'row'],
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ maxWidth: '560px' }}>
            <Text variant="h2light" sx={{ mb: 0 }}>
              Deine Pose Comparisons
            </Text>
            <Text
              sx={{ mt: 5, fontSize: 0, lineHeight: 1.5, letterSpacing: 0 }}
            >
              An vordefinierten Zeitpunkten halten wir ein Bild Deiner
              Ausführung fest, so dass du sie jetzt mit der der Lehrerin
              vergleichen und deinen Fortschritt auf Social Media teilen kannst.
            </Text>
          </Box>
          <Box
            sx={{ display: ['none', 'block'], maxWidth: '306px', mt: [7, 0] }}
          >
            <Image src={IllustrationPoseComparison} sx={{ width: '100%' }} />
          </Box>
        </Flex>
      </Container>

      {trainingSessions?.some((ts) => Boolean(ts.images[0])) ? (
        <PoseComparisonOverviewList trainingSessions={trainingSessions} />
      ) : (
        <PoseComparisonOverviewEmpty />
      )}

      <Box sx={{ display: 'flex', mt: 6, justifyContent: 'center' }}>
        <PrivacyNote />
      </Box>

      {trainingSessions?.some((ts) => Boolean(ts.images[0])) === false && (
        <PoseComparisonOverviewCourses />
      )}
    </Box>
  );
};

export default PoseComparisonOverviewSection;
