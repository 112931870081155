import React from 'react';
import type { ThemeUIStyleObject } from 'theme-ui';
import { Box } from 'theme-ui';

import IconCheck from '../../../assets/icons/iconCheck.inline.svg';
import IconExclamation from '../../../assets/icons/iconExclamation.inline.svg';

interface PasswordConstraintProps {
  isValid: boolean;
  mobile: boolean;
  title?: string;
  sx?: ThemeUIStyleObject;
}

const PasswordConstraint: React.FC<PasswordConstraintProps> = ({
  title,
  mobile = false,
  isValid,
  sx,
}: PasswordConstraintProps) => {
  const iconSize = '16px';

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        flexDirection: mobile ? 'row-reverse' : 'row',
        fontSize: mobile ? '12px' : 0,
        ml: mobile ? 0 : 2,
        mr: mobile ? '-0.4rem' : 2,
        height: mobile ? '1.5rem' : iconSize,
        backgroundColor: 'white',
        pl: mobile ? 2 : 0,
        my: mobile ? 0 : 1,
        color: 'grey3',
        ...sx,
      }}
    >
      <Box sx={{ mr: mobile ? 0 : 2, ml: mobile ? 2 : 0, height: iconSize }}>
        {isValid ? (
          <IconCheck
            sx={{
              width: iconSize,
              height: iconSize,
              '> *': { fill: 'success' },
            }}
          />
        ) : (
          <IconExclamation
            sx={{
              width: iconSize,
              height: iconSize,
              '> *': { fill: 'warning' },
            }}
          />
        )}
      </Box>
      {title && title}
    </Box>
  );
};

export default PasswordConstraint;
