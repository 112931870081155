import Footer from '../components/02_molecules/Footer/Footer';
import AppSection from '../components/03_organisms/AppSection/AppSection';
import Page from '../components/04_templates/Page';

function AppPage() {
  return (
    <Page title="Yuu Yoga" authRequired={true}>
      <AppSection />
      <Footer />
    </Page>
  );
}

export default AppPage;
