import React, { useState } from 'react';
import { Box } from 'theme-ui';
import { ProgressControl as VideoReactProgressControl } from 'video-react';
import MarkerOverlay, { Marker } from './MarkerOverlay';

export interface ProgressControlProps {
  actions?: {
    seek: (time: number) => void;
  };
  player?: {
    currentTime: number;
    duration: number;
  };
  order: number;
  marker?: Marker[];
}

const ProgressControl: React.FC<ProgressControlProps> = ({
  actions,
  player,
  marker = [],
  ...args
}: ProgressControlProps) => {
  const [visibleOverlayIndex, setVisibleOverlayIndex] = useState<number | null>(
    null,
  );

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <div className="video-react-progress-control-container">
      <VideoReactProgressControl actions={actions} player={player} {...args} />
      {(player?.duration || 0) > 0 &&
        marker.map((mark, index) => {
          const leftPercent = (mark.start / (player?.duration as number)) * 100;
          const widthPercent =
            (mark.length / (player?.duration as number)) * 100;
          const isInMarker =
            mark.start < (player?.currentTime || 0) &&
            mark.start + mark.length > (player?.currentTime || 0);

          return (
            <React.Fragment key={`step-${mark.label}-${mark.start}`}>
              <Box
                className="video-react-progress-control-step"
                sx={{
                  position: 'absolute',
                  left: `${leftPercent}%`,
                  width: `${widthPercent}%`,
                }}
                style={{
                  transform: isInMarker
                    ? 'translateY(-50%) scale(2)'
                    : undefined,
                }}
                onClick={(): void => {
                  if (actions?.seek) {
                    actions.seek(mark.start);
                  }
                }}
                onMouseEnter={(): void => {
                  setVisibleOverlayIndex(index);
                }}
                onMouseLeave={(): void => {
                  setVisibleOverlayIndex(null);
                }}
              >
                <MarkerOverlay
                  mark={mark}
                  visible={visibleOverlayIndex === index}
                />
              </Box>
            </React.Fragment>
          );
        })}
    </div>
  );
  /* eslint-enable react/jsx-props-no-spreading */
};

export default ProgressControl;
