import * as React from 'react';
import moment from 'moment';

import type { TrainingSession } from '@youga/youga-interfaces';
import {
  useVideoSessions,
  useTrainingSessions,
  useUser,
} from '@youga/youga-client-api';

import JourneyChallengeSection from '../../02_molecules/Journey/JourneyChallengeSection';
import useTracking from '../../../hooks/useTracking';
import { GoalsMeta } from '../../../constants/enums';
import {
  createFinderForTheFirstVideoOfThe30DayChallenge,
  getDaysSinceChallengeStartDate,
  isCompleted,
  sessionCompleteNumber,
} from '../../../helper/JourneyHelpers';
import { CHALLENGES } from '../../../data';
import { CourseSession, Challenge } from '../../../types/interfaces';

interface Props {
  sessionNames?: string[];
}

const TODAY = moment(new Date(), 'YYYY-MM-DD');

function MhmJourneyChallengeSection({ sessionNames = CHALLENGES }: Props) {
  const { track } = useTracking();
  const { data: user, refreshUser, updatePreferences } = useUser();
  const { data: videoSessionsData } = useVideoSessions();
  const { refresh: refreshTrainingSessions, data: trainingSessions } =
    useTrainingSessions();

  const userChallenge = user?.preferences?.challenge;

  React.useEffect(() => {
    // TODO: (pwo) fetch only if older than X
    Promise.all([refreshUser(), refreshTrainingSessions()]);

    if (userChallenge?.challengeProgress.length === 0) {
      const findCompletedSessionOfFirstVideo =
        createFinderForTheFirstVideoOfThe30DayChallenge(sessionNames[0]);

      const challengeStartSession = trainingSessions?.find(
        findCompletedSessionOfFirstVideo,
      );

      console.log(
        `MhmJourneyChallengeSection::challengeStartSession:`,
        challengeStartSession,
      );

      if (challengeStartSession == null) {
        // user didn't started the 30 day challenge
        return;
      }

      try {
        if (challengeStartSession.videoSessionId == null) {
          throw new Error(`VideoSessionId not found`);
        }

        if (challengeStartSession.videoSessionName == null) {
          throw new Error(`VideoSessionName not found`);
        }

        if (challengeStartSession.completion == null) {
          throw new Error(`Completion not found`);
        }

        updatePreferences({
          challenge: {
            challengeStartDate: moment(challengeStartSession?.timestamp).format(
              'YYYY-MM-DD',
            ),
            challengeEndDate: moment(challengeStartSession?.timestamp)
              .add(29, 'days')
              .format('YYYY-MM-DD'),
            challengeProgress: [
              {
                id: challengeStartSession.videoSessionId,
                title: challengeStartSession.videoSessionName,
                date: challengeStartSession.timestamp,
                completition: challengeStartSession.completion,
              },
            ],
          },
        });
        track('journey-challenge-start-video');
      } catch (error: unknown) {
        track('journey-challenge-start-video-error');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userChallengeStartDate = userChallenge?.challengeStartDate;

  const daysSinceChallengeStart: number = React.useMemo(() => {
    return getDaysSinceChallengeStartDate(userChallengeStartDate);
  }, [userChallengeStartDate]);

  const videoSessions = videoSessionsData?.videoSessions;
  const userSessionsProgress = user?.sessionsProgress;

  const courseSessions: CourseSession[] = React.useMemo(() => {
    const lastChallengeItem: CourseSession = {
      id: '30+',
      videoSession: undefined,
      courseId: undefined,
      finished: false,
    };

    const sessions: CourseSession[] = [
      ...sessionNames.map((sessionName, index) => {
        return {
          id: `${index + 1}`,
          courseId: GoalsMeta['30_days_challenge'].id,
          videoSession: videoSessions?.[sessionName],
          finished: isCompleted(userSessionsProgress?.[sessionName]),
        };
      }),
      lastChallengeItem,
    ];

    return [sessions[daysSinceChallengeStart]];
  }, [
    userSessionsProgress,
    videoSessions,
    daysSinceChallengeStart,
    sessionNames,
  ]);

  const dailyFinishedSession = React.useMemo(() => {
    return trainingSessions?.find(
      (session: TrainingSession) =>
        isCompleted(session.completion) &&
        moment(session.timestamp).isSame(new Date(), 'day'),
    );
  }, [trainingSessions]);

  const challenges: Challenge[] = React.useMemo(() => {
    let counter = 0;
    const newChallenges = [];
    while (counter <= daysSinceChallengeStart) {
      const getDailyTrainingSession: TrainingSession | undefined =
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        trainingSessions?.find((session: TrainingSession) => {
          const dateFormatToDay = moment(session.timestamp, 'YYYY-MM-DD');
          return (
            session.completion != null &&
            session.completion >= sessionCompleteNumber &&
            TODAY.diff(dateFormatToDay, 'days') === counter
          );
        });

      const newChallenge: Challenge =
        getDailyTrainingSession !== undefined
          ? {
              title: getDailyTrainingSession.videoSessionName,
              day: daysSinceChallengeStart - counter + 1,
              challengeCompleted: true,
            }
          : {
              title: '-',
              day: daysSinceChallengeStart - counter + 1,
              challengeCompleted: false,
            };
      newChallenges.push(newChallenge);
      counter += 1;
    }
    return newChallenges;
  }, [daysSinceChallengeStart, trainingSessions]);

  const finishedSessions = React.useMemo(() => {
    return challenges.filter(
      (challenge: Challenge) => challenge.challengeCompleted,
    ).length;
  }, [challenges]);

  const allSessionsFinished = finishedSessions === courseSessions.length;

  const hideJourney = user?.preferences?.hideJourney;
  return hideJourney !== true ? (
    <JourneyChallengeSection
      bgImage={require('../../../assets/images/onboardingJourney/ImageTeaserJourney-100width.png')}
      teaserText={GoalsMeta['30_days_challenge'].title}
      dailyFinishedSession={dailyFinishedSession}
      finishedSessions={finishedSessions}
      allSessionsFinished={allSessionsFinished}
      videos={courseSessions}
      daysSinceChallengeStart={daysSinceChallengeStart}
    />
  ) : null;
}

export default MhmJourneyChallengeSection;
